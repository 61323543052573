import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { Account, AccountMapper } from "@rmp/core/types/account/account";
import { useAccountStore } from "@rmp/organization/stores/account";
import InvalidArgumentException from "@rmp/core/exceptions/invalidArgumentException";
import { useAccountEmailStore } from "@rmp/organization/stores/account/general/email";
import { useAccountPasswordStore } from "@rmp/organization/stores/account/general/password";
import { AccountController } from "@rmp/organization/api/account";

const abortService = new AbortService();
const accountController = new AccountController(abortService);

const page = usePageStore(abortService);
const form = useFormStore();
const snapshotStore = useSnapshotStore([
	{ key: SnapshotKeysEnum.LAST_SAVED, fields: ["account"] }

]);

export interface AccountGeneralState extends PageState, SnapshotState, FormState {
	id: string;
	account: Account;
	statusSaving: boolean;
	emailSaving: boolean;
	resetPasswordOperationExecuting: boolean;
}

const getDefaultState = (): AccountGeneralState => {
	return {
		...page.getDefaultPageState(),
		...form.getDefaultState(),
		...snapshotStore.getDefaultState(),
		id: "",
		account: AccountMapper.getEmpty(),
		statusSaving: false,
		emailSaving: false,
		resetPasswordOperationExecuting: false
	};
};

export const useAccountGeneralStore = defineStore({
	id: "account-general",
	state: (): AccountGeneralState => getDefaultState(),
	getters: {
		...page.getters,
		...form.getters,
		...snapshotStore.getters,
		breadcrumbs(state: AccountGeneralState) {
			const { breadcrumbs } = useAccountStore();
			
			return [
				...breadcrumbs
			];
		}
	},
	actions: {
		...page.actions,
		...form.actions,
		...snapshotStore.actions,
		async beforeInitialized() {
			const { id, account } = useAccountStore();
			if(!id)
				throw new InvalidArgumentException("id", id);
			
			this.id = id;
			this.account = account;
			
			this.setStateSnapshot();
		},
		async updateStatus() {
			this.statusSaving = true;
			
			try {
				const { isActive } = await accountController.updateAccountStatus(this.id, !this.account.isActive);
				const { account } = useAccountStore();
				
				if(isActive)
					alertService.addSuccess(AlertKeys.ACCOUNT_ACTIVATED);
				else
					alertService.addSuccess(AlertKeys.ACCOUNT_DEACTIVATED);
				
				this.account.isActive = isActive;
				account.isActive = isActive;
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.statusSaving = false;
			}
		},
		async resetPassword() {
			this.resetPasswordOperationExecuting = true;
			
			try {
				await accountController.resetAccountPassword(this.id);
				
				alertService.addSuccess(AlertKeys.PASSWORD_RESET_SUCCESSFULLY);
				
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.resetPasswordOperationExecuting = false;
			}
		}
	}
});
