import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import { RouteNames } from "@rmp/organization/router/routes";
import { i18n } from "@rmp/organization/plugins";
import routeToPermissionsMap from "@rmp/organization/router/routeToPermissionsMap";

// TODO: после рефакторинга списка контрагентов переместить в контрагенты 
export function useCounterpartiesBreadcrumb() {
	return useBreadcrumb({
		route: {
			name: RouteNames.COUNTERPARTIES
		},
		text: i18n.t(`navigation.breadcrumbs.${RouteNames.COUNTERPARTIES}`).toString(),
		routeToPermissionsMap
	});
}
