import { RouteNames } from "@rmp/organization/router/routes";
import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";

export default {
	navigation: {
		breadcrumbs: {
			[RouteNames.ROOT]: "Главная",
			[RouteNames.COUNTERPARTIES]: "Контрагенты",
			[RouteNames.COUNTERPARTY_CREATE]: "Добавление контрагента",
			[RouteNames.COUNTERPARTY_EMPLOYEES]: "Сотрудники",
			[RouteNames.COUNTERPARTY_MASTER_ACCOUNT]: "Мастер-аккаунт",
			[RouteNames.COUNTERPARTY_DOCUMENTS]: "Документы",
			[RouteNames.COUNTERPARTY_EMPLOYEE_CREATE]: "Новый сотрудник",
			[RouteNames.COUNTERPARTY_EMPLOYEE_TRUSTS]: "Доверенности",
			[RouteNames.COUNTERPARTY_EMPLOYEE_PROFILE]: "Профиль",
			[RouteNames.COUNTERPARTY_EMPLOYEE_TRUST_CREATE]: "Новая доверенность",
			[RouteNames.OFFICES]: "Предприятия",
			[RouteNames.OFFICE_CREATE]: "Новое предприятие",
			[RouteNames.COUNTERPARTY_BANK_ACCOUNTS]: "Банковские реквизиты",
			[RouteNames.COUNTERPARTY_BANK_ACCOUNT]: "Просмотр счета",
			[RouteNames.COUNTERPARTY_BANK_ACCOUNT_CREATE]: "Новый счет",
			[RouteNames.OFFICE_EMPLOYEES]: "Сотрудники",
			[RouteNames.OFFICE_EMPLOYEE_CREATE]: "Новый сотрудник",
			[RouteNames.OFFICE_EMPLOYEE_PROFILE]: "Профиль",
			[RouteNames.APPLICATIONS]: "Запросы",
			[RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESSES]: "Запросы на получение доступа к мастер-аккаунту",
			[RouteNames.ACCOUNTS]: "Пользователи",
			[RouteNames.ACCOUNT_PROFILES]: "Профили"
		}
	}
};
