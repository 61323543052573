import { AccountsState } from "@rmp/organization/stores/accounts/index";
import ApiGetAccountsParameters from "@rmp/core/api/types/account/apiGetAccountsParameters";

export default class CounterpartiesMapperProfile {
	constructor() {
	}
	
	mapToApiGetAccountsParameters(source: AccountsState): ApiGetAccountsParameters {
		return {
			take: source.paging.pageSize,
			skip: source.paging.pageSize * (source.paging.page - 1),
			query: source.search.query,
			sortField: source.sorting.type,
			sortingDirection: source.sorting.order
		};
	}
}
