export enum OfficeEmployeeRoleCodeEnum {
	ADMIN = "Admin",
	DISPATCHER = "Dispatcher",
	FLEET_OPERATIONS_SERVICE_EMPLOYEE = "FleetOperationsServiceEmployee",
	PILOT = "Pilot",
	FINANCE_DEPARTMENT_EMPLOYEE = "FinanceDepartmentEmployee",
	NGO_SERVICE_EMPLOYEE = "NgoServiceEmployee",
	FLEET_OPERATIONS_HEAD_OFFICE_EMPLOYEE = "FleetOperationsHeadOfficeEmployee",
	DREDGING_DEPARTMENT_EMPLOYEE = "DredgingDepartmentEmployee",
	PLANNING_ECONOMIC_DEPARTMENT_EMPLOYEE = "PlanningEconomicDepartmentEmployee",
	DEPARTMENT_HEAD = "DepartmentHead",
	CAN_SIGN_DOCUMENTS_ON_BEHALF_OF_ENTERPRISE = "CanSignDocumentsOnBehalfOfEnterprise"
}
