import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { EmbeddedFormState, useEmbeddedFormStore } from "@rmp/core/stores/composables/embeddedForm/useEmbeddedFormStore";
import { CounterpartyTypeEnum } from "@rmp/core/types/CounterpartyTypeEnum";
import { validateEmail } from "@rmp/core/utils/validator";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { LegalPersonController } from "@rmp/core/api/counterparty/legalPerson";
import { LegalEntityController } from "@rmp/core/api/counterparty/legalEntity";
import { AccountController } from "@rmp/organization/api/account";
import { EmbeddedFormEditModeTypeEnum } from "@rmp/core/stores/composables/embeddedForm/types/EmbeddedFormEditModeTypeEnum";
import { EmbeddedFormModeTypeEnum } from "@rmp/core/stores/composables/embeddedForm/types/EmbeddedFormModeTypeEnum";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import { useCounterpartyMasterAccountStore } from "@rmp/organization/stores/counterparty/masterAccount";
import { useCounterpartyStore } from "@rmp/organization/stores/counterparty";

const abortService = new AbortService();
const accountController = new AccountController(abortService);
const legalPersonController = new LegalPersonController(abortService);
const legalEntityController = new LegalEntityController(abortService);

const page = usePageStore(abortService);
const embeddedForm = useEmbeddedFormStore();
const snapshotStore = useSnapshotStore([
	{
		key: SnapshotKeysEnum.LAST_SAVED, fields: ["email"]
	}
]);

export interface CounterpartyMasterAccountEmailState extends PageState, SnapshotState, EmbeddedFormState {
	email: string;
	confirmedEmail: string;
	emailUnique: boolean;
	emailUniqueCheckInProgress: boolean;
}

const getDefaultState = (): CounterpartyMasterAccountEmailState => {
	return {
		...page.getDefaultPageState(),
		...embeddedForm.getDefaultState(),
		...snapshotStore.getDefaultState(),
		email: "",
		confirmedEmail: "",
		emailUnique: true,
		emailUniqueCheckInProgress: false
	};
};

export const useCounterpartyMasterAccountEmailStore = defineStore({
	id: "counterparty-master-account-email",
	state: (): CounterpartyMasterAccountEmailState => getDefaultState(),
	getters: {
		...page.getters,
		...embeddedForm.getters,
		...snapshotStore.getters,
		breadcrumbs(state: CounterpartyMasterAccountEmailState) {
			const { breadcrumbs } = useCounterpartyMasterAccountStore();
			return [...breadcrumbs];
		}
	},
	actions: {
		...page.actions,
		...embeddedForm.actions,
		...snapshotStore.actions,
		async beforeInitialized({ email }: { email: string }) {
			if(email) {
				this.email = email;
				this.confirmedEmail = email;
				this.formEditMode = EmbeddedFormEditModeTypeEnum.UPDATE;
			} else {
				this.formEditMode = EmbeddedFormEditModeTypeEnum.CREATE;
			}
			
			this.formMode = EmbeddedFormModeTypeEnum.DETAILS;
			
			this.setStateSnapshot();
		},
		async save() {
			this.formSaving = true;
			
			try {
				if(this.formEditMode === EmbeddedFormEditModeTypeEnum.CREATE) {
					await this.create();
				} else if(this.formEditMode === EmbeddedFormEditModeTypeEnum.UPDATE) {
					await this.update();
				}
				
				this.formMode = EmbeddedFormModeTypeEnum.SUCCESS;
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.formSaving = false;
			}
		},
		
		async create() {
			let { counterparty } = useCounterpartyStore();
			
			switch (counterparty.type) {
				case CounterpartyTypeEnum.LEGAL_ENTITY:
					await legalEntityController.createLegalEntityProfile(counterparty.id, this.email);
					break;
				case CounterpartyTypeEnum.LEGAL_PERSON:
					await legalPersonController.createEntrepreneurMasterAccountProfile(counterparty.id, this.email);
					break;
			}
			
			this.confirmedEmail = this.email;
			this.setStateSnapshot();
			this.formEditMode = EmbeddedFormEditModeTypeEnum.UPDATE
		},
		async update() {
			let { counterparty } = useCounterpartyStore();
			
			switch (counterparty.type) {
				case CounterpartyTypeEnum.LEGAL_ENTITY:
					await legalEntityController.updateLegalEntityProfile(counterparty.id, this.email);
					break;
				case CounterpartyTypeEnum.LEGAL_PERSON:
					await legalPersonController.updateEntrepreneurMasterAccountProfile(counterparty.id, this.email);
					break;
			}
			
			this.confirmedEmail = this.email;
			this.setStateSnapshot();
		},
		async checkEmailUnique() {
			if(!validateEmail(this.email)) {
				this.emailUnique = true;
				return;
			}
			
			try {
				this.emailUniqueCheckInProgress = true;
				
				let { accountId } = useCounterpartyMasterAccountStore();
				
				let exists = null;
				
				if(accountId)
					exists = await accountController.checkEmailById(accountId, this.email);
				else
					exists = await accountController.checkEmail(this.email);
				
				this.emailUnique = !exists;
				this.emailUniqueCheckInProgress = false;
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
				this.emailUnique = true;
			}
		}
	}
});
