import { render, staticRenderFns } from "./RmpTileCard.vue?vue&type=template&id=3618889b&scoped=true&"
import script from "./RmpTileCard.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RmpTileCard.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./RmpTileCard.vue?vue&type=style&index=0&id=3618889b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3618889b",
  null
  
)

export default component.exports