import { ApiPersistedBase } from "@rmp/core/api/types/base/apiPersistedBase";

export interface ApiOffice {
	inn: string;
	ogrn: string;
	kpp: string;
	shortName: string;
	longName: string;
	description: string;
}

export class ApiOfficeMapper {
	static getEmpty(): ApiOffice {
		return {
			inn: "",
			ogrn: "",
			kpp: "",
			shortName: "",
			longName: "",
			description: ""
		};
	}
}

export interface ApiOfficePersisted extends ApiPersistedBase {
	office: ApiOffice;
}

export class ApiOfficePersistedMapper {
	static getEmpty(): ApiOfficePersisted {
		return {
			id: "",
			createdAt: "",
			updatedAt: "",
			office: ApiOfficeMapper.getEmpty()
		};
	}
}
