<template>
	<rmp-nested-content-layout>
		<template #title>
			<v-row class="d-flex align-center justify-start pb-1">
				<v-col class="loader-title pb-0">
					<rmp-custom-title-loader :width="500" :height="20"/>
				</v-col>
				<v-col class="pb-0">
					<rmp-btn-loader :height="40"/>
				</v-col>
			</v-row>
		</template>
		<template #content>
			<rmp-tabs-loader></rmp-tabs-loader>

			<template v-if="isGeneralPage">
				<rmp-counterparty-employee-general-loader/>
			</template>

			<template v-else-if="isProfilePage">
				<rmp-counterparty-employee-profile-loader/>
			</template>

			<template v-else-if="isTrustsPage">
				<rmp-counterparty-employee-trusts-loader/>
			</template>

		</template>
	</rmp-nested-content-layout>
</template>

<script>
import RmpBackRouteLoader from "@rmp/core/components/loaders/common/RmpBackRouteLoader.vue";
import RmpTabsLoader from "@rmp/core/components/loaders/common/RmpTabsLoader.vue";
import RmpNestedContentLayout from "@rmp/core/components/layouts/RmpNestedContentLayout.vue";
import RmpCustomTitleLoader from "@rmp/core/components/loaders/common/RmpCustomTitleLoader.vue";
import RmpBtnLoader from "@rmp/core/components/loaders/form/RmpBtnLoader.vue";
import RmpFormCard from "@rmp/core/components/markup/RmpFormCard.vue";
import RmpFormCardBlocks from "@rmp/core/components/markup/RmpFormCardBlocks.vue";
import RmpFormCardBlock from "@rmp/core/components/markup/RmpFormCardBlock.vue";
import RmpFormCardBlockRow from "@rmp/core/components/markup/RmpFormCardBlockRow.vue";
import RmpFormCardBlockCol from "@rmp/core/components/markup/RmpFormCardBlockCol.vue";
import RmpFormItem from "@rmp/core/components/markup/RmpFormItem.vue";
import RmpSwitchLoader from "@rmp/core/components/loaders/form/RmpSwitchLoader.vue";
import RmpTextareaLoader from "@rmp/core/components/loaders/form/RmpTextareaLoader.vue";
import { RouteNames } from "@rmp/organization/router/routes";
import RmpCounterpartyEmployeeGeneralLoader from "@rmp/organization/views/counterpartyEmployee/RmpCounterpartyEmployeeGeneralLoader.vue";
import RmpCounterpartyEmployeeProfileLoader from "@rmp/organization/views/counterpartyEmployee/RmpCounterpartyEmployeeProfileLoader.vue";
import RmpCounterpartyEmployeeTrustsLoader from "@rmp/organization/views/counterpartyEmployee/RmpCounterpartyEmployeeTrustsLoader.vue";

export default {
	data() {
		return {
			RouteNames
		};
	},
	computed: {
		isGeneralPage() {
			return this.$route.name === RouteNames.COUNTERPARTY_EMPLOYEE_GENERAL;
		},
		isProfilePage() {
			return this.$route.name === RouteNames.COUNTERPARTY_EMPLOYEE_PROFILE;
		},
		isTrustsPage() {
			return this.$route.name === RouteNames.COUNTERPARTY_EMPLOYEE_TRUSTS;
		}
	},
	components: {
		RmpBtnLoader,
		RmpCounterpartyEmployeeTrustsLoader,
		RmpCounterpartyEmployeeProfileLoader,
		RmpCounterpartyEmployeeGeneralLoader,
		RmpCustomTitleLoader,
		RmpTabsLoader,
		RmpBackRouteLoader,
		RmpNestedContentLayout,
		RmpFormCardBlocks,
		RmpFormCard,
		RmpFormCardBlock,
		RmpFormCardBlockCol,
		RmpFormCardBlockRow,
		RmpFormItem,
		RmpSwitchLoader,
		RmpTextareaLoader
	}
};
</script>
<style scoped>
.loader-title {
	max-width: 500px !important;
}
</style>
