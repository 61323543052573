import { Dictionary } from "vue-router/types/router";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import { RouteNames } from "@rmp/organization/router/routes";
import { i18n } from "@rmp/organization/plugins";
import routeToPermissionsMap from "@rmp/organization/router/routeToPermissionsMap";

export function useCounterpartyBankAccountBreadcrumb(params: Dictionary<string>) {
	let routeNames = !params.id ? RouteNames.COUNTERPARTY_BANK_ACCOUNT_CREATE : RouteNames.COUNTERPARTY_BANK_ACCOUNT;
	
	return useBreadcrumb({
		route: {
			name: routeNames, params
		},
		text: i18n.t(`navigation.breadcrumbs.${routeNames}`).toString(),
		routeToPermissionsMap
	});
}
