import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/organization/router";
import { RouteNames } from "@rmp/organization/router/routes";
import { OfficeController } from "@rmp/organization/api/office";
import { OfficeEmployee, OfficeEmployeeMapper } from "@rmp/core/types/officeEmployee/officeEmployee";
import { ApiOfficeEmployeeRole } from "@rmp/core/api/types/roles/apiOfficeEmployeeRole";
import { useAppStore } from "@rmp/core/stores/appStore";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";
import AccessForbiddenException from "@rmp/core/exceptions/accessForbiddenException";
import { useOfficeEmployeeStore } from "@rmp/organization/stores/officeEmployee";
import { ApiOfficeEmployeeMapper } from "@rmp/core/api/types/office/apiOfficeEmployee";
import { JoiningEmployeeToOfficeMethodTypeEnum } from "@rmp/core/types/officeEmployee/JoiningEmployeeToOfficeMethodTypeEnum";
import { formatFullName } from "@rmp/core/utils/formatting";
import { i18n } from "@rmp/core/plugins";
import {
	useOfficeEmployeeGeneralBreadcrumb
} from "@rmp/organization/stores/officeEmployee/general/composables/useOfficeEmployeeGeneralBreadcrumb";
import { OfficeEmployeeRoleCodeEnum } from "@rmp/core/types/officeEmployee/OfficeEmployeeRoleCodeEnum";
import { some } from "lodash";

const abortService = new AbortService();
const officeController = new OfficeController(abortService);

const page = usePageStore(abortService);
const form = useFormStore();
const snapshotStore = useSnapshotStore([
	{
		key: SnapshotKeysEnum.LAST_SAVED, fields: ["employee"]
	}
]);

export interface OfficeEmployeeGeneralState extends PageState, FormState, SnapshotState {
	id: string;
	employee: OfficeEmployee;
	officeId: string;
	roles: ApiOfficeEmployeeRole[];
	changedByUserCanSignDocumentsValue: boolean | null;
}

const getDefaultState = (): OfficeEmployeeGeneralState => {
	return {
		...page.getDefaultPageState(),
		...form.getDefaultState(),
		...snapshotStore.getDefaultState(),
		id: "",
		employee: OfficeEmployeeMapper.getEmpty(),
		officeId: "",
		roles: [],
		changedByUserCanSignDocumentsValue: null
	};
};

export const useOfficeEmployeeGeneralStore = defineStore({
	id: "office-employee-general",
	state: (): OfficeEmployeeGeneralState => getDefaultState(),
	getters: {
		...page.getters,
		...form.getters,
		...snapshotStore.getters,
		breadcrumbs(state: OfficeEmployeeGeneralState) {
			const { breadcrumbs } = useOfficeEmployeeStore();
			
			const routeName = state.id ? RouteNames.OFFICE_EMPLOYEE_GENERAL : RouteNames.OFFICE_EMPLOYEE_CREATE;
			const text = state.id ? formatFullName(state.employee) : String(i18n.t("titles.createEmployee"));
			
			return [
				...breadcrumbs,
				useOfficeEmployeeGeneralBreadcrumb(routeName, text)
			];
		}
	},
	actions: {
		...page.actions,
		...form.actions,
		...snapshotStore.actions,
		async beforeInitialized({ id }: { id: string | null }) {
			if(id)
				this.id = id;
			
			await this.fetch();
			
			this.setStateSnapshot();
		},
		async fetch() {
			this.formStateLoading = true;
			
			const { officeId } = useOfficeEmployeeStore();
			this.officeId = officeId;
			
			try {
				this.roles = await officeController.getOfficeEmployeeRoles();
				
				if(this.id) {
					let { employee } = await officeController.getOfficeEmployee(this.id, this.officeId);
					
					this.employee = OfficeEmployeeMapper.map(employee);
					this.changedByUserCanSignDocumentsValue =
						employee.roles.some(x => x.code === OfficeEmployeeRoleCodeEnum.CAN_SIGN_DOCUMENTS_ON_BEHALF_OF_ENTERPRISE);
				}
			} catch (error) {
				const { setPageModeNotFound, setPageModeAccessForbidden } = useAppStore();
				
				switch (error.constructor) {
					case HttpNotFoundException:
						setPageModeNotFound();
						break;
					case AccessForbiddenException:
						setPageModeAccessForbidden();
						break;
					default:
						AlertHelper.handleGeneralRequestErrors(error);
						break;
				}
			} finally {
				this.formStateLoading = false;
			}
		},
		async save() {
			this.formSaving = true;
			
			try {
				const { assignEmployee } = useOfficeEmployeeStore();
				
				const apiEmployee = ApiOfficeEmployeeMapper.map(this.employee);
				
				await officeController.updateOfficeEmployee(this.id, this.officeId, apiEmployee);
				
				assignEmployee({
					firstName: this.employee.firstName,
					middleName: this.employee.middleName,
					lastName: this.employee.lastName
				});
				
				this.setStateSnapshot();
				alertService.addSuccess(AlertKeys.SUCCESS_UPDATED_INFO);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.formSaving = false;
			}
		},
		async create({ joiningEmployeeToOfficeMethod }: { joiningEmployeeToOfficeMethod: JoiningEmployeeToOfficeMethodTypeEnum }) {
			this.formSaving = true;
			
			try {
				let apiEmployee = ApiOfficeEmployeeMapper.map(this.employee);
				let assignToAccount = joiningEmployeeToOfficeMethod === JoiningEmployeeToOfficeMethodTypeEnum.REGISTER;
				
				await officeController.createOfficeEmployee(this.officeId, apiEmployee, assignToAccount);
				
				this.setStateSnapshot();
				alertService.addSuccess(AlertKeys.SUCCESS_CREATED_INFO);
				await router.push({ name: RouteNames.OFFICE_EMPLOYEES });
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.formSaving = false;
			}
		},
		enableRole(code: OfficeEmployeeRoleCodeEnum) {
			const canSign = this.roles.find(x => x.code === code) as ApiOfficeEmployeeRole;
			
			if(!some(this.employee.roles, canSign))
				this.employee.roles.push(canSign);
		},
		disableRole(code: OfficeEmployeeRoleCodeEnum) {
			this.employee.roles = this.employee.roles.filter(x => x.code !== code);
		}
	}
});
