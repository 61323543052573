<template>
	<v-card class="pa-8 pb-1 d-flex align-start flex-wrap pt-9"
			:style="{ 'column-gap': $vuetify.breakpoint.lgAndDown ? '56px' : '128px' }" elevation="0">
		<v-row class="d-flex flex-wrap">
			<rmp-details-section-column>
				<rmp-details-group>
					<rmp-details-section-title-loader></rmp-details-section-title-loader>
					<rmp-details-item-loader v-for="(_, i) in 3" :key="i"></rmp-details-item-loader>
				</rmp-details-group>
			</rmp-details-section-column>
		</v-row>
	</v-card>
</template>

<script>
import RmpDetailsItemLoader from "@rmp/core/components/loaders/details/RmpDetailsItemLoader.vue";
import RmpDetailsGroup from "@rmp/core/components/details/RmpDetailsGroup.vue";
import RmpDetailsSectionTitleLoader from "@rmp/core/components/loaders/details/RmpDetailsSectionTitleLoader.vue";
import RmpFileLoader from "@rmp/core/components/loaders/common/RmpFileLoader.vue";
import RmpDetailsSectionColumn from "@rmp/core/components/details/RmpDetailsSectionColumn.vue";

export default {
	components: {
		RmpDetailsItemLoader,
		RmpDetailsGroup,
		RmpDetailsSectionTitleLoader,
		RmpFileLoader,
		RmpDetailsSectionColumn
	}
};
</script>
