import { Dictionary } from "vue-router/types/router";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import { RouteNames } from "@rmp/organization/router/routes";
import routeToPermissionsMap from "@rmp/organization/router/routeToPermissionsMap";

export function useAccountBreadcrumb(text: string, params?: Dictionary<string>) {
	return useBreadcrumb({
		route: {
			name: RouteNames.ACCOUNT, params
		},
		text,
		routeToPermissionsMap
	});
}
