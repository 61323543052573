import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { Permissions } from "@rmp/core/constants/permissions";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";
import AccessForbiddenException from "@rmp/core/exceptions/accessForbiddenException";
import PermissionsService from "@rmp/core/services/permissionsService";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import { i18n } from "@rmp/core/plugins";
import { useCounterpartiesBreadcrumb } from "@rmp/organization/stores/counterparties/composables/useCounterpartiesBreadcrumb";
import SearchSuggestion from "@rmp/core/services/searchSuggestion/searchSuggestion";
import { LAST_VISITED_COUNTERPARTIES } from "@rmp/core/constants/localStorage";
import { Counterparty } from "@rmp/core/types/counterparty/counterparty";
import { CounterpartyTypeEnum } from "@rmp/core/types/CounterpartyTypeEnum";
import { CounterpartyController } from "@rmp/core/api/counterparty";
import SearchSuggestionsService from "@rmp/core/services/searchSuggestion/searchSuggestionsService";
import ApiCounterpartyPersistedBase from "@rmp/core/api/types/counterparty/apiCounterpartyPersistedBase";
import ApiLegalEntityCounterparty from "@rmp/core/api/types/counterparty/apiLegalEntityCounterparty";
import ApiLegalPersonCounterparty from "@rmp/core/api/types/counterparty/apiLegalPersonCounterparty";
import { useCounterpartyBreadcrumb } from "@rmp/organization/stores/counterparty/composables/useCounterpartyBreadcrumb";

const abortService = new AbortService();
const counterpartyController = new CounterpartyController(abortService);
const permissionsService = new PermissionsService();
const searchSuggestionService = new SearchSuggestionsService();

const page = usePageStore(abortService);
const snapshotStore = useSnapshotStore([
	{
		key: SnapshotKeysEnum.LAST_SAVED, fields: ["counterparty"]
	}
]);

export interface CounterpartyState extends PageState, SnapshotState {
	id: string;
	counterparty: Counterparty;
	counterpartyLoading: boolean;
	title: string;
	shortTitle: string;
}

const getDefaultState = (): CounterpartyState => {
	return {
		...page.getDefaultPageState(),
		...snapshotStore.getDefaultState(),
		id: "",
		counterparty: {
			id: "",
			type: CounterpartyTypeEnum.LEGAL_ENTITY
		} as Counterparty,
		counterpartyLoading: false,
		title: "",
		shortTitle: ""
	};
};

export const useCounterpartyStore = defineStore({
	id: "counterparty",
	state: (): CounterpartyState => getDefaultState(),
	getters: {
		...page.getters,
		...snapshotStore.getters,
		breadcrumbs(state: CounterpartyState) {
			return [
				useCounterpartiesBreadcrumb(),
				useCounterpartyBreadcrumb(state.title, { id: state.id })
			];
		}
	},
	actions: {
		...page.actions,
		...snapshotStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			if(id) {
				this.id = id;
				await this.fetch({ id: this.id });
				
				const searchSuggestion = new SearchSuggestion(id, this.title);
				searchSuggestionService.add(LAST_VISITED_COUNTERPARTIES, searchSuggestion);
			} else {
				this.title = i18n.t("titles.counterpartyCreate") as string;
			}
			
			this.setStateSnapshot();
		},
		async fetch({ id }: { id: string }) {
			this.counterpartyLoading = true;
			
			try {
				const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_READ])
					? AuthorizationScopeType.GLOBAL
					: AuthorizationScopeType.OWN;
				
				let { counterparty } = await counterpartyController.getCounterparty<ApiCounterpartyPersistedBase>(id,
					ApiCounterpartyPersistedBase,
					scope);
				
				if(counterparty instanceof ApiLegalEntityCounterparty) {
					this.title = counterparty.shortName;
				} else if(counterparty instanceof ApiLegalPersonCounterparty) {
					this.title = `${i18n.t("common.legalPerson")} "${counterparty.fullName}"`;
				}
				
				this.counterparty = { id, type: counterparty.type } as Counterparty;
			} catch (error) {
				const { setPageModeNotFound, setPageModeAccessForbidden } = useAppStore();
				
				switch (error.constructor) {
					case HttpNotFoundException:
						setPageModeNotFound();
						break;
					case AccessForbiddenException:
						setPageModeAccessForbidden();
						break;
					default:
						AlertHelper.handleGeneralRequestErrors(error);
						break;
				}
			} finally {
				this.counterpartyLoading = false;
			}
		}
	}
});
