import { IMiddleware } from "@rmp/core/types/core/middleware";
import { NavigationGuardNext, Route } from "vue-router/types/router";
import SecurityService from "@rmp/core/services/auth/securityService";
import { ProfileType } from "@rmp/core/types/ProfileType";
import { RouteNames } from "@rmp/organization/router/routes";
import PermissionsService from "@rmp/core/services/permissionsService";
import { Permissions } from "@rmp/core/constants/permissions";
import ApiLegalEntityCounterpartyPersisted from "@rmp/core/api/types/counterparty/apiLegalEntityCounterpartyPersisted";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";
import ApiLegalPersonCounterpartyPersisted from "@rmp/core/api/types/counterparty/apiLegalPersonCounterpartyPersisted";
import AbortService from "@rmp/core/services/abortService";
import { CounterpartyController } from "@rmp/core/api/counterparty";
import ApiCounterpartyPersistedBase from "@rmp/core/api/types/counterparty/apiCounterpartyPersistedBase";

const securityService = new SecurityService();

const abortService = new AbortService();
const counterpartyController = new CounterpartyController(abortService);

export default class RedirectMiddleware implements IMiddleware {
	constructor() {

	}

	async invoke(to: Route, from: Route) {
		try {
			let user = await securityService.getUser();
			
			if(!user.profile || !user.profile.isActive)
				return { name: RouteNames.ERROR_ACCESS_DENIED };
			
			if(to.name === RouteNames.ROOT) {
				switch (user.profile.type) {
					case ProfileType.MASTER_ACCOUNT_LEGAL_ENTITY:
					case ProfileType.MASTER_ACCOUNT_ENTREPRENEUR:
					{
						abortService.initialize();

						let counterparty = await counterpartyController.getCounterparty("",
							ApiCounterpartyPersistedBase, AuthorizationScopeType.OWN);

						return { name: RouteNames.COUNTERPARTY_EMPLOYEES, params: { id: counterparty.id } };
					}
					case ProfileType.OFFICE_EMPLOYEE:
					{
						return { name: RouteNames.COUNTERPARTIES };
					}
					case ProfileType.COUNTERPARTY_LEGAL_ENTITY_EMPLOYEE:
					case ProfileType.COUNTERPARTY_ENTREPRENEUR_EMPLOYEE:
					case ProfileType.COUNTERPARTY_PHYSICAL_PERSON_EMPLOYEE:
					case ProfileType.COUNTERPARTY_FOREIGN_ORGANIZATION_EMPLOYEE:
					{
						const permissionsService = new PermissionsService();

						if(await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_READ_LIST])) {
							return { name: RouteNames.COUNTERPARTIES };
						} else {
							return { name: RouteNames.COUNTERPARTY_CREATE };
						}
					}
					default:
						return;
				}
			}
			if(to.name === RouteNames.APPS) {
				let user = await securityService.getUser();
				
				if(!user.profile)
					return { name: RouteNames.ERROR_ACCESS_DENIED };
			}
		} catch (e) {
			console.error(e);
		}
	}
}
