import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { EmbeddedFormState, useEmbeddedFormStore } from "@rmp/core/stores/composables/embeddedForm/useEmbeddedFormStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { AccountController } from "@rmp/organization/api/account";
import { EmbeddedFormEditModeTypeEnum } from "@rmp/core/stores/composables/embeddedForm/types/EmbeddedFormEditModeTypeEnum";
import { EmbeddedFormModeTypeEnum } from "@rmp/core/stores/composables/embeddedForm/types/EmbeddedFormModeTypeEnum";
import { useAccountStore } from "@rmp/organization/stores/account";
import { AccountGeneralEmailState } from "@rmp/organization/stores/account/general/email";

const abortService = new AbortService();
const accountController = new AccountController(abortService);

const page = usePageStore(abortService);
const embeddedForm = useEmbeddedFormStore();

export interface AccountGeneralPasswordState extends PageState, EmbeddedFormState {
	password: string;
	confirmedPassword: string;
}

const getDefaultState = (): AccountGeneralPasswordState => {
	return {
		...page.getDefaultPageState(),
		...embeddedForm.getDefaultState(),
		password: "",
		confirmedPassword: ""
	};
};

export const useAccountPasswordStore = defineStore({
	id: "account-general-password",
	state: (): AccountGeneralPasswordState => getDefaultState(),
	getters: {
		...page.getters,
		...embeddedForm.getters,
		breadcrumbs(state: AccountGeneralPasswordState) {
			const { breadcrumbs } = useAccountStore();
			
			return [
				...breadcrumbs
			];
		}
	},
	actions: {
		...page.actions,
		...embeddedForm.actions,
		async beforeInitialized() {
			this.formEditMode = EmbeddedFormEditModeTypeEnum.UPDATE;
			this.formMode = EmbeddedFormModeTypeEnum.DETAILS;
		},
		async save() {
			this.formSaving = true;
			
			try {
				await this.update();
				
				this.formMode = EmbeddedFormModeTypeEnum.SUCCESS;
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.formSaving = false;
			}
		},
		async update() {
			const { id } = useAccountStore();
			
			await accountController.updateAccountPassword(id, this.password);
			this.rollbackState();
		},
		rollbackState() {
			this.password = "";
			this.confirmedPassword = "";
		},
		cancelChanges() {
			this.rollbackState();
		}
	}
});
