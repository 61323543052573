import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import ApiEntrepreneurEmployeeProfile from "@rmp/core/api/types/account/profile/apiEntrepreneurEmployeeProfile";
import ApiLegalEntityEmployeeProfile from "@rmp/core/api/types/account/profile/apiLegalEntityEmployeeProfile";
import { ApiAccountProfileBase } from "@rmp/core/api/types/account/profile/apiAccountProfileBase";
import { useCounterpartyEmployeeStore } from "@rmp/organization/stores/counterpartyEmployee";
import NotDefinedException from "@rmp/core/exceptions/notDefinedException";
import { AuthorizationController } from "@rmp/core/api/authorization";
import { AccountController } from "@rmp/organization/api/account";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import { formatFullName } from "@rmp/core/utils/formatting";
import { useCounterpartyEmployeeProfileBreadcrumb } from "@rmp/organization/stores/counterpartyEmployee/profile/composables/useCounterpartyEmployeeProfileBreadcrumb";
import {
	useCounterpartyEmployeeGeneralBreadcrumb
} from "@rmp/organization/stores/counterpartyEmployee/general/composables/useCounterpartyEmployeeGeneralBreadcrumb";
import { RouteNames } from "@rmp/organization/router/routes";

const abortService = new AbortService();
const authorizationController = new AuthorizationController(abortService);
const accountController = new AccountController(abortService);

const page = usePageStore(abortService);
const form = useFormStore();
const snapshotStore = useSnapshotStore([
	{
		key: SnapshotKeysEnum.LAST_SAVED, fields: ["profile"]
	}
]);

type CounterpartyEmployeeProfile = ApiEntrepreneurEmployeeProfile | ApiLegalEntityEmployeeProfile | ApiAccountProfileBase;

export interface CounterpartyEmployeeProfileState extends PageState, FormState, SnapshotState {
	id: string;
	accountId: string;
	profile: CounterpartyEmployeeProfile;
	requestPhoneConfirmationOperationExecuting: boolean;
	signatureRequiredToggleLoading: boolean;
}

const getDefaultState = (): CounterpartyEmployeeProfileState => {
	return {
		...page.getDefaultPageState(),
		...form.getDefaultState(),
		...snapshotStore.getDefaultState(),
		id: "",
		accountId: "",
		profile: new ApiAccountProfileBase(),
		requestPhoneConfirmationOperationExecuting: false,
		signatureRequiredToggleLoading: false
	};
};

export const useCounterpartyEmployeeProfileStore = defineStore({
	id: "counterparty-employee-profile",
	state: (): CounterpartyEmployeeProfileState => getDefaultState(),
	getters: {
		...page.getters,
		...form.getters,
		...snapshotStore.getters,
		breadcrumbs(state: CounterpartyEmployeeProfileState) {
			let { breadcrumbs, employee, id } = useCounterpartyEmployeeStore();

			return [
				...breadcrumbs,
				useCounterpartyEmployeeGeneralBreadcrumb(RouteNames.COUNTERPARTY_EMPLOYEE_GENERAL, formatFullName(employee), { id: state.id }),
				useCounterpartyEmployeeProfileBreadcrumb()
			];
		}
	},
	actions: {
		...page.actions,
		...form.actions,
		...snapshotStore.actions,
		async beforeInitialized() {
			const { profile: persistedProfile } = useCounterpartyEmployeeStore();
			if(!persistedProfile) throw new NotDefinedException("persistedProfile");
			
			const { id, profile } = persistedProfile;
			const { accountId } = profile;
			
			this.id = id;
			this.accountId = accountId;
			this.profile = profile;
			
			this.setStateSnapshot();
		},
		async requestPhoneConfirmation() {
			this.requestPhoneConfirmationOperationExecuting = true;
			
			try {
				await authorizationController.requestPhoneConfirmation(this.id);
				
				this.profile.isPhoneConfirmed = false;
				
				alertService.addSuccess(AlertKeys.PHONE_CONFIRMATION_REQUEST_SUCCESS_CREATED);
			} catch (ex) {
				console.error(ex);
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.requestPhoneConfirmationOperationExecuting = false;
			}
		},
		async toggleSignatureRequired(value: boolean) {
			this.signatureRequiredToggleLoading = true;
			
			try {
				this.profile.isSignatureRequired = value;
				
				await accountController.toggleSignatureRequired(this.id, value);
				
				alertService.addSuccess(AlertKeys.SUCCESS_UPDATED_INFO);
			} catch (ex) {
				console.error(ex);
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.signatureRequiredToggleLoading = false;
			}
		}
	}
});
