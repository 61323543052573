import { RouteNames } from "@rmp/organization/router/routes";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import { i18n } from "@rmp/organization/plugins";
import routeToPermissionsMap from "@rmp/organization/router/routeToPermissionsMap";

export function useMasterAccountAccessApplicationsBreadcrumb() {
	return useBreadcrumb({
		route: {
			name: RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESSES
		},
		text: i18n.t(`navigation.breadcrumbs.${RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESSES}`).toString(),
		routeToPermissionsMap
	});
}
