import { RouteNames } from "@rmp/organization/router/routes";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import routeToPermissionsMap from "@rmp/organization/router/routeToPermissionsMap";
import { Dictionary } from "vue-router/types/router";
import { i18n } from "@rmp/organization/plugins";

export function useCounterpartyEmployeeTrustsBreadcrumb(text?: string, params?: Dictionary<string>) {
	return useBreadcrumb({
		route: {
			name: RouteNames.COUNTERPARTY_EMPLOYEE_TRUSTS, params
		},
		text: text || i18n.t(`navigation.breadcrumbs.${RouteNames.COUNTERPARTY_EMPLOYEE_TRUSTS}`).toString(),
		routeToPermissionsMap
	});
}
