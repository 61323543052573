import { convertIsoToNumber } from "@rmp/core/utils/dates";
import { ApiLegalEntityFnsUpdate } from "@rmp/core/api/types/fns/apiLegalEntityFnsUpdate";

export interface LegalEntityFnsUpdate {
	lastChecked: number;
	lastUpdated: number;
	isEnabled: boolean;
	externalId: string;
	inn: string;
	ogrn: string;
	kpp: string;
	type: string;
}

export class LegalEntityFnsUpdateMapper {
	static map(source: ApiLegalEntityFnsUpdate): LegalEntityFnsUpdate {
		return {
			...source,
			lastChecked: convertIsoToNumber(source.lastChecked),
			lastUpdated: convertIsoToNumber(source.lastUpdated)
		};
	}
	
	static getEmpty(): LegalEntityFnsUpdate {
		return {
			lastChecked: 0,
			lastUpdated: 0,
			isEnabled: true,
			externalId: "",
			inn: "",
			ogrn: "",
			kpp: "",
			type: "",
		}
	}
}
