import urls, { urlTemplateParts } from "@rmp/core/api/config/urls";
import BaseController from "@rmp/core/api/shared/baseController";
import AbortService from "@rmp/core/services/abortService";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";
import {
	ApiCounterpartyBankAccount,
	ApiCounterpartyBankAccountPersisted
} from "@rmp/core/api/types/counterpartyBankAccount/apiCounterpartyBankAccount";

export class CounterpartyBankAccountController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	updateAccount = async (counterpartyId: string,
		id: string,
		account: ApiCounterpartyBankAccount,
		scope = AuthorizationScopeType.GLOBAL): Promise<ApiCounterpartyBankAccountPersisted> => {
		const url = scope === AuthorizationScopeType.GLOBAL
			? urls.counterparty.bankAccount.updateAccount.replace(urlTemplateParts.id, counterpartyId).replace(urlTemplateParts.subId, id)
			: urls.counterparty.bankAccount.updateOwnAccount;

		return await this.client.put(url, account);
	};

	createAccount = async (counterpartyId: string,
		account: ApiCounterpartyBankAccount,
		scope = AuthorizationScopeType.GLOBAL): Promise<ApiCounterpartyBankAccountPersisted> => {
		const url = scope === AuthorizationScopeType.GLOBAL
			? urls.counterparty.bankAccount.createAccount.replace(urlTemplateParts.id, counterpartyId)
			: urls.counterparty.bankAccount.createOwnAccount;

		return await this.client.post(url, account);
	};

	getAccount = async (counterpartyId: string,
		id: string,
		scope = AuthorizationScopeType.GLOBAL): Promise<ApiCounterpartyBankAccountPersisted> => {
		const url = scope === AuthorizationScopeType.GLOBAL
			? urls.counterparty.bankAccount.getAccount.replace(urlTemplateParts.id, counterpartyId).replace(urlTemplateParts.subId, id)
			: urls.counterparty.bankAccount.getOwnAccount;

		return await this.client.get<ApiCounterpartyBankAccountPersisted>(url);
	};

	getAccounts = async (id: string, scope = AuthorizationScopeType.GLOBAL): Promise<ApiCounterpartyBankAccountPersisted[]> => {
		const url = scope === AuthorizationScopeType.GLOBAL
			? urls.counterparty.bankAccount.getAccounts.replace(urlTemplateParts.id, id)
			: urls.counterparty.bankAccount.getOwnAccounts;
		
		let { bankAccounts } = await this.client.get<{ bankAccounts: ApiCounterpartyBankAccountPersisted[] }>(url);
		return bankAccounts;
	};
}
