import { Dictionary } from "vue-router/types/router";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import { RouteNames } from "@rmp/organization/router/routes";
import routeToPermissionsMap from "@rmp/organization/router/routeToPermissionsMap";

export function useCounterpartyBreadcrumb(text: string, params?: Dictionary<string>) {
	const routeName = params?.id ? RouteNames.COUNTERPARTY : RouteNames.COUNTERPARTY_CREATE
	
	return useBreadcrumb({
		route: {
			name: routeName, params
		},
		text,
		routeToPermissionsMap
	});
}
