<template>
	<rmp-nested-content-layout :back-route="{ name: RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESSES }">
		<template #title>
			<rmp-details-title-loader></rmp-details-title-loader>
		</template>
		<template #content>
			<v-card class="pa-8 pb-1 d-flex align-start flex-wrap"
					:style="{ 'column-gap': $vuetify.breakpoint.lgAndDown ? '56px' : '128px' }" elevation="0">
				<v-row class="d-flex flex-wrap">
					<rmp-details-section-column>
						<rmp-details-group>
							<rmp-details-section-title-loader></rmp-details-section-title-loader>
							<rmp-details-item-loader></rmp-details-item-loader>
							<rmp-details-item-loader></rmp-details-item-loader>
							<rmp-details-item-loader></rmp-details-item-loader>
							<rmp-details-item-loader></rmp-details-item-loader>
							<rmp-details-item-loader></rmp-details-item-loader>
						</rmp-details-group>
					</rmp-details-section-column>
					<rmp-details-section-column>
						<rmp-details-group>
							<rmp-details-section-title-loader></rmp-details-section-title-loader>
							<rmp-details-item-loader></rmp-details-item-loader>
							<rmp-details-item-loader></rmp-details-item-loader>
							<rmp-details-item-loader></rmp-details-item-loader>
						</rmp-details-group>
					</rmp-details-section-column>
					<rmp-details-section-column>
						<rmp-details-group>
							<rmp-details-section-title-loader></rmp-details-section-title-loader>
							<rmp-file-loader></rmp-file-loader>
							<rmp-file-loader></rmp-file-loader>
						</rmp-details-group>
					</rmp-details-section-column>
				</v-row>
			</v-card>
		</template>
		
	</rmp-nested-content-layout>
</template>

<script>
import RmpDetailsItemLoader from "@rmp/core/components/loaders/details/RmpDetailsItemLoader.vue";
import RmpDetailsGroup from "@rmp/core/components/details/RmpDetailsGroup.vue";
import RmpDetailsSectionTitleLoader from "@rmp/core/components/loaders/details/RmpDetailsSectionTitleLoader.vue";
import RmpFileLoader from "@rmp/core/components/loaders/common/RmpFileLoader.vue";
import RmpDetailsSectionColumn from "@rmp/core/components/details/RmpDetailsSectionColumn.vue";
import RmpDetailsTitleLoader from "@rmp/core/components/loaders/details/RmpDetailsTitleLoader.vue";
import RmpNestedContentLayout from "@rmp/core/components/layouts/RmpNestedContentLayout.vue";
import { RouteNames } from "@rmp/organization/router/routes";


export default {
	data() {
		return {
			RouteNames
		};
	},
	components: {
		RmpDetailsTitleLoader,
		RmpNestedContentLayout,
		RmpDetailsItemLoader,
		RmpDetailsGroup,
		RmpDetailsSectionTitleLoader,
		RmpFileLoader,
		RmpDetailsSectionColumn
	}
};
</script>
