import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { RouteNames } from "@rmp/organization/router/routes";
import router from "@rmp/organization/router";
import { convertIsoToNumber, formatDate } from "@rmp/core/utils/dates";
import { dateTimeFormat } from "@rmp/core/utils/formats";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { AccountController } from "@rmp/organization/api/account";
import { LegalPersonController } from "@rmp/core/api/counterparty/legalPerson";
import { LegalEntityController } from "@rmp/core/api/counterparty/legalEntity";
import { OfficeController } from "@rmp/organization/api/office";
import ApiAccountProfilePersistedBase from "@rmp/core/api/types/account/apiAccountProfilePersistedBase";
import AccountProfilesRouteQuery from "@rmp/organization/stores/account/profiles/types/AccountProfilesRouteQuery";
import AccountProfilesRouteQueryService from "@rmp/organization/stores/account/profiles/services/accountProfilesRouteQueryService";
import ApiLegalEntityProfile from "@rmp/core/api/types/account/profile/apiLegalEntityProfile";
import ApiEntrepreneurProfile from "@rmp/core/api/types/account/profile/apiEntrepreneurProfile";
import ApiLegalEntityEmployeeProfile from "@rmp/core/api/types/account/profile/apiLegalEntityEmployeeProfile";
import ApiEntrepreneurEmployeeProfile from "@rmp/core/api/types/account/profile/apiEntrepreneurEmployeeProfile";
import ApiOfficeEmployeeProfile from "@rmp/core/api/types/account/profile/apiOfficeEmployeeProfile";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useAccountStore } from "@rmp/organization/stores/account";
import { useAccountProfilesBreadcrumb } from "@rmp/organization/stores/account/profiles/composables/useAccountProfilesBreadcrumb";

const abortService = new AbortService();
const accountController = new AccountController(abortService);
const legalPersonController = new LegalPersonController(abortService);
const legalEntityController = new LegalEntityController(abortService);
const officeController = new OfficeController(abortService);

export interface AccountProfilesState extends PageState, RegisterState<ApiAccountProfilePersistedBase>
{
	id: string;
	selectItemHandling: boolean;
}

const defaultRouteQuery = new AccountProfilesRouteQuery(
	1,
	"createdAt",
	SortingOrderTypeEnum.DESCENDING,
	""
);
const routeQueryService = new AccountProfilesRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);

const register = useRegisterStore<ApiAccountProfilePersistedBase>({
	routeName: RouteNames.ACCOUNT_PROFILES,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): AccountProfilesState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		id: "",
		selectItemHandling: false
	};
};

export const useAccountProfilesStore = defineStore({
	id: "account-profiles",
	state: (): AccountProfilesState => getDefaultState(),
	getters: {
		...register.getters,
		...page.getters,
		breadcrumbs(state: AccountProfilesState) {
			const { breadcrumbs } = useAccountStore();
			
			return [
				...breadcrumbs,
				useAccountProfilesBreadcrumb({ id: state.id })
			];
		},
		formattedItems(state: AccountProfilesState) {
			return state.listing.items.map(x => {
				return {
					...x,
					id: x.id,
					title: x.profile.title,
					email: x.profile.email,
					phone: x.profile.phone,
					isActive: x.profile.isActive,
					createdAt: formatDate(convertIsoToNumber(x.createdAt), dateTimeFormat)
				};
			});
		},
		filteredItems() {
			return this.formattedItems;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized() {
			this.id = router.currentRoute.params.id;
			
			await this.initializeRegister();
		},
		async fetchRegisterItems() {
			let profiles: ApiAccountProfilePersistedBase[] = [];
			profiles = await accountController.getAccountProfilesById(this.id);
			
			return { items: profiles };
		},
		async handleSelectProfile({ profile }: { profile: any }) {
			this.selectItemHandling = true;
			
			try {
				if(profile instanceof ApiLegalEntityProfile) {
					const counterparty = await legalEntityController.getLegalEntityCounterpartyByIdentifiers(profile.inn,
						profile.ogrn,
						profile.kpp);
					
					if(counterparty)
						await router.push({ name: RouteNames.COUNTERPARTY_MASTER_ACCOUNT, params: { id: counterparty.id } });
				}
				
				if(profile instanceof ApiEntrepreneurProfile) {
					const counterparty = await legalPersonController.getLegalPersonByInnOgrn(profile.inn, profile.ogrnIp);
					
					if(counterparty)
						await router.push({ name: RouteNames.COUNTERPARTY_MASTER_ACCOUNT, params: { id: counterparty.id } });
				}
				
				if(profile instanceof ApiLegalEntityEmployeeProfile) {
					const counterparty = await legalEntityController.getLegalEntityCounterpartyByIdentifiers(profile.counterpartyInn,
						profile.counterpartyOgrn,
						profile.counterpartyKpp);
					
					if(counterparty) {
						await router.push({
							name: RouteNames.COUNTERPARTY_EMPLOYEE,
							params: { employeeId: profile.externalId, counterpartyId: counterparty.id }
						});
					}
				}
				
				if(profile instanceof ApiEntrepreneurEmployeeProfile) {
					const counterparty = await legalPersonController.getLegalPersonByInnOgrn(profile.employerInn, profile.employerOgrn);
					
					if(counterparty) {
						await router.push({
							name: RouteNames.COUNTERPARTY_EMPLOYEE,
							params: { employeeId: profile.externalId, counterpartyId: counterparty.id }
						});
					}
				}
				
				if(profile instanceof ApiOfficeEmployeeProfile) {
					const office = await officeController.getOfficeByIdentifiers(profile.officeInn, profile.officeOgrn, profile.officeKpp);
					
					await router.push({
						name: RouteNames.OFFICE_EMPLOYEE,
						params: { employeeId: profile.externalId, officeId: office.id }
					});
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.selectItemHandling = false;
			}
		}
	}
});
