import { LegalEntityCounterpartyHead } from "@rmp/core/types/counterparty/legalEntity/legalEntityCounterpartyHead";

export interface ApiLegalEntityCounterpartyHead {
	inn: string;
	snils: string;
	fullName: string;
	position: string;
	description: string;
}

export class ApiLegalEntityCounterpartyHeadMapper {
	static map(source: LegalEntityCounterpartyHead): ApiLegalEntityCounterpartyHead {
		return {
			...source
		};
	}
	
	static getEmpty(): ApiLegalEntityCounterpartyHead {
		return {
			inn: "",
			snils: "",
			fullName: "",
			position: "",
			description: ""
		};
	}
}

export interface ApiLegalEntityCounterpartyHeadPersisted {
	id: string;
	legalEntityHead: ApiLegalEntityCounterpartyHead;
}
