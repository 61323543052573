import { OfficeEmployeesState } from "@rmp/organization/stores/office/employees/index";
import ApiGetOfficeEmployeesParameters from "@rmp/core/api/types/office/apiGetOfficeEmployeesParameters";

export default class OfficeEmployeesMapperProfile {
	constructor() {
	}
	
	static mapToApiGetOfficeEmployeesParameters(source: OfficeEmployeesState): ApiGetOfficeEmployeesParameters {
		return {
			take: source.paging.pageSize,
			skip: source.paging.pageSize * (source.paging.page - 1),
			query: source.search.query,
			sortField: source.sorting.type,
			sortingDirection: source.sorting.order,
			roleIds: source.filter.roles
		};
	}
}
