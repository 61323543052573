import { prepareUrl } from "@rmp/core/utils/prepareUrlQuery";
import urls from "@rmp/core/api/config/urls";
import { plainToInstance } from "class-transformer";
import { ApiLegalEntity } from "@rmp/core/api/types/legalEntity/apiLegalEntity";
import { ApiFnsLegalPerson } from "@rmp/core/api/types/fns/apiFnsLegalPerson";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";
import { ApiLegalPersonFnsUpdate } from "@rmp/core/api/types/fns/apiLegalPersonFnsUpdate";
import BaseController from "@rmp/core/api/shared/baseController";
import AbortService from "@rmp/core/services/abortService";
import { ApiLegalEntityFnsUpdatePersisted } from "@rmp/core/api/types/fns/apiLegalEntityFnsUpdate";

export class FnsController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	
	getEgrulLegalEntity = async (inn: string, ogrn: string, kpp: string): Promise<ApiLegalEntity | null> => {
		try {
			return await this.client.get<ApiLegalEntity>(prepareUrl(urls.fns.getEgrulLegalEntity, {
				inn, ogrn, kpp
			}));
		} catch (e) {
			if(e instanceof HttpNotFoundException)
				return null;
			else
				throw e;
		}
	};
	
	getEgripLegalPerson = async (inn: string): Promise<ApiFnsLegalPerson | null> => {
		try {
			return await this.client.get<ApiFnsLegalPerson | null>(prepareUrl(urls.fns.getEgripLegalPersonByInn, { inn }));
		} catch (e) {
			if(e instanceof HttpNotFoundException)
				return null;
			else
				throw e;
		}
	};
	
	getLegalPersonFnsUpdate = async (inn: string, ogrn: string): Promise<ApiLegalPersonFnsUpdate | null> => {
		try {
			return await this.client.get<ApiLegalPersonFnsUpdate | null>(prepareUrl(urls.fns.upToDate.getLegalPerson, { inn, ogrn }));
		} catch (e) {
			if(e instanceof HttpNotFoundException)
				return null;
			else
				throw e;
		}
	};
	
	getLegalEntityFnsUpdate = async (inn: string, ogrn: string, kpp: string): Promise<ApiLegalEntityFnsUpdatePersisted | null> => {
		try {
			let { upToDateLegalEntityPersisted } = await this.client.get<{ upToDateLegalEntityPersisted: ApiLegalEntityFnsUpdatePersisted }>(prepareUrl(urls.fns.upToDate.getLegalEntity,
				{
					inn, ogrn, kpp
				}));
			return upToDateLegalEntityPersisted;
		} catch (e) {
			if(e instanceof HttpNotFoundException)
				return null;
			else
				throw e;
		}
	};
	
	enableLegalPersonFnsUpdate = async (inn: string, ogrn: string, externalId: string): Promise<void> => {
		await this.client.put(prepareUrl(urls.fns.upToDate.enableLegalPerson, {
			inn, ogrn, externalId
		}), null);
	};
	
	
	disableLegalPersonFnsUpdate = async (inn: string, ogrn: string, externalId: string): Promise<void> => {
		await this.client.delete(prepareUrl(urls.fns.upToDate.disableLegalPerson, {
			inn, ogrn, externalId
		}));
	};
	
	enableLegalEntityFnsUpdate = async (inn: string, ogrn: string, kpp: string, externalId: string): Promise<void> => {
		await this.client.put(prepareUrl(urls.fns.upToDate.enableLegalEntity, {
			inn, ogrn, kpp, externalId
		}), null);
	};
	
	disableLegalEntityFnsUpdate = async (inn: string, ogrn: string, kpp: string, externalId: string): Promise<void> => {
		await this.client.delete(prepareUrl(urls.fns.upToDate.disableLegalEntity, {
			inn, ogrn, kpp, externalId
		}));
	};
	
	updateLegalEntityViaFns = async (inn: string, ogrn: string, kpp: string, externalId: string): Promise<void> => {
		await this.client.post(prepareUrl(urls.fns.upToDate.updateLegalEntity, {
			inn, ogrn, kpp, externalId
		}), null);
	};
	
	updateLegalPersonViaFns = async (inn: string, ogrn: string, externalId: string): Promise<void> => {
		await this.client.post(prepareUrl(urls.fns.upToDate.updateLegalPerson, {
			inn, ogrn, externalId
		}), null);
	};
	
}
