import { render, staticRenderFns } from "./RmpCounterpartyEmployeeGeneral.vue?vue&type=template&id=2768edb0&scoped=true&"
import script from "./RmpCounterpartyEmployeeGeneral.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RmpCounterpartyEmployeeGeneral.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./RmpCounterpartyEmployeeGeneral.vue?vue&type=style&index=0&id=2768edb0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2768edb0",
  null
  
)

export default component.exports