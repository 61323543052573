import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { Permissions } from "@rmp/core/constants/permissions";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";
import PermissionsService from "@rmp/core/services/permissionsService";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import { CounterpartyController } from "@rmp/core/api/counterparty";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/organization/router";
import { RouteNames } from "@rmp/organization/router/routes";
import { i18n } from "@rmp/core/plugins";
import ApiLegalPersonCounterparty from "@rmp/core/api/types/counterparty/apiLegalPersonCounterparty";
import { CounterpartyBankAccount, CounterpartyBankAccountMapper } from "@rmp/core/types/counterparty/counterpartyBankAccount";
import ApiCounterpartyPersistedBase from "@rmp/core/api/types/counterparty/apiCounterpartyPersistedBase";
import ApiLegalEntityCounterparty from "@rmp/core/api/types/counterparty/apiLegalEntityCounterparty";
import { ApiCounterpartyBankAccountMapper } from "@rmp/core/api/types/counterpartyBankAccount/apiCounterpartyBankAccount";
import { CounterpartyBankAccountController } from "@rmp/organization/api/counterpartyBankAccount";
import { useCounterpartyStore } from "@rmp/organization/stores/counterparty";
import { useCounterpartiesBreadcrumb } from "@rmp/organization/stores/counterparties/composables/useCounterpartiesBreadcrumb";
import { useCounterpartyBreadcrumb } from "@rmp/organization/stores/counterparty/composables/useCounterpartyBreadcrumb";
import {
	useCounterpartyBankAccountsBreadcrumb
} from "@rmp/organization/stores/counterparty/bankAccounts/composables/useCounterpartyBankAccountsBreadcrumb";
import {
	useCounterpartyBankAccountBreadcrumb
} from "@rmp/organization/stores/counterpartyBankAccount/composables/useCounterpartyBankAccountBreadcrumb";

const abortService = new AbortService();
const bankAccountController = new CounterpartyBankAccountController(abortService);
const counterpartyController = new CounterpartyController(abortService);
const permissionsService = new PermissionsService();

const page = usePageStore(abortService);
const form = useFormStore();
const snapshotStore = useSnapshotStore([
	{ key: SnapshotKeysEnum.LAST_SAVED, fields: ["account"] }
]);

export interface CounterpartyBankAccountState extends PageState, SnapshotState, FormState {
	id: string;
	account: CounterpartyBankAccount;
	counterpartyId: string;
	bankAccountId: string;
	counterpartyTitle: string;
}

const getDefaultState = (): CounterpartyBankAccountState => {
	return {
		...page.getDefaultPageState(),
		...form.getDefaultState(),
		...snapshotStore.getDefaultState(),
		id: "",
		account: CounterpartyBankAccountMapper.getEmpty(),
		counterpartyId: "",
		bankAccountId: "",
		counterpartyTitle: ""
	};
};

export const useCounterpartyBankAccountStore = defineStore({
	id: "counterparty-bank-account",
	state: (): CounterpartyBankAccountState => getDefaultState(),
	getters: {
		...page.getters,
		...form.getters,
		...snapshotStore.getters,
		breadcrumbs(state: CounterpartyBankAccountState) {
			return [
				useCounterpartiesBreadcrumb(),
				useCounterpartyBreadcrumb(state.counterpartyTitle, { id: state.counterpartyId }),
				useCounterpartyBankAccountsBreadcrumb({ id: state.counterpartyId }),
				useCounterpartyBankAccountBreadcrumb({ id: state.bankAccountId })
			];
		}
	},
	actions: {
		...page.actions,
		...form.actions,
		...snapshotStore.actions,
		async beforeInitialized() {
			this.counterpartyId = router.currentRoute.params.counterpartyId;
			this.bankAccountId = router.currentRoute.params.accountId;
			
			await this.fetch();
			
			this.setStateSnapshot();
		},
		async fetch() {
			this.formStateLoading = true;
			
			try {
				const hasCounterpartyReadPermission = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_READ]);
				
				const tasks: Promise<any>[] = [
					counterpartyController.getCounterparty<ApiCounterpartyPersistedBase>(
						this.counterpartyId,
						ApiCounterpartyPersistedBase,
						hasCounterpartyReadPermission ? AuthorizationScopeType.GLOBAL : AuthorizationScopeType.OWN
					)
				];
				
				if(router.currentRoute.params.accountId)
					tasks.push(bankAccountController.getAccount(this.counterpartyId, this.bankAccountId));
				
				const [{ counterparty }, account] = await Promise.all(tasks);
				
				if(counterparty instanceof ApiLegalEntityCounterparty) {
					this.counterpartyTitle = counterparty.shortName;
				} else if(counterparty instanceof ApiLegalPersonCounterparty) {
					this.counterpartyTitle = `${i18n.t("common.legalPerson")} "${counterparty.fullName}"`;
				}
				
				if(account) {
					const { bankAccount } = account;
					this.account = CounterpartyBankAccountMapper.map(bankAccount);
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
				this.formDisabled = true;
			} finally {
				this.formStateLoading = false;
			}
		},
		async save() {
			this.formSaving = true;
			
			try {
				if(this.bankAccountId) {
					await bankAccountController.updateAccount(
						this.counterpartyId,
						this.bankAccountId,
						ApiCounterpartyBankAccountMapper.map(this.account)
					);
					
					this.setStateSnapshot();
					alertService.addSuccess(AlertKeys.SUCCESS_UPDATED_INFO);
				} else {
					const { id } = await bankAccountController.createAccount(
						this.counterpartyId,
						ApiCounterpartyBankAccountMapper.map(this.account)
					);

					this.setStateSnapshot();
					alertService.addSuccess(AlertKeys.SUCCESS_CREATED_INFO);
					await router.push({
						name: RouteNames.COUNTERPARTY_BANK_ACCOUNT,
						params: { counterpartyId: this.counterpartyId, bankAccountId: this.bankAccountId }
					});
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.formSaving = false;
			}
		}
	}
});
