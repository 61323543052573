import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import PermissionsService from "@rmp/core/services/permissionsService";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import { CounterpartyController } from "@rmp/core/api/counterparty";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import { FnsController } from "@rmp/organization/api/fns";
import { LegalEntityController } from "@rmp/core/api/counterparty/legalEntity";
import { useCounterpartyStore } from "@rmp/organization/stores/counterparty";
import { LegalEntityCounterparty, LegalEntityCounterpartyMapper } from "@rmp/core/types/counterparty/legalEntity/legalEntityCounterparty";
import { LegalEntityFnsUpdate, LegalEntityFnsUpdateMapper } from "@rmp/core/types/counterparty/legalEntity/legalEntityFnsUpdate";
import {
	LegalEntityCounterpartyHead,
	LegalEntityCounterpartyHeadMapper
} from "@rmp/core/types/counterparty/legalEntity/legalEntityCounterpartyHead";
import { v4 as generateGuid } from "uuid";
import { Permissions } from "@rmp/core/constants/permissions";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";
import { CounterpartyTypeEnum } from "@rmp/core/types/CounterpartyTypeEnum";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import ApiLegalEntityCounterpartyPersisted from "@rmp/core/api/types/counterparty/apiLegalEntityCounterpartyPersisted";
import { validateKpp, validateLegalEntityInn, validateOgrn } from "@rmp/core/utils/validator";
import { ApiLegalEntityCounterpartyHeadMapper } from "@rmp/core/api/types/counterparty/apiLegalEntityCounterpartyHead";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { i18n } from "@rmp/core/plugins";
import { ApiLegalEntityHead } from "@rmp/core/api/types/legalEntity/apiLegalEntityHead";
import { useUserStore } from "@rmp/core/stores/user";
import NotDefinedException from "@rmp/core/exceptions/notDefinedException";
import router from "@rmp/organization/router";
import { RouteNames } from "@rmp/organization/router/routes";
import { formatISODate } from "@rmp/core/utils/dates";

const abortService = new AbortService();
const fnsController = new FnsController(abortService);
const legalEntityController = new LegalEntityController(abortService);
const counterpartyController = new CounterpartyController(abortService);
const permissionsService = new PermissionsService();
const MAX_HEADS_COUNT = 10;

const page = usePageStore(abortService);
const form = useFormStore();
const snapshotStore = useSnapshotStore([
	{ key: SnapshotKeysEnum.LAST_SAVED, fields: ["counterparty", "fnsUpdate", "heads"] }
]);

export interface LegalEntityCounterpartyState extends PageState, SnapshotState, FormState {
	id: string;
	counterparty: LegalEntityCounterparty;
	counterpartyInn: string;
	fnsUpdate: LegalEntityFnsUpdate;
	egrulLegalEntityLoading: boolean;
	updateViaFnsOperationExecuting: boolean;
	heads: LegalEntityCounterpartyHead[];
	counterpartySuccessCreatedDialogOpened: boolean;
}

const getDefaultState = (): LegalEntityCounterpartyState => {
	return {
		...page.getDefaultPageState(),
		...form.getDefaultState(),
		...snapshotStore.getDefaultState(),
		id: "",
		counterparty: LegalEntityCounterpartyMapper.getEmpty(),
		counterpartyInn: "",
		fnsUpdate: LegalEntityFnsUpdateMapper.getEmpty(),
		egrulLegalEntityLoading: false,
		updateViaFnsOperationExecuting: false,
		heads: [],
		counterpartySuccessCreatedDialogOpened: false
	};
};

export const useLegalEntityCounterpartyStore = defineStore({
	id: "legal-entity-counterparty",
	state: (): LegalEntityCounterpartyState => getDefaultState(),
	getters: {
		...page.getters,
		...form.getters,
		...snapshotStore.getters,
		breadcrumbs(state: LegalEntityCounterpartyState) {
			const { breadcrumbs } = useCounterpartyStore();
			return [
				...breadcrumbs
			];
		}
	},
	actions: {
		...page.actions,
		...form.actions,
		...snapshotStore.actions,
		async beforeInitialized({ id }: { id: string | null }) {
			if(id) {
				this.id = id;
				await this.fetch();
			} else {
				this.addTemporaryHead();
			}
			
			this.formRecordUnique = true;
			this.setStateSnapshot();
		},
		addTemporaryHead(head?: ApiLegalEntityHead) {
			const temporaryHead = head || LegalEntityCounterpartyHeadMapper.getEmpty();
			this.heads.push({ ...temporaryHead, id: generateGuid() });
		},
		removeTemporaryHead(id: string) {
			this.heads = this.heads.filter(x => x.id !== id);
		},
		async fetch() {
			this.formStateLoading = true;
			
			try {
				const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_READ])
					? AuthorizationScopeType.GLOBAL
					: AuthorizationScopeType.OWN;
				
				const counterpartyPersisted = await counterpartyController.getCounterparty<ApiLegalEntityCounterpartyPersisted>(this.id,
					ApiLegalEntityCounterpartyPersisted, scope);
				
				const counterparty = LegalEntityCounterpartyMapper.map(counterpartyPersisted);
				
				const hasFnsReadPermission = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_FNS_READ]);
				
				if(hasFnsReadPermission) {
					let apiFnsUpdate = await fnsController.getLegalEntityFnsUpdate(counterparty.inn, counterparty.ogrn, counterparty.kpp);
					
					if(apiFnsUpdate) {
						const mappedLegalEntityFnsUpdate = LegalEntityFnsUpdateMapper.map(apiFnsUpdate.upToDateLegalEntity);
						
						this.fnsUpdate = { ...mappedLegalEntityFnsUpdate, isEnabled: true };
					} else {
						this.fnsUpdate.isEnabled = false;
					}
				}
				
				this.formRecordUnique = true;
				this.counterpartyInn = counterparty.inn;
				this.counterparty = counterparty;
				await this.fetchHeads();
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
				this.formDisabled = true;
			} finally {
				this.formStateLoading = false;
			}
		},
		async fetchHeads() {
			const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_HEAD_READ_LIST])
				? AuthorizationScopeType.GLOBAL
				: AuthorizationScopeType.OWN;
			
			let apiHeads = await legalEntityController.getLegalEntityHeads(this.id, scope);
			this.heads = apiHeads.map(x => LegalEntityCounterpartyHeadMapper.mapFromPersisted(x));
		},
		async saveFnsUpdate() {
			try {
				let { ogrn, inn, kpp } = this.counterparty;
				
				if(this.fnsUpdate.isEnabled) {
					await fnsController.enableLegalEntityFnsUpdate(inn, ogrn, kpp, this.id);
				} else {
					await fnsController.disableLegalEntityFnsUpdate(inn, ogrn, kpp, this.id);
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
				throw error;
			}
		},
		async save() {
			this.formSaving = true;
			
			const isCreationMode = !this.id;
			
			try {
				const { profile } = useUserStore();
				if(!profile) throw new NotDefinedException("user.profile");
				
				// TODO: исправить этот костыль попозже, что ниже, т.к. очень-очень много надо менять, если поправить ApiLegalEntityCounterparty на interface + будут конфликты.
				let apiCounterparty = {
					type: CounterpartyTypeEnum.LEGAL_ENTITY,
					...this.counterparty,
					registrationDate: formatISODate(this.counterparty.registrationDate),
					heads: this.heads.length ? this.heads.map(x => ApiLegalEntityCounterpartyHeadMapper.map(x)) : []
				};
				
				if(isCreationMode) {
					this.id = await counterpartyController.createLegalEntityCounterparty(apiCounterparty);
					
					if(this.fnsUpdate.isEnabled)
						await this.saveFnsUpdate();
				} else {
					await counterpartyController.updateLegalEntityCounterparty(this.id, apiCounterparty);
					
					if(this.changedSinceLastSavedFields.includes("fnsUpdate")) {
						await this.saveFnsUpdate();
					}
				}
				
				this.setStateSnapshot();
				
				if(isCreationMode) {
					const hasGlobalPermissionCounterpartyRead = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_READ]);
					
					if(!hasGlobalPermissionCounterpartyRead) {
						this.counterpartySuccessCreatedDialogOpened = true;
					} else {
						alertService.addSuccess(AlertKeys.SUCCESS_CREATED_INFO);
						await router.push({ name: RouteNames.COUNTERPARTY, params: { id: this.id } });
					}
				} else {
					alertService.addSuccess(AlertKeys.SUCCESS_UPDATED_INFO);
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.formSaving = false;
			}
		},
		async updateViaFns() {
			this.formDisabled = true;
			this.updateViaFnsOperationExecuting = true;
			
			try {
				let { ogrn, inn, kpp } = this.counterparty;
				await fnsController.updateLegalEntityViaFns(inn, ogrn, kpp, this.id);
				
				// Временно необходима, так как updateLegalEntityViaFns возвращает ответ не дожидаясь обновления данных
				const delay = 1000;
				await new Promise(resolve => setTimeout(resolve, delay));
				
				await this.fetch();
				this.setStateSnapshot();
				
				alertService.addSuccess(AlertKeys.COUNTERPARTY_SUCCESS_UPDATED_VIA_FNS);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.formDisabled = false;
				this.updateViaFnsOperationExecuting = false;
			}
		},
		async handleInnInput() {
			if(!validateLegalEntityInn(this.counterparty.inn) || !this.fnsUpdate.isEnabled) return;
			
			try {
				this.formReadonly = true;
				this.egrulLegalEntityLoading = true;
				
				let suggestion = await legalEntityController.getLegalEntitySuggestion(this.counterparty.inn);
				let apiEgrulLegalEntity = suggestion &&
					await fnsController.getEgrulLegalEntity(suggestion.inn, suggestion.ogrn, suggestion.kpp);
				
				if(apiEgrulLegalEntity) {
					const legalEntity = LegalEntityCounterpartyMapper.mapFromApiLegalEntity(apiEgrulLegalEntity);
					
					const creationMode = !this.id;
					
					if(creationMode) {
						this.heads = apiEgrulLegalEntity.heads.map(x => ({ ...x, id: generateGuid() }));
					} else {
						for (const head of apiEgrulLegalEntity.heads) {
							this.addTemporaryHead(head);
						}
					}
					
					this.counterparty = legalEntity;
				} else {
					alertService.addCustomError(i18n.t("alerts.errors.legalEntityByInnNotFound", { inn: this.counterparty.inn }) as string);
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.formReadonly = false;
				this.egrulLegalEntityLoading = false;
			}
		},
		async checkIsRecordUnique() {
			if(!validateLegalEntityInn(this.counterparty.inn) || !validateOgrn(this.counterparty.ogrn) ||
				!validateKpp(this.counterparty.kpp))
			{
				this.formRecordUnique = true;
				return;
			}
			
			try {
				this.formRecordUniqueCheckInProgress = true;
				
				const exists = await legalEntityController.checkLegalEntityCounterpartyIsExists(this.counterparty.inn,
					this.counterparty.ogrn,
					this.counterparty.kpp);
				
				this.formRecordUnique = !exists;
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
				this.formRecordUnique = true;
			} finally {
				this.formRecordUniqueCheckInProgress = true;
			}
		}
	}
});
