import { urls } from "@rmp/core/api/config/organizationUrls";
import { ApiOfficeEmployeeRole } from "@rmp/core/api/types/roles/apiOfficeEmployeeRole";
import AbortService from "@rmp/core/services/abortService";
import BaseOrganizationController from "@rmp/core/api/base/baseOrganiztionController";

export class RoleController extends BaseOrganizationController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	getOfficeEmployeeRoles = async () => {
		return await this.get<ApiOfficeEmployeeRole[]>(urls.role.officeEmployee);
	};
}
