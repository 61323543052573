import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import { CounterpartyTypeEnum } from "@rmp/core/types/CounterpartyTypeEnum";
import { AccountController } from "@rmp/organization/api/account";
import { LegalPersonController } from "@rmp/core/api/counterparty/legalPerson";
import { LegalEntityController } from "@rmp/core/api/counterparty/legalEntity";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import router from "@rmp/organization/router";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { Account, AccountMapper } from "@rmp/core/types/account/account";
import { useCounterpartyMasterAccountEmailStore } from "@rmp/organization/stores/counterparty/masterAccount/email";
import {
	useCounterpartyMasterAccountBreadcrumb
} from "@rmp/organization/stores/counterparty/masterAccount/composables/useCounterpartyMasterAccountBreadcrumb";
import { useCounterpartyStore } from "@rmp/organization/stores/counterparty";

const abortService = new AbortService();
const accountController = new AccountController(abortService);
const legalPersonController = new LegalPersonController(abortService);
const legalEntityController = new LegalEntityController(abortService);

const page = usePageStore(abortService);
const form = useFormStore();
const snapshotStore = useSnapshotStore([
	{
		key: SnapshotKeysEnum.LAST_SAVED, fields: ["account"]
	}
]);

export interface CounterpartyMasterAccountState extends PageState, SnapshotState, FormState {
	account: Account;
	counterpartyId: string;
	accountId: string;
	statusSaving: boolean;
}

const getDefaultState = (): CounterpartyMasterAccountState => {
	return {
		...page.getDefaultPageState(),
		...form.getDefaultState(),
		...snapshotStore.getDefaultState(),
		account: AccountMapper.getEmpty(),
		counterpartyId: "",
		accountId: "",
		statusSaving: false
	};
};

export const useCounterpartyMasterAccountStore = defineStore({
	id: "counterparty-master-account",
	state: (): CounterpartyMasterAccountState => getDefaultState(),
	getters: {
		...page.getters,
		...form.getters,
		...snapshotStore.getters,
		breadcrumbs(state: CounterpartyMasterAccountState) {
			const { breadcrumbs } = useCounterpartyStore();
			
			return [
				...breadcrumbs,
				useCounterpartyMasterAccountBreadcrumb({ id: router.currentRoute.params.id })
			];
		}
	},
	actions: {
		...page.actions,
		...form.actions,
		...snapshotStore.actions,
		async beforeInitialized() {
			this.counterpartyId = router.currentRoute.params.id;
			
			await this.fetch();
			
			this.setStateSnapshot();
		},
		async fetch() {
			this.formStateLoading = true;
			
			try {
				const { counterparty } = useCounterpartyStore();
				
				let persistedProfile;
				
				switch (counterparty.type) {
					case CounterpartyTypeEnum.LEGAL_ENTITY:
						persistedProfile = await legalEntityController.getLegalEntityProfile(counterparty.id);
						break;
					case CounterpartyTypeEnum.LEGAL_PERSON:
						persistedProfile = await legalPersonController.getEntrepreneurMasterAccountProfile(counterparty.id);
						break;
				}
				
				if(persistedProfile) {
					let profile = persistedProfile.profile;
					let { account } = await accountController.getAccount(profile.accountId);
					
					this.accountId = profile.accountId;
					
					this.account = AccountMapper.map(account);
					
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
				this.formDisabled = true;
			} finally {
				this.formStateLoading = false;
			}
		},
		async updateStatus() {
			this.statusSaving = true;
			
			try {
				const { isActive } = await accountController.updateAccountStatus(this.accountId, !this.account.isActive);
				
				if(isActive)
					alertService.addSuccess(AlertKeys.ACCOUNT_ACTIVATED);
				else
					alertService.addSuccess(AlertKeys.ACCOUNT_DEACTIVATED);
				
				this.account.isActive = isActive;
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.statusSaving = false;
			}
		},
		destroy() {
			const counterpartyMasterAccountEmailStore = useCounterpartyMasterAccountEmailStore();
			counterpartyMasterAccountEmailStore.destroy();
			
			this.destroyBase();
			// @ts-ignore
			this.$reset();
		}
	}
});
