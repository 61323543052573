import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { OfficeController } from "@rmp/organization/api/office";
import SearchSuggestion from "@rmp/core/services/searchSuggestion/searchSuggestion";
import { LAST_VISITED_OFFICES } from "@rmp/core/constants/localStorage";
import SearchSuggestionsService from "@rmp/core/services/searchSuggestion/searchSuggestionsService";
import { useOfficeBreadcrumb } from "@rmp/organization/stores/office/composables/useOfficeBreadcrumb";
import { useOfficesBreadcrumb } from "@rmp/organization/stores/offices/composables/useOfficesBreadcrumb";
import { RouteNames } from "@rmp/organization/router/routes";
import { i18n } from "@rmp/organization/plugins";
import OfficeMapperProfile, { Office } from "@rmp/organization/stores/office/types/office";

const searchSuggestionService = new SearchSuggestionsService();
const abortService = new AbortService();
const officeController = new OfficeController(abortService);

const page = usePageStore(abortService);

export interface OfficeState extends PageState {
	id: string;
	// TODO: Office -> ApiOffice, нужно убрать сравнение по классам в снапшоте
	office: Office;
}

const getDefaultState = (): OfficeState => {
	return {
		...page.getDefaultPageState(),
		id: "",
		office: {
			inn: "",
			ogrn: "",
			kpp: "",
			shortName: "",
			longName: "",
			description: ""
		} as Office
	};
};

export const useOfficeStore = defineStore({
	id: "office",
	state: (): OfficeState => getDefaultState(),
	getters: {
		...page.getters,
		breadcrumbs(state: OfficeState) {
			const routeName = state.id ? RouteNames.OFFICE_GENERAL : RouteNames.OFFICE_CREATE;
			const text = state.id ? state.office.shortName : i18n.tc(`navigation.breadcrumbs.${RouteNames.OFFICE_CREATE}`);
			
			return [
				useOfficesBreadcrumb(),
				useOfficeBreadcrumb(routeName, text, { id: state.id })
			];
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized({ id }: { id: string }) {
			if(!id)
				return;
			
			this.id = id;
			
			await this.fetch();
			
			const searchSuggestion = new SearchSuggestion(this.id, this.office.shortName);
			searchSuggestionService.add(LAST_VISITED_OFFICES, searchSuggestion);
		},
		async fetch() {
			try {
				const { office } = await officeController.getOffice(this.id);
				
				this.office = OfficeMapperProfile.map(office);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		}
	}
});
