import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { EmbeddedFormState, useEmbeddedFormStore } from "@rmp/core/stores/composables/embeddedForm/useEmbeddedFormStore";
import { validateEmail } from "@rmp/core/utils/validator";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { AccountController } from "@rmp/organization/api/account";
import { EmbeddedFormEditModeTypeEnum } from "@rmp/core/stores/composables/embeddedForm/types/EmbeddedFormEditModeTypeEnum";
import { EmbeddedFormModeTypeEnum } from "@rmp/core/stores/composables/embeddedForm/types/EmbeddedFormModeTypeEnum";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import { useAccountGeneralStore } from "@rmp/organization/stores/account/general";
import { useAccountStore } from "@rmp/organization/stores/account";

const abortService = new AbortService();
const accountController = new AccountController(abortService);

const page = usePageStore(abortService);
const embeddedForm = useEmbeddedFormStore();
const snapshotStore = useSnapshotStore([
    {
        key: SnapshotKeysEnum.LAST_SAVED, fields: ["email"]
    }
]);

export interface AccountGeneralEmailState extends PageState, SnapshotState, EmbeddedFormState {
    email: string;
    confirmedEmail: string;
    emailUnique: boolean;
    emailUniqueCheckInProgress: boolean;
}

const getDefaultState = (): AccountGeneralEmailState => {
    return {
        ...page.getDefaultPageState(),
        ...embeddedForm.getDefaultState(),
        ...snapshotStore.getDefaultState(),
        email: "",
        confirmedEmail: "",
        emailUnique: true,
        emailUniqueCheckInProgress: false
    };
};

export const useAccountEmailStore = defineStore({
    id: "account-general-email",
    state: (): AccountGeneralEmailState => getDefaultState(),
    getters: {
        ...page.getters,
        ...embeddedForm.getters,
        ...snapshotStore.getters,
		breadcrumbs(state: AccountGeneralEmailState) {
			const { breadcrumbs } = useAccountStore();
			
			return [
				...breadcrumbs
			];
		},
    },
    actions: {
        ...page.actions,
        ...embeddedForm.actions,
        ...snapshotStore.actions,
        async beforeInitialized({ email }: { email: string }) {
            if (email) {
                this.email = email;
                this.confirmedEmail = email;
                this.formEditMode = EmbeddedFormEditModeTypeEnum.UPDATE;
            } else {
                this.formEditMode = EmbeddedFormEditModeTypeEnum.CREATE;
            }

            this.formMode = EmbeddedFormModeTypeEnum.DETAILS;

            this.setStateSnapshot();
        },
        async save() {
            this.formSaving = true;

            try {
                await this.update();

                this.formMode = EmbeddedFormModeTypeEnum.SUCCESS;
            } catch (error) {
                console.error(error);
                AlertHelper.handleGeneralRequestErrors(error);
            } finally {
                this.formSaving = false;
            }
        },
        async update() {
            const { id: accountId } = useAccountGeneralStore();

            await accountController.updateAccountEmail(accountId, this.email);
            this.rollbackState();
        },
        async checkEmailUnique() {
            if (!validateEmail(this.email)) {
                this.emailUnique = true;
                return;
            }

            try {
                this.emailUniqueCheckInProgress = true;

                const { id } = useAccountStore();

                let exists = null;

                if (id)
                    exists = await accountController.checkEmailById(id, this.email);
                else
                    exists = await accountController.checkEmail(this.email);

                this.emailUnique = !exists;
                this.emailUniqueCheckInProgress = false;
            } catch (error) {
                AlertHelper.handleGeneralRequestErrors(error);
                this.emailUnique = true;
            }
        }
    }
});
