<template>
	<rmp-form-card>
		<rmp-form-card-blocks>
			<rmp-form-card-block>
				<template #title>
					<rmp-form-card-block-row>
						<rmp-form-card-block-col cols="12" sm="6" md="4">
							<rmp-form-item>
								<rmp-form-block-title-loader/>
							</rmp-form-item>
						</rmp-form-card-block-col>
					</rmp-form-card-block-row>
				</template>

				<rmp-form-card-block-row>
					<rmp-form-card-block-col cols="12" sm="6" md="4">
						<rmp-form-item>
							<rmp-input-loader/>
						</rmp-form-item>
					</rmp-form-card-block-col>
					<rmp-form-card-block-col offset-md="2" cols="12" sm="6" md="4" :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
						<rmp-form-item>
							<rmp-input-loader/>
						</rmp-form-item>
					</rmp-form-card-block-col>
				</rmp-form-card-block-row>
			</rmp-form-card-block>
		</rmp-form-card-blocks>
	</rmp-form-card>
</template>

<script>
import RmpDetailsSectionColumn from "@rmp/core/components/details/RmpDetailsSectionColumn.vue";
import RmpFormCard from "@rmp/core/components/markup/RmpFormCard.vue";
import RmpFormCardBlocks from "@rmp/core/components/markup/RmpFormCardBlocks.vue";
import RmpFormCardBlock from "@rmp/core/components/markup/RmpFormCardBlock.vue";
import RmpFormCardBlockRow from "@rmp/core/components/markup/RmpFormCardBlockRow.vue";
import RmpFormCardBlockCol from "@rmp/core/components/markup/RmpFormCardBlockCol.vue";
import RmpFormItem from "@rmp/core/components/markup/RmpFormItem.vue";
import RmpNestedContentLayout from "@rmp/core/components/layouts/RmpNestedContentLayout.vue";
import RmpInputLoader from "@rmp/core/components/loaders/form/RmpInputLoader.vue";
import RmpFormBlockTitleLoader from "@rmp/core/components/loaders/form/RmpFormBlockTitleLoader.vue";

export default {
	components: {
		RmpNestedContentLayout,
		RmpDetailsSectionColumn,
		RmpFormCardBlocks,
		RmpFormCard,
		RmpFormCardBlock,
		RmpFormCardBlockCol,
		RmpFormCardBlockRow,
		RmpFormItem,
		RmpInputLoader,
		RmpFormBlockTitleLoader
	}
};
</script>
