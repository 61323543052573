import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { Permissions } from "@rmp/core/constants/permissions";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";
import PermissionsService from "@rmp/core/services/permissionsService";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import { CounterpartyController } from "@rmp/core/api/counterparty";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import router from "@rmp/organization/router";
import { RouteNames } from "@rmp/organization/router/routes";
import { validateOgrnip, validatePersonInn } from "@rmp/core/utils/validator";
import { FnsController } from "@rmp/organization/api/fns";
import { LegalPersonController } from "@rmp/core/api/counterparty/legalPerson";
import ApiLegalPersonCounterpartyPersisted from "@rmp/core/api/types/counterparty/apiLegalPersonCounterpartyPersisted";
import { convertIsoToNumber, formatISODate } from "@rmp/core/utils/dates";
import { i18n } from "@rmp/core/plugins";
import { useCounterpartyStore } from "@rmp/organization/stores/counterparty";
import { LegalPersonCounterparty, LegalPersonCounterpartyMapper } from "@rmp/core/types/counterparty/legalPerson/legalPersonCounterparty";
import { LegalPersonFnsUpdate, LegalPersonFnsUpdateMapper } from "@rmp/core/types/counterparty/legalPerson/legalPersonFnsUpdate";
import ApiLegalPersonCounterparty from "@rmp/core/api/types/counterparty/apiLegalPersonCounterparty";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";

const abortService = new AbortService();
const fnsController = new FnsController(abortService);
const legalPersonController = new LegalPersonController(abortService);
const counterpartyController = new CounterpartyController(abortService);
const permissionsService = new PermissionsService();

const page = usePageStore(abortService);
const form = useFormStore();
const snapshotStore = useSnapshotStore([
	{ key: SnapshotKeysEnum.LAST_SAVED, fields: ["counterparty", "fnsUpdate"] }
]);

export interface LegalPersonCounterpartyState extends PageState, SnapshotState, FormState {
	id: string;
	counterpartyInn: string;
	counterparty: LegalPersonCounterparty;
	fnsUpdate: LegalPersonFnsUpdate;
	egripLegalPersonLoading: boolean;
	updateViaFnsOperationExecuting: boolean;
	counterpartySuccessCreatedDialogOpened: boolean;
}

const getDefaultState = (): LegalPersonCounterpartyState => {
	return {
		...page.getDefaultPageState(),
		...form.getDefaultState(),
		...snapshotStore.getDefaultState(),
		id: "",
		counterpartyInn: "",
		counterparty: {
			inn: "",
			ogrnIp: "",
			fullName: "",
			registrationDate: null,
			description: ""
		} as LegalPersonCounterparty,
		fnsUpdate: {
			lastChecked: 0,
			lastUpdated: 0,
			isEnabled: true,
			externalId: "",
			inn: "",
			ogrn: "",
			type: ""
		} as LegalPersonFnsUpdate,
		egripLegalPersonLoading: false,
		updateViaFnsOperationExecuting: false,
		counterpartySuccessCreatedDialogOpened: false
	};
};

export const useLegalPersonCounterpartyStore = defineStore({
	id: "legal-person-counterparty",
	state: (): LegalPersonCounterpartyState => getDefaultState(),
	getters: {
		...page.getters,
		...form.getters,
		...snapshotStore.getters,
		breadcrumbs(state: LegalPersonCounterpartyState) {
			const { breadcrumbs } = useCounterpartyStore();
			return [
				...breadcrumbs
			];
		}
	},
	actions: {
		...page.actions,
		...form.actions,
		...snapshotStore.actions,
		async beforeInitialized({ id }: { id: string }) {
			if(id) {
				this.id = id;
				await this.fetch();
			}
			
			this.formRecordUnique = true;
			this.setStateSnapshot();
		},
		async fetch() {
			this.formStateLoading = true;
			
			try {
				const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_READ])
					? AuthorizationScopeType.GLOBAL
					: AuthorizationScopeType.OWN;
				
				let counterpartyPersisted = await counterpartyController.getCounterparty<ApiLegalPersonCounterpartyPersisted>(this.id,
					ApiLegalPersonCounterpartyPersisted, scope);
				
				// TODO: подправить тип у counterpartyPersisted.counterparty, когда переведем из class в interface "@rmp/core/api/types/counterparty/apiLegalPersonCounterparty"
				// const mappedLegalPersonCounterparty = LegalPersonCounterpartyMapper.map(counterpartyPersisted.counterparty);
				
				let counterparty = {
					...counterpartyPersisted.counterparty,
					registrationDate: convertIsoToNumber(counterpartyPersisted.counterparty.registrationDate)
				};
				
				this.counterpartyInn = counterparty.inn;
				
				const hasFnsReadPermission = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_FNS_READ]);
				if(hasFnsReadPermission) {
					let apiFnsUpdate = await fnsController.getLegalPersonFnsUpdate(counterparty.inn, counterparty.ogrnIp);
					
					if(apiFnsUpdate) {
						const mappedLegalPersonFnsUpdate = LegalPersonFnsUpdateMapper.map(apiFnsUpdate);
						
						this.fnsUpdate = {
							...mappedLegalPersonFnsUpdate,
							isEnabled: true
						} as LegalPersonFnsUpdate;
					} else {
						this.fnsUpdate.isEnabled = false;
					}
				}
				
				this.formRecordUnique = true;
				this.counterparty = counterparty;
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
				this.formDisabled = true;
			} finally {
				this.formStateLoading = false;
			}
		},
		async save() {
			this.formSaving = true;
			
			try {
				let counterparty = {
					...this.counterparty,
					registrationDate: formatISODate(this.counterparty.registrationDate)
				} as ApiLegalPersonCounterparty;
				
				if(this.id) {
					await counterpartyController.updateLegalPersonCounterparty(
						this.id,
						counterparty
					);
					
					if(this.changedSinceLastSavedFields.includes("fnsUpdate")) {
						await this.saveFnsUpdate();
					}
					
					this.setStateSnapshot();
					
					alertService.addSuccess(AlertKeys.SUCCESS_UPDATED_INFO);
				} else {
					let id = await counterpartyController.createLegalPersonCounterparty(counterparty);
					
					this.id = id;
					
					if(this.fnsUpdate.isEnabled)
						await this.saveFnsUpdate();
					
					this.setStateSnapshot();
					
					const hasGlobalPermissionCounterpartyRead = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_READ]);
					
					if(!hasGlobalPermissionCounterpartyRead) {
						this.counterpartySuccessCreatedDialogOpened = true;
					} else {
						alertService.addSuccess(AlertKeys.SUCCESS_CREATED_INFO);
						await router.push({ name: RouteNames.COUNTERPARTY, params: { id } });
					}
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.formSaving = false;
			}
		},
		async checkIsRecordUnique() {
			if(!validatePersonInn(this.counterparty.inn) || !validateOgrnip(this.counterparty.ogrnIp)) {
				this.formRecordUnique = true;
				return;
			}
			
			try {
				this.formRecordUniqueCheckInProgress = true;
				
				const exists = await legalPersonController.checkLegalPersonIsExists(this.counterparty.inn, this.counterparty.ogrnIp);
				this.formRecordUnique = !exists;
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
				this.formRecordUnique = true;
			} finally {
				this.formRecordUniqueCheckInProgress = false;
			}
		},
		async handleInnInput() {
			if(!validatePersonInn(this.counterparty.inn) || !this.fnsUpdate.isEnabled)
				return;
			
			this.formReadonly = true;
			this.egripLegalPersonLoading = true;
			
			try {
				let egripLegalPerson = await fnsController.getEgripLegalPerson(this.counterparty.inn);
				
				if(egripLegalPerson) {
					this.counterparty = LegalPersonCounterpartyMapper.map(egripLegalPerson);
				} else {
					alertService.addCustomError(i18n.t("alerts.errors.legalPersonByInnNotFound", { inn: this.counterparty.inn }) as string);
					this.fnsUpdate.isEnabled = false;
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.formReadonly = false;
				this.egripLegalPersonLoading = false;
			}
		},
		async saveFnsUpdate() {
			let { ogrnIp, inn } = this.counterparty;
			
			if(this.fnsUpdate.isEnabled) {
				await fnsController.enableLegalPersonFnsUpdate(inn, ogrnIp, this.id);
			} else {
				await fnsController.disableLegalPersonFnsUpdate(inn, ogrnIp, this.id);
			}
		},
		async updateViaFns() {
			this.formDisabled = true;
			this.updateViaFnsOperationExecuting = true;
			
			try {
				let { ogrnIp, inn } = this.counterparty;
				await fnsController.updateLegalPersonViaFns(inn, ogrnIp, this.id);
				
				// Временно необходима, так как updateLegalEntityViaFns возвращает ответ не дожидаясь обновления данных
				const delay = 1000;
				await new Promise(resolve => setTimeout(resolve, delay));
				
				await this.fetch();
				
				this.setStateSnapshot();
				
				alertService.addSuccess(AlertKeys.COUNTERPARTY_SUCCESS_UPDATED_VIA_FNS);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.formDisabled = false;
				this.updateViaFnsOperationExecuting = false;
			}
		}
	}
});
