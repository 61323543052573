import { Dictionary } from "vue-router/types/router";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import { RouteNames } from "@rmp/organization/router/routes";
import routeToPermissionsMap from "@rmp/organization/router/routeToPermissionsMap";
import { i18n } from "@rmp/organization/plugins";

export function useAccountProfilesBreadcrumb(params: Dictionary<string>) {
	return useBreadcrumb({
		route: {
			name: RouteNames.ACCOUNT_PROFILES, params
		},
		text: i18n.t(`navigation.breadcrumbs.${RouteNames.ACCOUNT_PROFILES}`).toString(),
		routeToPermissionsMap
	});
}
