import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { LegalEntityController } from "@rmp/core/api/counterparty/legalEntity";
// @ts-ignore
import { checkINN } from "ru-validation-codes";
import { RouteNames } from "@rmp/organization/router/routes";
import { useOfficeStore } from "@rmp/organization/stores/office";
import { cloneDeep } from "lodash";
import { useAppStore } from "@rmp/core/stores/appStore";
import { watch } from "vue";
import { OfficeController } from "@rmp/organization/api/office";
import router from "@rmp/organization/router";
import OfficeMapperProfile, { Office } from "@rmp/organization/stores/office/types/office";
import { i18n } from "@rmp/organization/plugins";

const abortService = new AbortService();
const legalEntityController = new LegalEntityController(abortService);
const officeController = new OfficeController(abortService);

const page = usePageStore(abortService);
const form = useFormStore();
const snapshotStore = useSnapshotStore([
	{
		key: SnapshotKeysEnum.LAST_SAVED, fields: ["office"]
	}
]);

export interface OfficeGeneralState extends PageState, FormState, SnapshotState {
	id: string;
	egrulLegalEntityLoading: boolean;
	office: Office;
}

const getDefaultState = (): OfficeGeneralState => {
	return {
		...page.getDefaultPageState(),
		...form.getDefaultState(),
		...snapshotStore.getDefaultState(),
		id: "",
		egrulLegalEntityLoading: false,
		office: {} as Office
	};
};

export const useOfficeGeneralStore = defineStore({
	id: "office-general",
	state: (): OfficeGeneralState => getDefaultState(),
	getters: {
		...page.getters,
		...form.getters,
		...snapshotStore.getters,
		breadcrumbs() {
			const { breadcrumbs } = useOfficeStore();
			
			return breadcrumbs;
		}
	},
	actions: {
		...page.actions,
		...form.actions,
		...snapshotStore.actions,
		async beforeInitialized() {
			const { id, office } = useOfficeStore();
			
			this.id = id;
			this.office = cloneDeep(office);
			
			this.setStateSnapshot();
			
			watch(() => this.office.inn, async (value) => {
				if(checkINN(value || "") && this.stateContainsChangesSinceLastSaved)
					await this.handleInnInput();
			})
		},
		async save() {
			this.formSaving = true;
			
			try {
				if(this.id) {
					await officeController.updateOffice(this.id, this.office);
					
					alertService.addSuccess(AlertKeys.SUCCESS_UPDATED_INFO);
					
					this.setStateSnapshot();
				} else {
					const { id } = await officeController.createOffice(this.office);

					this.setStateSnapshot();
					
					alertService.addSuccess(AlertKeys.SUCCESS_CREATED_INFO);
					
					await router.push({ name: RouteNames.OFFICE_GENERAL, params: { officeId: id } });
				}
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.formSaving = false;
			}
		},
		async handleInnInput() {
			this.formReadonly = true;
			this.egrulLegalEntityLoading = true;
			
			try {
				let suggestion = await legalEntityController.getLegalEntitySuggestion(this.office.inn, "");
				
				if(suggestion) {
					this.office = OfficeMapperProfile.mapFromApiLegalEntity(suggestion);
				} else {
					alertService.addCustomError(String(i18n.t("alerts.errors.legalEntityByInnNotFound", { inn: this.office.inn })));
				}
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.formReadonly = false;
				this.egrulLegalEntityLoading = false;
			}
		}
	}
});
