<template>
	<rmp-form-card>
		<rmp-form-card-blocks>
			<rmp-form-card-block>
				<template #title>
					<rmp-form-card-block-row>
						<rmp-form-card-block-col cols="12" sm="6" md="4">
							<rmp-form-item>
								<rmp-form-block-title-loader/>
							</rmp-form-item>
						</rmp-form-card-block-col>
					</rmp-form-card-block-row>
				</template>

				<rmp-form-card-block-row>
					<rmp-form-card-block-col cols="12" sm="6" md="4">
						<rmp-form-item v-for="i in 3" :key="i">
							<rmp-input-loader/>
						</rmp-form-item>
						<rmp-form-item>
							<rmp-textarea-loader/>
						</rmp-form-item>
						<rmp-form-item>
							<rmp-switch-loader/>
						</rmp-form-item>
					</rmp-form-card-block-col>
					<rmp-form-card-block-col offset-md="2" cols="12" sm="6" md="4" :class="{ 'pt-0': $vuetify.breakpoint.xsOnly }">
						<rmp-form-item v-for="i in 5" :key="i">
							<rmp-input-loader/>
						</rmp-form-item>
					</rmp-form-card-block-col>
				</rmp-form-card-block-row>
			</rmp-form-card-block>
		</rmp-form-card-blocks>
	</rmp-form-card>
</template>

<script>
import RmpDetailsItemLoader from "@rmp/core/components/loaders/details/RmpDetailsItemLoader.vue";
import RmpDetailsGroup from "@rmp/core/components/details/RmpDetailsGroup.vue";
import RmpDetailsSectionTitleLoader from "@rmp/core/components/loaders/details/RmpDetailsSectionTitleLoader.vue";
import RmpFileLoader from "@rmp/core/components/loaders/common/RmpFileLoader.vue";
import RmpDetailsSectionColumn from "@rmp/core/components/details/RmpDetailsSectionColumn.vue";
import RmpFormCard from "@rmp/core/components/markup/RmpFormCard.vue";
import RmpFormCardBlocks from "@rmp/core/components/markup/RmpFormCardBlocks.vue";
import RmpFormCardBlock from "@rmp/core/components/markup/RmpFormCardBlock.vue";
import RmpFormCardBlockRow from "@rmp/core/components/markup/RmpFormCardBlockRow.vue";
import RmpFormCardBlockCol from "@rmp/core/components/markup/RmpFormCardBlockCol.vue";
import RmpFormItem from "@rmp/core/components/markup/RmpFormItem.vue";
import RmpSwitchLoader from "@rmp/core/components/loaders/form/RmpSwitchLoader.vue";
import RmpTextareaLoader from "@rmp/core/components/loaders/form/RmpTextareaLoader.vue";
import RmpDetailsTitleLoader from "@rmp/core/components/loaders/details/RmpDetailsTitleLoader.vue";
import RmpNestedContentLayout from "@rmp/core/components/layouts/RmpNestedContentLayout.vue";
import RmpInputLoader from "@rmp/core/components/loaders/form/RmpInputLoader.vue";
import RmpFormBlockTitleLoader from "@rmp/core/components/loaders/form/RmpFormBlockTitleLoader.vue";
import { RouteNames } from "@rmp/organization/router/routes";

export default {
	data() {
		return {
			RouteNames
		};
	},
	components: {
		RmpDetailsTitleLoader,
		RmpNestedContentLayout,
		RmpDetailsItemLoader,
		RmpDetailsGroup,
		RmpDetailsSectionTitleLoader,
		RmpFileLoader,
		RmpDetailsSectionColumn,
		RmpFormCardBlocks,
		RmpFormCard,
		RmpFormCardBlock,
		RmpFormCardBlockCol,
		RmpFormCardBlockRow,
		RmpFormItem,
		RmpInputLoader,
		RmpFormBlockTitleLoader,
		RmpSwitchLoader,
		RmpTextareaLoader
	}
};
</script>
