import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import { RouteNames } from "@rmp/organization/router/routes";
import router from "@rmp/organization/router";
import SearchSuggestion from "@rmp/core/services/searchSuggestion/searchSuggestion";
import { convertIsoToNumber, formatDate } from "@rmp/core/utils/dates";
import { dateTimeFormat } from "@rmp/core/utils/formats";
import { formatFullName } from "@rmp/core/utils/formatting";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import OfficeEmployeesRouteQueryService from "@rmp/organization/stores/office/employees/services/officeEmployeesRouteQueryService";
import OfficeEmployeesFilter from "@rmp/organization/stores/office/employees/types/officeEmployeesFilter";
import OfficeEmployeesRouteQuery from "@rmp/organization/stores/office/employees/types/officeEmployeesRouteQuery";
import { RoleController } from "@rmp/organization/api/roles";
import { OfficeController } from "@rmp/organization/api/office";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { useOfficeStore } from "@rmp/organization/stores/office";
import { useOfficeEmployeesBreadcrumb } from "@rmp/organization/stores/office/employees/composables/useOfficeEmployeesBreadcrumb";
import OfficeEmployeesMapperProfile from "@rmp/organization/stores/office/employees/mapper";
import { ApiOfficeEmployeeRole } from "@rmp/core/api/types/roles/apiOfficeEmployeeRole";
import { OfficeEmployeesItem } from "@rmp/organization/stores/office/employees/types/officeEmployeesItem";
import { ApiOfficeEmployeePersisted } from "@rmp/core/api/types/office/apiOfficeEmployee";

const abortService = new AbortService();
const roleController = new RoleController(abortService);
const officeController = new OfficeController(abortService);

export interface OfficeEmployeesState extends PageState, RegisterState<ApiOfficeEmployeePersisted, OfficeEmployeesFilter>
{
	filter: OfficeEmployeesFilter;
	searchSuggestions: SearchSuggestion[];
	officeId: string;
	roles: ApiOfficeEmployeeRole[];
	rolesLoading: boolean;
}

const defaultRouteQuery = new OfficeEmployeesRouteQuery(1,
	"createdAt",
	SortingOrderTypeEnum.DESCENDING,
	"",
	[]);

const routeQueryService = new OfficeEmployeesRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);

const register = useRegisterStore<ApiOfficeEmployeePersisted, OfficeEmployeesFilter>({
	routeName: RouteNames.OFFICE_EMPLOYEES,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): OfficeEmployeesState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		filter: {
			roles: []
		} as OfficeEmployeesFilter,
		searchSuggestions: [],
		officeId: "",
		roles: [],
		rolesLoading: false
	};
};

export const useOfficeEmployeesStore = defineStore({
	id: "office-employees",
	state: (): OfficeEmployeesState => getDefaultState(),
	getters: {
		...register.getters,
		...page.getters,
		formattedItems(state: OfficeEmployeesState): OfficeEmployeesItem[] {
			return state.listing.items.map(x => {
				return {
					id: x.id,
					fio: formatFullName({ ...x.employee }),
					position: x.employee.position,
					description: x.employee.description,
					isActive: x.employee.isActive,
					roles: x.employee.roles.map((x) => x.description).join(", "),
					createdAt: formatDate(convertIsoToNumber(x.createdAt), dateTimeFormat) || "",
					number: x.number
				};
			});
		},
		filteredItems(): OfficeEmployeesItem[] {
			return this.formattedItems;
		},
		formattedRolesItems(state: OfficeEmployeesState): { text: string, value: string }[] {
			return state.roles.map(x => {
				return {
					text: x.description,
					value: `${x.id}`
				};
			});
		},
		breadcrumbs(state: OfficeEmployeesState) {
			const { breadcrumbs } = useOfficeStore();
			
			return [
				...breadcrumbs,
				useOfficeEmployeesBreadcrumb({ officeId: state.officeId })
			];
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.officeId = id;
			
			await Promise.all([
				this.initializeRegister(),
				this.fetchRoles()
			]);
		},
		patchStateWithRouteQuery(query: OfficeEmployeesRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.roles = query.filterRoles;
			});
		},
		async fetchRegisterItems() {
			let { employees, totalCount } = await officeController.getOfficeEmployees(this.officeId,
				OfficeEmployeesMapperProfile.mapToApiGetOfficeEmployeesParameters(this));
			return { items: employees, totalCount };
		},
		async fetchRoles() {
			this.rolesLoading = true;
			
			try {
				this.roles = await roleController.getOfficeEmployeeRoles();
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.rolesLoading = false;
			}
		}
	}
});
