import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";
import AccessForbiddenException from "@rmp/core/exceptions/accessForbiddenException";
import { AccountController } from "@rmp/organization/api/account";
import { Account, AccountMapper } from "@rmp/core/types/account/account";
import { useAccountsBreadcrumb } from "@rmp/organization/stores/accounts/composables/useAccountsBreadcrumb";
import { useAccountBreadcrumb } from "@rmp/organization/stores/account/general/composables/useAccountBreadcrumb";

const abortService = new AbortService();
const accountController = new AccountController(abortService);

const page = usePageStore(abortService);

export interface AccountState extends PageState {
	id: string;
	account: Account;
}

const getDefaultState = (): AccountState => {
	return {
		...page.getDefaultPageState(),
		id: "",
		account: AccountMapper.getEmpty()
	};
};

export const useAccountStore = defineStore({
	id: "account",
	state: (): AccountState => getDefaultState(),
	getters: {
		...page.getters,
		breadcrumbs(state: AccountState) {
			return [
				useAccountsBreadcrumb(),
				useAccountBreadcrumb(state.account.login, { id: state.id })
			];
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized({ id }: { id: string }) {
			if(!id) return;
			
			this.id = id;
			await this.fetch();
		},
		async fetch() {
			try {
				let { account } = await accountController.getAccount(this.id);
				this.account = AccountMapper.map(account);
			} catch (error) {
				const { setPageModeNotFound, setPageModeAccessForbidden } = useAppStore();
				
				switch (error.constructor) {
					case HttpNotFoundException:
						setPageModeNotFound();
						break;
					case AccessForbiddenException:
						setPageModeAccessForbidden();
						break;
					default:
						AlertHelper.handleGeneralRequestErrors(error);
						break;
				}
			}
		}
	}
});
