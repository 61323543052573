import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import PermissionsService from "@rmp/core/services/permissionsService";
import { CounterpartyController } from "@rmp/core/api/counterparty";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import { useCounterpartyEmployeeStore } from "@rmp/organization/stores/counterpartyEmployee";
import { Permissions } from "@rmp/core/constants/permissions";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";
import {
	CounterpartyEmployeeGeneral,
	CounterpartyEmployeeGeneralMapper
} from "@rmp/core/types/counterpartyEmployee/counterpartyEmployeeGeneral";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { CounterpartyTypeEnum } from "@rmp/core/types/CounterpartyTypeEnum";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { LegalPersonController } from "@rmp/core/api/counterparty/legalPerson";
import { LegalEntityController } from "@rmp/core/api/counterparty/legalEntity";
import { ForeignOrganizationController } from "@rmp/core/api/counterparty/foreignOrganization";
import { useUserStore } from "@rmp/core/stores/user";
import router from "@rmp/organization/router";
import { RouteNames } from "@rmp/organization/router/routes";
import { JoiningEmployeeToCounterpartyMethodType } from "@rmp/core/types/counterpartyEmployee/JoiningEmployeeToCounterpartyMethodType";
import { ApiCounterpartyEmployeeBaseMapper } from "@rmp/core/api/types/counterparty/apiCounterpartyEmployee";
import { formatFullName } from "@rmp/core/utils/formatting";
import {
	useCounterpartyEmployeeGeneralBreadcrumb
} from "@rmp/organization/stores/counterpartyEmployee/general/composables/useCounterpartyEmployeeGeneralBreadcrumb";
import { i18n } from "@rmp/core/plugins";

const abortService = new AbortService();
const counterpartyController = new CounterpartyController(abortService);
const legalPersonController = new LegalPersonController(abortService);
const legalEntityController = new LegalEntityController(abortService);
const foreignOrganizationController = new ForeignOrganizationController(abortService);
const permissionsService = new PermissionsService();

const page = usePageStore(abortService);
const form = useFormStore();
const snapshotStore = useSnapshotStore([
	{
		key: SnapshotKeysEnum.LAST_SAVED, fields: ["employee"]
	}
]);

export interface CounterpartyEmployeeGeneralState extends PageState, FormState, SnapshotState {
	id: string;
	counterpartyId: string;
	employee: CounterpartyEmployeeGeneral;
	masterAccountExitDialogOpened: boolean;
}

const getDefaultState = (): CounterpartyEmployeeGeneralState => {
	return {
		...page.getDefaultPageState(),
		...form.getDefaultState(),
		...snapshotStore.getDefaultState(),
		id: "",
		counterpartyId: "",
		employee: {
			inn: "",
			email: "",
			phone: "",
			position: "",
			snils: "",
			firstName: "",
			middleName: "",
			lastName: "",
			isActive: true
		} as CounterpartyEmployeeGeneral,
		masterAccountExitDialogOpened: false
	};
};

export const useCounterpartyEmployeeGeneralStore = defineStore({
	id: "counterparty-employee-general",
	state: (): CounterpartyEmployeeGeneralState => getDefaultState(),
	getters: {
		...page.getters,
		...form.getters,
		...snapshotStore.getters,
		breadcrumbs(state: CounterpartyEmployeeGeneralState) {
			let { breadcrumbs } = useCounterpartyEmployeeStore();
			const routeName = state.id ? RouteNames.COUNTERPARTY_EMPLOYEE_GENERAL : RouteNames.COUNTERPARTY_EMPLOYEE_CREATE;
			const text = state.id ? formatFullName(state.employee) : String(i18n.t("titles.createEmployee"));
			
			
			return [
				...breadcrumbs,
				useCounterpartyEmployeeGeneralBreadcrumb(routeName, text, { id: state.id })
			];
		}
	},
	actions: {
		...page.actions,
		...form.actions,
		...snapshotStore.actions,
		async beforeInitialized({ id, counterpartyId }: { id: string | null, counterpartyId: string }) {
			this.counterpartyId = counterpartyId;
			
			if(id) {
				this.id = id;
				await this.fetch();
			}
			
			this.setStateSnapshot();
		},
		async fetch() {
			try {
				const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_READ])
					? AuthorizationScopeType.GLOBAL
					: AuthorizationScopeType.OWN;
				
				let { employee } = await counterpartyController.getCounterpartyEmployee(this.id, this.counterpartyId, scope);
				this.employee = CounterpartyEmployeeGeneralMapper.map(employee);
			} catch (ex) {
				console.error(ex);
				AlertHelper.handleGeneralRequestErrors(ex);
				this.formDisabled = true;
			}
		},
		async save() {
			this.formSaving = true;
			
			try {
				const { counterparty, counterpartyId, assignEmployee } = useCounterpartyEmployeeStore();
				
				const apiEmployee = ApiCounterpartyEmployeeBaseMapper.map(this.employee);
				apiEmployee.counterpartyId = counterpartyId;
				
				const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_UPDATE])
					? AuthorizationScopeType.GLOBAL
					: AuthorizationScopeType.OWN;
				
				switch (counterparty.type) {
					case CounterpartyTypeEnum.LEGAL_ENTITY:
						await legalEntityController.updateLegalEntityCounterpartyEmployee(this.id, apiEmployee, scope);
						break;
					case CounterpartyTypeEnum.LEGAL_PERSON:
						await legalPersonController.updateLegalPersonCounterpartyEmployee(counterpartyId, this.id, apiEmployee, scope);
						break;
					case CounterpartyTypeEnum.FOREIGN_ORGANIZATION:
						await foreignOrganizationController.updateForeignOrganizationCounterpartyEmployee(this.id, apiEmployee);
						break;
				}
				
				assignEmployee({
					snils: this.employee.snils,
					firstName: this.employee.firstName,
					middleName: this.employee.middleName,
					lastName: this.employee.lastName
				});
				
				this.setStateSnapshot();
				alertService.addSuccess(AlertKeys.SUCCESS_UPDATED_INFO);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.formSaving = false;
			}
		},
		async create({ joiningEmployeeToCounterpartyMethod }: { joiningEmployeeToCounterpartyMethod: JoiningEmployeeToCounterpartyMethodType }) {
			this.formSaving = true;
			
			try {
				let { counterparty, counterpartyId } = useCounterpartyEmployeeStore();
				
				const apiEmployee = ApiCounterpartyEmployeeBaseMapper.map(this.employee);
				apiEmployee.counterpartyId = counterpartyId;
				
				let assignToAccount = joiningEmployeeToCounterpartyMethod === JoiningEmployeeToCounterpartyMethodType.REGISTER;
				
				const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_CREATE])
					? AuthorizationScopeType.GLOBAL
					: AuthorizationScopeType.OWN;
				
				let employeeId;
				
				switch (counterparty.type) {
					case CounterpartyTypeEnum.LEGAL_ENTITY:
						employeeId = await legalEntityController.createLegalEntityCounterpartyEmployee(counterpartyId,
							apiEmployee,
							assignToAccount,
							scope);
						break;
					case CounterpartyTypeEnum.LEGAL_PERSON:
						employeeId = await legalPersonController.createLegalPersonCounterpartyEmployee(counterpartyId,
							apiEmployee,
							assignToAccount,
							scope);
						break;
					case CounterpartyTypeEnum.FOREIGN_ORGANIZATION:
						employeeId = await foreignOrganizationController.createForeignOrganizationCounterpartyEmployee(counterpartyId,
							apiEmployee,
							assignToAccount);
						break;
				}
				
				this.setStateSnapshot();
				
				alertService.addSuccess(assignToAccount ? AlertKeys.EMPLOYEE_SUCCESS_CREATED : AlertKeys.EMPLOYEE_SUCCESS_INVITED);
				
				const { isMasterAccount } = useUserStore();
				
				if(isMasterAccount && employeeId) {
					await router.push({ name: RouteNames.COUNTERPARTY_EMPLOYEE_GENERAL, params: { counterpartyId, employeeId } });
					
					this.masterAccountExitDialogOpened = true;
				} else {
					await router.push({ name: RouteNames.COUNTERPARTY_EMPLOYEES, params: { id: counterpartyId } });
				}
				
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.formSaving = false;
			}
		}
	}
});
