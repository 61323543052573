import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { RouteNames } from "@rmp/organization/router/routes";
import router from "@rmp/organization//router";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { CounterpartyDocumentsFilter } from "@rmp/core/types/counterparty/documents/counterpartyDocumentsFilter";
import { ArchiveStatusType } from "@rmp/core/types/ArchiveStatusType";
import CounterpartyDocumentsMapperProfile from "@rmp/organization/stores/counterparty/documents/mapper";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { saveAs } from "file-saver";
import { StorageController } from "@rmp/core/api/storage";
import { z } from "zod";
import { AVAILABLE_SORTS } from "@rmp/organization/stores/counterparty/documents/constants";
import { useRouteQueryService } from "@rmp/core/composables/useRouteQueryService";
import ApiFileMeta from "@rmp/core/api/types/storage/apiFileMeta";
import { CounterpartyDocument } from "@rmp/core/types/counterparty/documents/counterpartyDocument";
import { CounterpartyDocumentsController } from "@rmp/core/api/counterpartyDocument";
import { useCounterpartyStore } from "@rmp/organization/stores/counterparty";
import { useCounterpartyDocumentsBreadcrumb } from "@rmp/organization/stores/counterparty/documents/composables/useCounterpartyDocumentsBreadcrumb";

const abortService = new AbortService();
const counterpartyDocumentsController = new CounterpartyDocumentsController(abortService);
const storageController = new StorageController(abortService);


const schema = z.object({
	sort: z.nativeEnum(AVAILABLE_SORTS).default(AVAILABLE_SORTS.CREATED_AT),
	status: z.nativeEnum(ArchiveStatusType).default(ArchiveStatusType.ALL)
});

const { defaultRouteQuery, ...routeQueryService } = useRouteQueryService(schema.shape);



const page = usePageStore(abortService);

export interface CounterpartyDocumentsState extends PageState, RegisterState<CounterpartyDocument, CounterpartyDocumentsFilter>
{
	id: string;
	filter: CounterpartyDocumentsFilter;
}


const register = useRegisterStore<CounterpartyDocument, CounterpartyDocumentsFilter>({
	routeName: RouteNames.COUNTERPARTY_DOCUMENTS,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): CounterpartyDocumentsState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		id: "",
		filter: {
			status: ArchiveStatusType.ALL,
		}
	};
};

export const useCounterpartyDocumentsStore = defineStore({
	id: "counterparty-documents",
	state: (): CounterpartyDocumentsState => getDefaultState(),
	getters: {
		...register.getters,
		...page.getters,
		breadcrumbs(state: CounterpartyDocumentsState) {
			const { breadcrumbs } = useCounterpartyStore();
			
			return [
				...breadcrumbs,
				useCounterpartyDocumentsBreadcrumb({ id: state.id })
			];
		},
		filteredItems(state: CounterpartyDocumentsState) {
			return state.listing.items;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized() {
			this.id = router.currentRoute.params.id;
			
			await this.initializeRegister();
			
		},
		patchStateWithRouteQuery(query: typeof defaultRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.status = query.status;
			});
		},
		async fetchRegisterItems() {
			let { documents, totalCount } = await counterpartyDocumentsController.getDocuments(router.currentRoute.params.id, 
				CounterpartyDocumentsMapperProfile.mapToApiGetCounterpartyDocumentsParameters(this));
			return {
				items: documents.map(x => CounterpartyDocumentsMapperProfile.mapToCounterpartyDocument(x)),
				totalCount
			};
		},
		async downloadFile(id: string) {
			try {
				const fileMeta = await storageController.getFileMeta(id);
				
				const file = await storageController.getFile(fileMeta.id);
				saveAs(file, fileMeta.name);
			} catch (ex) {
				console.error(ex);
				
				const { handleInternalServerError } = useAppStore();
				handleInternalServerError(ex);
				
				AlertHelper.handleGeneralRequestErrors(ex);
			}
		},
		setDocumentFileMeta(id: string, meta: ApiFileMeta) {
			this.listing.items.find(x => x.id === id)!.fileMeta = meta;
		},
	}
});
