import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import NotDefinedException from "@rmp/core/exceptions/notDefinedException";
import { AuthorizationController } from "@rmp/core/api/authorization";
import { AccountController } from "@rmp/organization/api/account";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { FormState, useFormStore } from "@rmp/core/stores/composables/form/useFormStore";
import ApiOfficeEmployeeProfile from "@rmp/core/api/types/account/profile/apiOfficeEmployeeProfile";
import { useOfficeEmployeeStore } from "@rmp/organization/stores/officeEmployee";
import {
	useOfficeEmployeeProfileBreadcrumb
} from "@rmp/organization/stores/officeEmployee/profile/composables/useOfficeEmployeeProfileBreadcrumb";
import { RouteNames } from "@rmp/organization/router/routes";
import { formatFullName } from "@rmp/core/utils/formatting";
import {
	useOfficeEmployeeGeneralBreadcrumb
} from "@rmp/organization/stores/officeEmployee/general/composables/useOfficeEmployeeGeneralBreadcrumb";

const abortService = new AbortService();
const authorizationController = new AuthorizationController(abortService);
const accountController = new AccountController(abortService);

const page = usePageStore(abortService);
const form = useFormStore();
const snapshotStore = useSnapshotStore([
	{
		key: SnapshotKeysEnum.LAST_SAVED, fields: ["profile"]
	}
]);

export interface OfficeEmployeeProfileState extends PageState, FormState, SnapshotState {
	id: string;
	// TODO: поправить ApiOfficeEmployeeProfile на interface и все его вложенные типы 
	profile: ApiOfficeEmployeeProfile;
	accountId: string;
	requestPhoneConfirmationOperationExecuting: boolean;
	signatureRequiredToggleLoading: boolean;
}

const getDefaultState = (): OfficeEmployeeProfileState => {
	return {
		...page.getDefaultPageState(),
		...form.getDefaultState(),
		...snapshotStore.getDefaultState(),
		id: "",
		profile: new ApiOfficeEmployeeProfile(),
		accountId: "",
		requestPhoneConfirmationOperationExecuting: false,
		signatureRequiredToggleLoading: false
	};
};

export const useOfficeEmployeeProfileStore = defineStore({
	id: "office-employee-profile",
	state: (): OfficeEmployeeProfileState => getDefaultState(),
	getters: {
		...page.getters,
		...form.getters,
		...snapshotStore.getters,
		breadcrumbs(state: OfficeEmployeeProfileState) {
			const { breadcrumbs, employee } = useOfficeEmployeeStore();
			
			return [
				...breadcrumbs,
				useOfficeEmployeeGeneralBreadcrumb(RouteNames.OFFICE_EMPLOYEE_GENERAL, formatFullName(employee)),
				useOfficeEmployeeProfileBreadcrumb()
			];
		}
	},
	actions: {
		...page.actions,
		...form.actions,
		...snapshotStore.actions,
		async beforeInitialized() {
			const { profile: persistedProfile } = useOfficeEmployeeStore();
			
			if(!persistedProfile) throw new NotDefinedException("persistedProfile");
			
			const { id, profile } = persistedProfile;
			const { accountId } = profile;
			
			this.id = id;
			this.accountId = accountId;
			this.profile = profile as ApiOfficeEmployeeProfile;
			
			this.setStateSnapshot();
		},
		async requestPhoneConfirmation() {
			this.requestPhoneConfirmationOperationExecuting = true;
			
			try {
				await authorizationController.requestPhoneConfirmation(this.id);
				
				this.profile.isPhoneConfirmed = false;
				
				alertService.addSuccess(AlertKeys.PHONE_CONFIRMATION_REQUEST_SUCCESS_CREATED);
			} catch (ex) {
				console.error(ex);
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.requestPhoneConfirmationOperationExecuting = false;
			}
		},
		async toggleSignatureRequired(value: boolean) {
			this.signatureRequiredToggleLoading = true;
			
			try {
				await accountController.toggleSignatureRequired(this.id, value);
				
				this.profile.isSignatureRequired = value;
				
				alertService.addSuccess(AlertKeys.SUCCESS_UPDATED_INFO);
			} catch (ex) {
				console.error(ex);
				AlertHelper.handleGeneralRequestErrors(ex);
			} finally {
				this.signatureRequiredToggleLoading = false;
			}
		}
	}
});
