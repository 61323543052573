import RmpRouterView from "@rmp/core/components/common/RmpRouterView.vue";
import { stringifyQuery } from "@rmp/core/utils/query";
import RmpAccount from "@rmp/organization/views/account/RmpAccount.vue";
import RmpAccountGeneral from "@rmp/organization/views/account/sections/RmpAccountGeneral.vue";
import RmpAccountProfiles from "@rmp/organization/views/account/sections/RmpAccountProfiles.vue";
import RmpAccounts from "@rmp/organization/views/accounts/RmpAccounts.vue";
import RmpCounterparties from "@rmp/organization/views/counterparties/RmpCounterparties.vue";
import RmpCounterparty from "@rmp/organization/views/counterparty/RmpCounterparty.vue";
import RmpCounterpartyEmployees from "@rmp/organization/views/counterparty/sections/employees/RmpCounterpartyEmployees.vue";
import RmpCounterpartyGeneral from "@rmp/organization/views/counterparty/sections/RmpCounterpartyGeneral.vue";
import RmpCounterpartyMasterAccount from "@rmp/organization/views/counterparty/sections/masterAccount/RmpCounterpartyMasterAccount.vue";
import RmpCounterpartyEmployee from "@rmp/organization/views/counterpartyEmployee/RmpCounterpartyEmployee.vue";
import RmpCounterpartyEmployeeGeneral from "@rmp/organization/views/counterpartyEmployee/RmpCounterpartyEmployeeGeneral.vue";
import RmpCounterpartyEmployeeProfile from "@rmp/organization/views/counterpartyEmployee/RmpCounterpartyEmployeeProfile.vue";
import RmpOfficeEmployeeProfile from "@rmp/organization/views/officeEmployee/RmpOfficeEmployeeProfile.vue";
import RmpCounterpartyEmployeeTrust from "@rmp/organization/views/counterpartyEmployee/RmpCounterpartyEmployeeTrust.vue";
import RmpCounterpartyEmployeeTrusts from "@rmp/organization/views/counterpartyEmployee/RmpCounterpartyEmployeeTrusts.vue";
import RmpInternalServerError from "@rmp/core/pages/errors/RmpInternalServerError.vue";
import RmpAccessDeniedError from "@rmp/core/pages/errors/RmpAccessDeniedError.vue";
import RmpPageNotFound from "@rmp/core/pages/errors/RmpPageNotFound.vue";
import RmpConfirmEmail from "@rmp/core/pages/confirmation/RmpConfirmEmail.vue";
import RmpConfirmPhone from "@rmp/core/pages/confirmation/RmpConfirmPhone.vue";
import RmpSilentRenew from "@rmp/organization/views/RmpSilentRenew.vue";
import RmpOffices from "@rmp/organization/views/offices/RmpOffices.vue";
import RmpOffice from "@rmp/organization/views/office/RmpOffice.vue";
import RmpOfficeEmployee from "@rmp/organization/views/officeEmployee/RmpOfficeEmployee.vue";
import RmpOfficeEmployeeGeneral from "@rmp/organization/views/officeEmployee/RmpOfficeEmployeeGeneral.vue";
import RmpOfficeGeneral from "@rmp/organization/views/office/sections/RmpOfficeGeneral.vue";
import RmpOfficeEmployees from "@rmp/organization/views/office/sections/RmpOfficeEmployees.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import RmpOuterLayout from "@rmp/core/components/layouts/RmpOuterLayout.vue";
import RmpLayout from "@rmp/organization/views/layouts/RmpLayout.vue";
import RmpEmptyLayout from "@rmp/core/components/layouts/RmpEmptyLayout.vue";
import RmpMasterAccountAccessApplicationsList
	from "@rmp/organization/views/applications/masterAccountAccess/list/RmpMasterAccountAccessApplicationsList.vue";
import RmpMasterAccountApplicationDetails from "@rmp/organization/views/applications/masterAccountAccess/details/RmpMasterAccountApplicationDetails.vue";
import RmpCallback from "@rmp/organization/views/RmpCallback.vue";
import RmpCounterpartyBankAccounts from "@rmp/organization/views/counterparty/sections/bankAccounts/RmpCounterpartyBankAccounts.vue";
import RmpCounterpartyBankAccount from "@rmp/organization/views/counterpartyBankAccount/RmpCounterpartyBankAccount.vue";
import { RouteNames } from "@rmp/organization/router/routes";
import { configureMiddleware } from "@rmp/organization/router/middleware";
import RmpResetPassword from "@rmp/core/pages/resetPassword/RmpResetPassword.vue";
import RmpApps from "@rmp/organization/views/apps/RmpApps.vue";
import RmpLogout from "@rmp/organization/views/RmpLogout.vue";
import RmpCounterpartyDocuments from "@rmp/organization/views/counterparty/sections/documents/RmpCounterpartyDocuments.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/callback.html",
		component: RmpCallback,
		meta: { public: true },
		name: RouteNames.CALLBACK
	},
	{
		path: "/logout.html",
		component: RmpLogout,
		meta: { public: true },
		name: RouteNames.LOGOUT
	},
	{
		path: "/silent-renew.html",
		component: RmpSilentRenew,
		meta: { public: true },
		name: RouteNames.SILENT_RENEW
	},
	{
		path: "/",
		component: RmpLayout,
		name: RouteNames.ROOT,
		children: [
			{
				path: "apps",
				component: RmpApps,
				name: RouteNames.APPS
			},
			{
				path: "applications",
				component: RmpRouterView,
				name: RouteNames.APPLICATIONS,
				redirect: { name: RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESSES },
				children: [
					{
						path: "master-account-accesses",
						component: RmpRouterView,
						children: [
							{
								path: "",
								name: RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESSES,
								component: RmpMasterAccountAccessApplicationsList
							},
							{
								path: ":id",
								name: RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESS_DETAILS,
								component: RmpMasterAccountApplicationDetails
							}
						]
					},
				]
			},
			{
				path: "offices",
				name: RouteNames.OFFICES,
				component: RmpOffices
			},
			{
				path: "office",
				component: RmpOffice,
				redirect: { name: RouteNames.OFFICE_CREATE },
				children: [
					{
						meta: { key: "office-create" },
						path: "",
						name: RouteNames.OFFICE_CREATE,
						component: RmpOfficeGeneral
					}
				]
			},
			{
				path: "office",
				component: RmpRouterView,
				children: [
					{
						path: ":officeId",
						name: RouteNames.OFFICE,
						redirect: { name: RouteNames.OFFICE_GENERAL },
						component: RmpOffice,
						children: [
							{
								path: "",
								meta: { key: "office-update" },
								name: RouteNames.OFFICE_GENERAL,
								component: RmpOfficeGeneral
							},
							{
								path: "employees",
								meta: { key: "office-update" },
								name: RouteNames.OFFICE_EMPLOYEES,
								component: RmpOfficeEmployees
							}
						]
					}
				]
			},
			{
				path: "office/:officeId/employee",
				redirect: { name: RouteNames.OFFICE_EMPLOYEE_CREATE },
				component: RmpOfficeEmployee,
				children: [
					{
						path: "",
						name: RouteNames.OFFICE_EMPLOYEE_CREATE,
						component: RmpOfficeEmployeeGeneral
					}
				]
			},
			{
				path: "office/:officeId/employee/:employeeId",
				name: RouteNames.OFFICE_EMPLOYEE,
				redirect: { name: RouteNames.OFFICE_EMPLOYEE_GENERAL },
				component: RmpOfficeEmployee,
				children: [
					{
						meta: { key: "office-employee-update" },
						path: "",
						name: RouteNames.OFFICE_EMPLOYEE_GENERAL,
						component: RmpOfficeEmployeeGeneral
					},
					{
						meta: { key: "office-employee-update" },
						path: "profile",
						name: RouteNames.OFFICE_EMPLOYEE_PROFILE,
						component: RmpOfficeEmployeeProfile
					}
				]
			},
			{
				path: "counterparties",
				name: RouteNames.COUNTERPARTIES,
				component: RmpCounterparties
			},
			{
				path: "counterparty",
				component: RmpCounterparty,
				redirect: { name: RouteNames.COUNTERPARTY_CREATE },
				children: [
					{
						meta: { key: "counterparty-create" },
						path: "",
						name: RouteNames.COUNTERPARTY_CREATE,
						component: RmpCounterpartyGeneral
					}
				]
			},
			{
				path: "counterparty",
				component: RmpRouterView,
				children: [
					{
						path: ":id",
						name: RouteNames.COUNTERPARTY,
						redirect: { name: RouteNames.COUNTERPARTY_GENERAL },
						component: RmpCounterparty,
						children: [
							{
								meta: { key: "counterparty-update" },
								path: "",
								name: RouteNames.COUNTERPARTY_GENERAL,
								component: RmpCounterpartyGeneral
							},
							{
								meta: { key: "counterparty-update" },
								path: "employees",
								name: RouteNames.COUNTERPARTY_EMPLOYEES,
								component: RmpCounterpartyEmployees
							},
							{
								meta: { key: "counterparty-update" },
								path: "master-account",
								name: RouteNames.COUNTERPARTY_MASTER_ACCOUNT,
								component: RmpCounterpartyMasterAccount
							},
							{
								meta: { key: "counterparty-update" },
								path: "bank-accounts",
								name: RouteNames.COUNTERPARTY_BANK_ACCOUNTS,
								component: RmpCounterpartyBankAccounts
							},
							{
								meta: { key: "counterparty-update" },
								path: "documents",
								name: RouteNames.COUNTERPARTY_DOCUMENTS,
								component: RmpCounterpartyDocuments
							}
						]
					}
				]
			},
			{
				path: "counterparty/:counterpartyId/bank-account",
				name: RouteNames.COUNTERPARTY_BANK_ACCOUNT_CREATE,
				component: RmpCounterpartyBankAccount
			},
			{
				path: "counterparty/:counterpartyId/bank-account/:accountId",
				name: RouteNames.COUNTERPARTY_BANK_ACCOUNT,
				component: RmpCounterpartyBankAccount
			},
			{
				path: "counterparty/:counterpartyId/employee",
				component: RmpCounterpartyEmployee,
				redirect: { name: RouteNames.COUNTERPARTY_EMPLOYEE_CREATE },
				children: [
					{
						path: "",
						name: RouteNames.COUNTERPARTY_EMPLOYEE_CREATE,
						component: RmpCounterpartyEmployeeGeneral
					}
				]
			},
			{
				path: "counterparty/:counterpartyId/employee/:employeeId",
				component: RmpCounterpartyEmployee,
				name: RouteNames.COUNTERPARTY_EMPLOYEE,
				redirect: { name: RouteNames.COUNTERPARTY_EMPLOYEE_GENERAL },
				children: [
					{
						meta: { key: "counterparty-employee-update" },
						path: "",
						name: RouteNames.COUNTERPARTY_EMPLOYEE_GENERAL,
						component: RmpCounterpartyEmployeeGeneral
					},
					{
						meta: { key: "counterparty-employee-update" },
						path: "trusts",
						name: RouteNames.COUNTERPARTY_EMPLOYEE_TRUSTS,
						component: RmpCounterpartyEmployeeTrusts
					},
					{
						meta: { key: "counterparty-employee-update" },
						path: "profile",
						name: RouteNames.COUNTERPARTY_EMPLOYEE_PROFILE,
						component: RmpCounterpartyEmployeeProfile
					},
					{
						meta: { key: "counterparty-employee-update" },
						path: "trust",
						redirect: { name: RouteNames.COUNTERPARTY_EMPLOYEE_TRUST },
						component: RmpRouterView,
						children: [
							{
								path: "",
								meta: {
									key: "counterparty-employee-update"
								},
								name: RouteNames.COUNTERPARTY_EMPLOYEE_TRUST_CREATE,
								component: RmpCounterpartyEmployeeTrust
							},
							{
								path: ":id",
								meta: {
									key: "counterparty-employee-update"
								},
								name: RouteNames.COUNTERPARTY_EMPLOYEE_TRUST,
								component: RmpCounterpartyEmployeeTrust
							}
						]
					}
				]
			},
			{
				path: "accounts",
				name: RouteNames.ACCOUNTS,
				component: RmpAccounts
			},
			{
				path: "account",
				component: RmpRouterView,
				children: [
					{
						path: ":id",
						name: RouteNames.ACCOUNT,
						redirect: { name: RouteNames.ACCOUNT_GENERAL },
						component: RmpAccount,
						children: [
							{
								path: "",
								meta: { key: "account-update" },
								name: RouteNames.ACCOUNT_GENERAL,
								component: RmpAccountGeneral
							},
							{
								path: "profiles",
								meta: { key: "account-update" },
								name: RouteNames.ACCOUNT_PROFILES,
								component: RmpAccountProfiles
							}
						]
					}
				]
			}
		]
	},
	{
		path: "/",
		component: RmpEmptyLayout,
		children: [
			{
				path: "",
				component: RmpOuterLayout,
				children: [
					{
						path: "confirm-email",
						component: RmpConfirmEmail,
						name: RouteNames.CONFIRM_EMAIL
					},
					{
						path: "confirm-phone",
						component: RmpConfirmPhone,
						name: RouteNames.CONFIRM_PHONE
					},
					{
						path: "reset-password",
						component: RmpResetPassword,
						name: RouteNames.RESET_PASSWORD
					}
				]
			}
		]
	},
	{
		path: "/",
		component: RmpEmptyLayout,
		meta: { public: true },
		children: [
			{
				path: "internal-server-error",
				component: RmpInternalServerError,
				name: RouteNames.ERROR_INTERNAL_SERVER_ERROR
			},
			{
				path: "access-denied",
				component: RmpAccessDeniedError,
				name: RouteNames.ERROR_ACCESS_DENIED
			},
			{
				path: "*",
				component: RmpPageNotFound,
				name: RouteNames.ERROR_PAGE_NOT_FOUND
			}
		]
	}
];

const router = new VueRouter({
	routes,
	mode: "history",
	stringifyQuery: query => {
		// @ts-ignore
		let result = stringifyQuery(query);
		return result ? ("?" + result) : "";
	}
});

configureMiddleware(router);

export default router;
