import { IMiddleware } from "@rmp/core/types/core/middleware";
import { NavigationGuardNext, Route } from "vue-router/types/router";
import { RouteNames } from "@rmp/organization/router/routes";
import routeToPermissionsMap from "@rmp/organization/router/routeToPermissionsMap";
import PermissionsService from "@rmp/core/services/permissionsService";
import { useUserStore } from "@rmp/core/stores/user";

const permissionsService = new PermissionsService();

export default class PermissionsMiddleware implements IMiddleware {
	constructor() {

	}

	async invoke(to: Route, from: Route) {
		let routeName = to.name as RouteNames;
		
		const userStore = useUserStore();
		
		if(!userStore.initialized) {
			await userStore.initialize();
		}

		if(routeName) {
			let permissions = routeToPermissionsMap.get(routeName);

			if(permissions && permissions.length) {
				if(!userStore.check(permissions)) {
					return { name: RouteNames.ERROR_ACCESS_DENIED };
				}
			}
		}
	}
}
