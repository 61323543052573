import { difference } from "@rmp/core/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import RouteQueryService from "@rmp/core/services/route/routeQueryService";
import { parseArrayParameter } from "@rmp/core/utils/query";
import OfficeEmployeesRouteQuery from "@rmp/organization/stores/office/employees/types/officeEmployeesRouteQuery";
import { OfficeEmployeesState } from "@rmp/organization/stores/office/employees";

export default class OfficeEmployeesRouteQueryService implements RouteQueryService {
	defaultRouteQuery: OfficeEmployeesRouteQuery;
	
	constructor(defaultRouteQuery: OfficeEmployeesRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}
	
	private mapStateToQuery(state: OfficeEmployeesState) {
		return new OfficeEmployeesRouteQuery(
			state.paging.page,
			state.sorting.type,
			state.sorting.order,
			state.search.query,
			state.filter.roles
		);
	}
	
	public resolveRouteQueryDictionary(state: OfficeEmployeesState) {
		let query = this.mapStateToQuery(state);
		
		return difference(this.defaultRouteQuery, query);
	}
	
	public async hasRouteChanges(state: OfficeEmployeesState, route: Dictionary<any>): Promise<{ [key: string]: any }> {
		let stateQuery = this.mapStateToQuery(state);
		let routeQuery = await this.resolveRouteQuery(route);
		
		return difference(routeQuery, stateQuery);
	}
	
	public async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(OfficeEmployeesRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				filterRoles: parseArrayParameter(query.filterRoles)
			}, { enableImplicitConversion: true });
			
			await validateOrReject(result);
			
			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
