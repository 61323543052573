import { CounterpartyBankAccount } from "@rmp/core/types/counterparty/counterpartyBankAccount";
import { ApiPersistedBase } from "@rmp/core/api/types/base/apiPersistedBase";

export interface ApiCounterpartyBankAccount {
	bankAccountNumber: string;
	bankIdentificationCode: string;
	bankName: string;
	correspondentAccount: string;
}

export class ApiCounterpartyBankAccountMapper {
	static map(source: CounterpartyBankAccount): ApiCounterpartyBankAccount {
		return {
			...source
		};
	}
	
	static getEmpty(): ApiCounterpartyBankAccount {
		return {
			bankAccountNumber: "",
			bankIdentificationCode: "",
			bankName: "",
			correspondentAccount: ""
		};
	}
}

export interface ApiCounterpartyBankAccountPersisted extends ApiPersistedBase {
	counterpartyId: string;
	bankAccount: ApiCounterpartyBankAccount;
}

export class ApiCounterpartyBankAccountPersistedMapper {
	static getEmpty(): ApiCounterpartyBankAccountPersisted {
		return {
			id: "",
			counterpartyId: "",
			createdAt: "",
			updatedAt: "",
			bankAccount: ApiCounterpartyBankAccountMapper.getEmpty()
		};
	}
}
