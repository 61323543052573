import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { RouteNames } from "@rmp/organization/router/routes";
import router from "@rmp/organization/router";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { CounterpartyBankAccountController } from "@rmp/organization/api/counterpartyBankAccount";
import {
	useCounterpartyBankAccountsBreadcrumb
} from "@rmp/organization/stores/counterparty/bankAccounts/composables/useCounterpartyBankAccountsBreadcrumb";
import CounterpartyBankAccountsRouteQuery
	from "@rmp/organization/stores/counterparty/bankAccounts/types/counterpartyBankAccountsRouteQuery";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import CounterpartyBankAccountsRouteQueryService
	from "@rmp/organization/stores/counterparty/bankAccounts/services/counterpartyBankAccountsRouteQueryService";
import { useCounterpartyStore } from "@rmp/organization/stores/counterparty";
import { ApiCounterpartyBankAccountPersisted } from "@rmp/core/api/types/counterpartyBankAccount/apiCounterpartyBankAccount";

const abortService = new AbortService();
const bankAccountController = new CounterpartyBankAccountController(abortService);

export interface CounterpartyBankAccountsState extends PageState, RegisterState<ApiCounterpartyBankAccountPersisted>
{
	id: string;
}

const defaultRouteQuery = new CounterpartyBankAccountsRouteQuery(
	1,
	"bankAccountNumber",
	SortingOrderTypeEnum.ASCENDING,
	""
);
const routeQueryService = new CounterpartyBankAccountsRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);

const register = useRegisterStore<ApiCounterpartyBankAccountPersisted>({
	routeName: RouteNames.COUNTERPARTY_BANK_ACCOUNTS,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): CounterpartyBankAccountsState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		id: ""
	};
};

export const useCounterpartyBankAccountsStore = defineStore({
	id: "counterparty-bank-accounts",
	state: (): CounterpartyBankAccountsState => getDefaultState(),
	getters: {
		...page.getters,
		...register.getters,
		breadcrumbs(state: CounterpartyBankAccountsState) {
			const { breadcrumbs } = useCounterpartyStore();
			
			return [
				...breadcrumbs,
				useCounterpartyBankAccountsBreadcrumb({ id: state.id })
			];
		},
		formattedItems(state: CounterpartyBankAccountsState) {
			return state.listing.items.map(x => {
				return {
					id: x.id,
					bankAccountNumber: x.bankAccount.bankAccountNumber,
					bankIdentificationCode: x.bankAccount.bankIdentificationCode,
					bankName: x.bankAccount.bankName,
					correspondentAccount: x.bankAccount.correspondentAccount
				};
			});
		},
		filteredItems() {
			return this.formattedItems;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized() {
			await this.initializeRegister();
			
			this.id = router.currentRoute.params.id;
		},
		async fetchRegisterItems() {
			let items = await bankAccountController.getAccounts(router.currentRoute.params.id);
			return { items };
		}
	}
});
