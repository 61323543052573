import { defineStore, Store, SubscriptionCallback } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { RouteNames } from "@rmp/organization/router/routes";
import router from "@rmp/organization/router";
import OfficesMapperProfile from "@rmp/organization/stores/offices/mapper";
import SearchSuggestion from "@rmp/core/services/searchSuggestion/searchSuggestion";
import { convertIsoToNumber, formatDate } from "@rmp/core/utils/dates";
import { dateTimeFormat } from "@rmp/core/utils/formats";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { get } from "local-storage";
import { LAST_VISITED_OFFICES } from "@rmp/core/constants/localStorage";
import { OfficesItem } from "@rmp/organization/stores/offices/types/officesItem";
import { OfficeController } from "@rmp/organization/api/office";
import { ApiOfficePersisted } from "@rmp/core/api/types/office/apiOffice";
import { z } from "zod";
import { AVAILABLE_SORTS } from "@rmp/organization/stores/offices/constants";
import { useRouteQueryService } from "@rmp/core/composables/useRouteQueryService";

const abortService = new AbortService();
const officeController = new OfficeController(abortService);

const mapper = new OfficesMapperProfile();

export interface OfficesState extends PageState, RegisterState<ApiOfficePersisted>
{
	searchSuggestions: SearchSuggestion[];
}

const schema = z.object({
	sort: z.nativeEnum(AVAILABLE_SORTS).default(AVAILABLE_SORTS.CreatedAt)
});

const { defaultRouteQuery, ...routeQueryService } = useRouteQueryService(schema.shape);


const page = usePageStore(abortService);

const register = useRegisterStore<ApiOfficePersisted>({
	routeName: RouteNames.OFFICES,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router,
	itemsKey: "offices"
});

const getDefaultState = (): OfficesState => {
	return {
		...register.getDefaultRegisterState(),
		...page.getDefaultPageState(),
		searchSuggestions: []
	};
};

export const useOfficesStore = defineStore({
	id: "offices",
	state: (): OfficesState => getDefaultState(),
	getters: {
		...page.getters,
		...register.getters,
		formattedItems(state: OfficesState): OfficesItem[] {
			return state.listing.items.map(x => {
				return {
					...x,
					...x.office,
					createdAt: formatDate(convertIsoToNumber(x.createdAt), dateTimeFormat)
				} as OfficesItem;
			});
		},
		filteredItems(): OfficesItem[] {
			return this.formattedItems;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized() {
			await this.initializeRegister();
			
			this.searchSuggestions = get(LAST_VISITED_OFFICES) || [];
		},
		patchStateWithRouteQuery(query: typeof defaultRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
		},
		async fetchRegisterItems() {
			return await officeController.getOffices(mapper.mapToApiGetOfficesParameters(this));
		}
	}
});
