import BaseController from "@rmp/core/api/shared/baseController";
import AbortService from "@rmp/core/services/abortService";
import urls from "@rmp/core/api/config/urls";
import { ApiApp } from "@rmp/core/api/types/app/apiApp";

export class AppsController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getApps = async (): Promise<ApiApp[]> => {
		return await this.client.get<ApiApp[]>(urls.apps.getApps);
	};
}
