import { urls } from "@rmp/core/api/config/organizationUrls";
import ApiGetOfficesParameters from "@rmp/core/api/types/office/apiGetOfficesParameters";
import ApiOffices from "@rmp/core/api/types/office/apiOffices";
import { ApiOfficeEmployeeRole } from "@rmp/core/api/types/roles/apiOfficeEmployeeRole";
import ApiGetOfficeEmployeesParameters from "@rmp/core/api/types/office/apiGetOfficeEmployeesParameters";
import ApiOfficeEmployees from "@rmp/core/api/types/office/apiOfficeEmployees";
import AbortService from "@rmp/core/services/abortService";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";
import ApiAccountProfilePersistedBase from "@rmp/core/api/types/account/apiAccountProfilePersistedBase";
import BaseOrganizationController from "@rmp/core/api/base/baseOrganiztionController";
import { prepareUrl } from "@rmp/core/utils/prepareUrlQuery";
import tempUrls, { urlTemplateParts } from "@rmp/core/api/config/urls";
import { ApiOfficeEmployee, ApiOfficeEmployeePersisted } from "@rmp/core/api/types/office/apiOfficeEmployee";
import { ApiOffice, ApiOfficePersisted } from "@rmp/core/api/types/office/apiOffice";

export class OfficeController extends BaseOrganizationController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getOffices = async (parameters: ApiGetOfficesParameters) => {
		return await this.get<ApiOffices>(urls.staff.office, parameters);
	};
	
	getOffice = async (officeId: string) => {
		let { officePersisted } =
			await this.get<{ officePersisted: ApiOfficePersisted }>(urls.staff.office.$officeId, { officeId });
		return officePersisted;
	};
	
	getOfficeByIdentifiers = async (inn: string, ogrn: string, kpp: string) => {
		let { officePersisted } = await this.get<{ officePersisted: ApiOfficePersisted }>(urls.staff.office.search, {
			inn, ogrn, kpp
		});
		return officePersisted;
	};
	updateOffice = async (officeId: string, office: ApiOffice) => {
		return await this.put<ApiOffice>(urls.staff.office.$officeId, { officeId }, office);
	};
	
	createOffice = async (office: ApiOffice) => {
		return await this.post<{ id: string }>(urls.staff.office, {}, office);
	};
	
	getOfficeEmployees = async (officeId: string, parameters: ApiGetOfficeEmployeesParameters) => {
		return await this.get<ApiOfficeEmployees>(urls.staff.office.$officeId.employee, { officeId, ...parameters });
	};
	
	getOfficeEmployee = async (id: string, officeId: string): Promise<ApiOfficeEmployeePersisted> => {
		const url = tempUrls.office.employee.get.replace(urlTemplateParts.id, officeId).replace(urlTemplateParts.subId, id);
		
		let { employeePersisted } = await this.client.get<{ employeePersisted: ApiOfficeEmployeePersisted }>(url);
		
		if(employeePersisted === null)
			throw new HttpNotFoundException(url);
		
		return employeePersisted;
	};
	
	getOfficeEmployeeById = async (id: string): Promise<ApiOfficeEmployeePersisted> => {
		const url = tempUrls.office.employee.getById.replace(urlTemplateParts.id, id);
		
		let { employeePersisted } = await this.client.get<{ employeePersisted: ApiOfficeEmployeePersisted }>(url, {});
		
		return employeePersisted;
	};
	
	updateOfficeEmployee = async (id: string, officeId: string, payload: ApiOfficeEmployee) => {
		return await this.client.put<void>(tempUrls.office.employee.update.replace(urlTemplateParts.id, officeId)
												   .replace(urlTemplateParts.subId, id), payload);
	};
	
	createOfficeEmployee = async (officeId: string, payload: ApiOfficeEmployee, assignToAccount: boolean = true) => {
		const url = prepareUrl(tempUrls.office.employee.create.replace(urlTemplateParts.id, officeId),
			{ assignToAccount });
		return await this.client.post<string>(url, payload);
	};
	
	getOfficeEmployeeRoles = async () => {
		let { roles } = await this.client.get<{ roles: ApiOfficeEmployeeRole[] }>(prepareUrl(tempUrls.office.getRoles));
		return roles;
	};
	
	getOfficeEmployeeProfile = async (officeId: string, id: string) => {
		const url = tempUrls.office.employee.getEmployeeProfile
							.replace(urlTemplateParts.id, officeId)
							.replace(urlTemplateParts.subId, id);
		
		return  await this.client.get<ApiAccountProfilePersistedBase>(url);
	};
	
	activateOfficeEmployee = async (accountId: string, profileId: string) => {
		return await this.client.post(tempUrls.office.employee.activate
											  .replace(urlTemplateParts.id, accountId)
											  .replace(urlTemplateParts.subId, profileId), {});
	};
	
	deactivateOfficeEmployee = async (accountId: string, profileId: string) => {
		return await this.client.post(tempUrls.office.employee.deactivate
											  .replace(urlTemplateParts.id, accountId)
											  .replace(urlTemplateParts.subId, profileId), {});
	};
	
}
