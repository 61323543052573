import { ApiOfficeEmployee } from "@rmp/core/api/types/office/apiOfficeEmployee";
import { ApiOfficeEmployeeRole } from "@rmp/core/api/types/roles/apiOfficeEmployeeRole";

export interface OfficeEmployee {
	position: string;
	snils: string;
	firstName: string;
	middleName: string;
	lastName: string;
	description: string;
	email: string;
	phone: string;
	isActive: boolean;
	roles: ApiOfficeEmployeeRole[];
}

export class OfficeEmployeeMapper {
	static map(source: ApiOfficeEmployee): OfficeEmployee {
		return {
			...source
		}
	}
	static getEmpty(): OfficeEmployee {
		return {
			position: "",
			snils: "",
			firstName: "",
			middleName: "",
			lastName: "",
			email: "",
			description: "",
			phone: "",
			isActive: true,
			roles: []
		};
	}
}
