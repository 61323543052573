import "reflect-metadata";
import Vue, { markRaw } from "vue";
import App from "@rmp/organization/App.vue";
import router from "@rmp/organization/router";
import { i18n, vuetify } from "@rmp/organization/plugins";
import "@rmp/core/assets/main.css";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import configureApi from "@rmp/organization/api/index";
import PermissionsResolver from "@rmp/core/api/authorization/permissionsResolver";
import { createPinia, PiniaVuePlugin } from "pinia";
import { createHead, HeadVuePlugin } from "@vueuse/head";

const head = createHead();
Vue.use(HeadVuePlugin, head);
Vue.use(head);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

pinia.use(({ store }) => {
	store.router = markRaw(router);
});

const permissionsResolver = new PermissionsResolver();
permissionsResolver.resetPermissionsStorage();

Vue.use(VueTelInputVuetify, {
	vuetify
});

configureApi();

Vue.config.productionTip = false;

new Vue({
	i18n,
	router,
	vuetify,
	pinia,
	render: (h) => h(App)
}).$mount("#app");
