import ApiCounterpartyEmployeeTrustCreateRequest from "@rmp/core/api/types/counterparty/trust/apiCounterpartyEmployeeTrustCreateRequest";
import { convertNumberToIsoString } from "@rmp/core/utils/formatting";
import ApiCounterpartyEmployeeTrust from "@rmp/core/api/types/counterparty/trust/apiCounterpartyEmployeeTrust";
import { convertIsoToNumber } from "@rmp/core/utils/dates";
import { CounterpartyEmployeeTrustState } from "@rmp/organization/stores/counterpartyEmployee/trust/index";
import { CounterpartyEmployeeTrust } from "@rmp/core/types/counterpartyEmployee/trust/counterpartyEmployeeTrust";
import { CounterpartyEmployeeState } from "@rmp/organization/stores/counterpartyEmployee";

export default class CounterpartyEmployeeTrustMapperProfile {
	constructor() {
	}
	
	mapToApiCounterpartyEmployeeTrustCreateRequest(
		employeeSource: CounterpartyEmployeeState,
		employeeTrustSource: CounterpartyEmployeeTrustState
	): ApiCounterpartyEmployeeTrustCreateRequest {
		return {
			counterpartyId: employeeSource.counterpartyId,
			trusteeEmployeeId: employeeSource.id,
			trusteeSnils: employeeSource.employee.snils,
			expireAt: convertNumberToIsoString(employeeTrustSource.trust.expireAt),
			tempFileId: employeeTrustSource.trustFileMeta.id
		};
	}
}
