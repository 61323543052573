import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { formatCounterpartyTitle, formatFullName } from "@rmp/core/utils/formatting";
import { ApiCounterpartyType } from "@rmp/core/api/types/counterparty/apiCounterpartyType";
import ApiCounterpartyBase from "@rmp/core/api/types/counterparty/apiCounterpartyBase";
import ApiAccountProfilePersistedBase from "@rmp/core/api/types/account/apiAccountProfilePersistedBase";
import { Permissions } from "@rmp/core/constants/permissions";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";
import ApiCounterpartyPersistedBase from "@rmp/core/api/types/counterparty/apiCounterpartyPersistedBase";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";
import AccessForbiddenException from "@rmp/core/exceptions/accessForbiddenException";
import PermissionsService from "@rmp/core/services/permissionsService";
import { CounterpartyController } from "@rmp/core/api/counterparty";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { CounterpartyEmployee, CounterpartyEmployeeMapper } from "@rmp/core/types/counterpartyEmployee/counterpartyEmployee";
import { useCounterpartiesBreadcrumb } from "@rmp/organization/stores/counterpartyEmployee/composables/useCounterpartiesBreadcrumb";
import { useCounterpartyBreadcrumb } from "@rmp/organization/stores/counterparty/composables/useCounterpartyBreadcrumb";
import {
	useCounterpartyEmployeesBreadcrumb
} from "@rmp/organization/stores/counterparty/employees/composables/useCounterpartyEmployeesBreadcrumb";

const abortService = new AbortService();
const counterpartyController = new CounterpartyController(abortService);
const permissionsService = new PermissionsService();

const page = usePageStore(abortService);

export interface CounterpartyEmployeeState extends PageState {
	id: string;
	counterpartyId: string;
	counterparty: ApiCounterpartyType;
	employee: CounterpartyEmployee;
	profile: ApiAccountProfilePersistedBase | null;
}

const getDefaultState = (): CounterpartyEmployeeState => {
	return {
		...page.getDefaultPageState(),
		id: "",
		counterpartyId: "",
		counterparty: new ApiCounterpartyBase(),
		employee: CounterpartyEmployeeMapper.getEmpty(),
		profile: null
	};
};

export const useCounterpartyEmployeeStore = defineStore({
	id: "counterparty-employee",
	state: (): CounterpartyEmployeeState => getDefaultState(),
	getters: {
		...page.getters,
		counterpartyTitle(state: CounterpartyEmployeeState) {
			return formatCounterpartyTitle(state.counterparty);
		},
		breadcrumbs(state: CounterpartyEmployeeState) {
			return [
				useCounterpartiesBreadcrumb(),
				useCounterpartyBreadcrumb(formatCounterpartyTitle(state.counterparty), { id: state.counterpartyId }),
				useCounterpartyEmployeesBreadcrumb({ id: state.counterpartyId }),
			];
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized({ id, counterpartyId }: { id: string | null, counterpartyId: string }) {
			this.counterpartyId = counterpartyId;
			
			await this.fetch({ id, counterpartyId });
		},
		async fetch({ id, counterpartyId }: { id: string | null, counterpartyId: string }) {
			try {
				if(id) {
					this.id = id;
					
					const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_READ])
						? AuthorizationScopeType.GLOBAL
						: AuthorizationScopeType.OWN;
					
					let employeePersisted = await counterpartyController.getCounterpartyEmployee(id, counterpartyId, scope);
					
					this.employee = CounterpartyEmployeeMapper.map(employeePersisted.employee);
					this.employee.isActive = employeePersisted.employee.isActive;
					
					const hasEmployeeReedPermission = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_PROFILE_READ]);
					const hasOwnEmployeeReedPermission = await permissionsService.check([Permissions.OWN_COUNTERPARTY_EMPLOYEE_PROFILE_READ]);
					
					if(hasEmployeeReedPermission || hasOwnEmployeeReedPermission) {
						const employeeProfileScope = hasEmployeeReedPermission
							? AuthorizationScopeType.GLOBAL
							: AuthorizationScopeType.OWN;
						
						this.profile =
							await counterpartyController.getCounterpartyEmployeeProfile(counterpartyId, id, employeeProfileScope);
					}
				}
				
				const hasCounterpartyReadPermission = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_READ]);
				const hasOwnCounterpartyReadPermission = await permissionsService.check([Permissions.OWN_COUNTERPARTY_READ]);
				
				if(hasCounterpartyReadPermission || hasOwnCounterpartyReadPermission) {
					let { counterparty } = await counterpartyController.getCounterparty<ApiCounterpartyPersistedBase>(counterpartyId,
						ApiCounterpartyPersistedBase,
						hasCounterpartyReadPermission ? AuthorizationScopeType.GLOBAL : AuthorizationScopeType.OWN);
					
					this.counterparty = counterparty;
				}
			} catch (error) {
				const { setPageModeNotFound, setPageModeAccessForbidden } = useAppStore();
				
				switch (error.constructor) {
					case HttpNotFoundException:
						setPageModeNotFound();
						break;
					case AccessForbiddenException:
						setPageModeAccessForbidden();
						break;
					default:
						AlertHelper.handleGeneralRequestErrors(error);
						break;
				}
			}
		},
		async activateEmployee() {
			this.employee.statusSaving = true;
			
			try {
				const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_UPDATE])
					? AuthorizationScopeType.GLOBAL
					: AuthorizationScopeType.OWN;
				
				await counterpartyController.activateCounterpartyEmployee(this.counterpartyId, this.id, scope);
				
				this.employee.isActive = true;
				alertService.addSuccess(AlertKeys.PROFILE_ACTIVATED);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.employee.statusSaving = false;
			}
		},
		async deactivateEmployee() {
			this.employee.statusSaving = true;
			
			try {
				const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_UPDATE])
					? AuthorizationScopeType.GLOBAL
					: AuthorizationScopeType.OWN;
				
				await counterpartyController.deactivateCounterpartyEmployee(this.counterpartyId, this.id, scope);
				
				this.employee.isActive = false;
				
				alertService.addSuccess(AlertKeys.PROFILE_DEACTIVATED);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.employee.statusSaving = false;
			}
		},
		assignEmployee(employee: Partial<CounterpartyEmployee>) {
			Object.assign(this.employee, employee)
		}
	}
});
