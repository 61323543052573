import { MasterAccountAccessStatusFilterEnum } from "@rmp/core/types/masterAccountAccess/MasterAccountAccessStatusFilter";

export enum AVAILABLE_SORTS {
	Title = "title",
	Inn = "inn",
	Heads = "heads",
	Status = "status",
	CreatedAt = "createdAt"
}

export const AVAILABLE_FILTER_STATUS = [
	MasterAccountAccessStatusFilterEnum.CREATED,
	MasterAccountAccessStatusFilterEnum.APPROVED,
	MasterAccountAccessStatusFilterEnum.REJECTED,
	MasterAccountAccessStatusFilterEnum.EMPTY
];
