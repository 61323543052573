import { defineStore, Store, SubscriptionCallback } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { RouteNames } from "@rmp/organization/router/routes";
import router from "@rmp/organization/router";
import { formatDate } from "@rmp/core/utils/dates";
import { dateTimeFormat } from "@rmp/core/utils/formats";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import MasterAccountAccessApplicationsFilter
	from "@rmp/organization/stores/masterAccountAccessApplications/types/masterAccountAccessApplicationsFilter";
import MasterAccountAccessApplicationsMapperProfile from "@rmp/organization/stores/masterAccountAccessApplications/mapper";
import ApiMasterAccountAccessApplication from "@rmp/core/api/types/masterAccountAccessApplication/apiMasterAccountAccessApplication";
import { MasterAccountAccessApplicationController } from "@rmp/core/api/masterAccountAccessApplication";
import { MasterAccountAccessApplicationsItem } from "@rmp/organization/stores/masterAccountAccessApplications/types/masterAccountAccessApplicationsItem";
import { ApiLegalEntityCounterpartyHead } from "@rmp/core/api/types/counterparty/apiLegalEntityCounterpartyHead";
import { CounterpartyTypeEnum } from "@rmp/core/types/CounterpartyTypeEnum";
import { MasterAccountAccessStatusFilterEnum } from "@rmp/core/types/masterAccountAccess/MasterAccountAccessStatusFilter";
import { z } from "zod";
import { AVAILABLE_SORTS } from "@rmp/organization/stores/masterAccountAccessApplications/constants";
import { useRouteQueryService } from "@rmp/core/composables/useRouteQueryService";
import { parseArrayParameter } from "@rmp/core/utils/query";

const abortService = new AbortService();
const masterAccountAccessApplicationController = new MasterAccountAccessApplicationController(abortService);

const mapper = new MasterAccountAccessApplicationsMapperProfile();

export interface MasterAccountAccessApplicationsState extends PageState,
	RegisterState<ApiMasterAccountAccessApplication, MasterAccountAccessApplicationsFilter>
{
	filter: MasterAccountAccessApplicationsFilter;
	isInitialized: boolean;
}

const schema = z.object({
	sort: z.nativeEnum(AVAILABLE_SORTS).default(AVAILABLE_SORTS.CreatedAt),
	statuses: z.array(z.nativeEnum(MasterAccountAccessStatusFilterEnum)).default([])
});

const { defaultRouteQuery, ...routeQueryService } = useRouteQueryService(schema.shape, {
	parseFilterParameters(state) {
		return {
			...state,
			statuses: parseArrayParameter(state.statuses)
		}
	},
});


const page = usePageStore(abortService);

const register = useRegisterStore<ApiMasterAccountAccessApplication, MasterAccountAccessApplicationsFilter>({
	routeName: RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESSES,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router,
	itemsKey: "requests"
});

const getDefaultState = (): MasterAccountAccessApplicationsState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		filter: {
			statuses: [MasterAccountAccessStatusFilterEnum.CREATED]
		} as MasterAccountAccessApplicationsFilter,
		isInitialized: false,
	};
};

export const useMasterAccountAccessApplicationsStore = defineStore({
	id: "master-account-access-applications",
	state: (): MasterAccountAccessApplicationsState => getDefaultState(),
	getters: {
		...page.getters,
		...register.getters,
		formattedItems(state: MasterAccountAccessApplicationsState): MasterAccountAccessApplicationsItem[] {
			return state.listing.items.map(x => {
				let item = {
					...x,
					heads: [] as ApiLegalEntityCounterpartyHead[],
					inn: "",
					title: "",
					createdAt: formatDate(new Date(x.createdAt), dateTimeFormat)
				};
				
				if(x.type === CounterpartyTypeEnum.LEGAL_ENTITY) {
					item.heads = x.legalEntity.heads;
					item.inn = x.legalEntity.inn;
					item.title = x.legalEntity.shortName;
				}
				if(x.type === CounterpartyTypeEnum.LEGAL_PERSON) {
					item.inn = x.entrepreneur.inn;
					item.title = x.entrepreneur.fullName;
				}
				
				return item as MasterAccountAccessApplicationsItem;
			});
		},
		filteredItems(): MasterAccountAccessApplicationsItem[] {
			return this.formattedItems;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized() {
			await this.initializeRegister();
		},
		patchStateWithRouteQuery(query: typeof defaultRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			if (this.isInitialized || query.statuses.length >= 1) {
				this.$patch((state) => {
					state.filter.statuses = query.statuses;
				});
			}
			this.isInitialized = true;
		},
		async fetchRegisterItems() {
			return await masterAccountAccessApplicationController.getMasterAccountAccessApplications(mapper.mapToApiGetMasterAccountAccessApplicationsParameters(
				this));
		}
	}
});

