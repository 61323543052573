import { RouteNames } from "@rmp/organization/router/routes";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import routeToPermissionsMap from "@rmp/organization/router/routeToPermissionsMap";

export function useMasterAccountAccessApplicationBreadcrumb(text: string) {
	return useBreadcrumb({
		route: {
			name: RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESS_DETAILS
		},
		text,
		routeToPermissionsMap
	});
}
