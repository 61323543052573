import { render, staticRenderFns } from "./RmpCounterpartyEmployeeTrust.vue?vue&type=template&id=5fff8303&scoped=true&"
import script from "./RmpCounterpartyEmployeeTrust.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RmpCounterpartyEmployeeTrust.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./RmpCounterpartyEmployeeTrust.vue?vue&type=style&index=0&id=5fff8303&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fff8303",
  null
  
)

export default component.exports