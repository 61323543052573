import ApiLegalPersonCounterparty from "@rmp/core/api/types/counterparty/apiLegalPersonCounterparty";
import ApiLegalEntityCounterparty from "@rmp/core/api/types/counterparty/apiLegalEntityCounterparty";
import { IsOptional } from "class-validator";
import { Type } from "class-transformer";

export default class MasterAccountAccessApplication {
	id: string;
	createdAt: number;
	updatedAt: number;
	requestorEmail: string;
	documentIds: string[];
	status: boolean;
	type: string;
	rejectReason: string;
	entrepreneur: ApiLegalPersonCounterparty;
	legalEntity: ApiLegalEntityCounterparty;

	constructor(
		id: string = "",
		createdAt: number = 0,
		updatedAt: number = 0,
		requestorEmail: string = "",
		documentIds: string[] = [],
		status: boolean = false,
		type: string = "",
		rejectReason: string = "",
		entrepreneur: ApiLegalPersonCounterparty = new ApiLegalPersonCounterparty(),
		legalEntity: ApiLegalEntityCounterparty = new ApiLegalEntityCounterparty()
	)
	{
		this.id = id;
		this.createdAt = createdAt;
		this.updatedAt = updatedAt;
		this.requestorEmail = requestorEmail;
		this.documentIds = documentIds;
		this.status = status;
		this.type = type;
		this.rejectReason = rejectReason;
		this.entrepreneur = entrepreneur;
		this.legalEntity = legalEntity;
	}
}
