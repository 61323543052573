import { Store, storeToRefs } from "pinia";
import { nextTick, ref, watch } from "vue";
import {
	EmbeddedFormActions,
	EmbeddedFormGetters,
	EmbeddedFormState,
	EmbeddedFormStore
} from "@rmp/core/stores/composables/embeddedForm/useEmbeddedFormStore";
import { SnapshotActions, SnapshotGetters, SnapshotState } from "@rmp/core/stores/composables/snapshot";

export type EmbeddedFormSnapshotStore = Store<string, EmbeddedFormState & SnapshotState, EmbeddedFormGetters & SnapshotGetters, EmbeddedFormActions & SnapshotActions>;

export interface EmbeddedFormOptions {
	useStore: () => EmbeddedFormStore;
}

export function useEmbeddedForm(options: EmbeddedFormOptions, isResetValidationDisabled: boolean = false) {
	const store = options.useStore() as EmbeddedFormSnapshotStore;
	
	const form = ref<any>(null);
	
	const {
		formStateLoading,
		formReadonly,
		formSaving,
		formValid,
		formDisabled,
		formInitialized,
		formEditMode,
		formMode,
		stateContainsChangesSinceLastSaved
	} = storeToRefs(store);
	
	const validateForm = () => {
		return form.value.validate();
	};
	
	const onFormCancel = async () => {
		await cancelFormChanges();
		await nextTick(() => {
			if(!isResetValidationDisabled)
				form.value.resetValidation();
		});
	};
	
	const cancelFormChanges = async () => {
		await store.cancelChanges();
	};
	
	const submit = async () => {
		if(!validateForm())
			return;
		
		try {
			await store.save();
		} catch (e) {
			console.error(e);
			// Обработка производится на уровне store
		}
	};
	
	watch(formValid, (value) => {
		formValid.value = value;
	}, { immediate: true });
	
	return {
		form,
		formStateLoading,
		formReadonly,
		formSaving,
		formValid,
		formDisabled,
		formInitialized,
		formEditMode,
		formMode,
		stateContainsChangesSinceLastSaved,
        validateForm,
        onFormCancel,
        cancelFormChanges,
		submit
	};
}
