import { ApiLegalEntity } from "@rmp/core/api/types/legalEntity/apiLegalEntity";
import { ApiOffice } from "@rmp/core/api/types/office/apiOffice";

export interface Office {
	inn: string;
	ogrn: string;
	kpp: string;
	shortName: string;
	longName: string;
	description: string;
}

export default class OfficeMapperProfile {
	constructor() {
	}
	
	static map(source: ApiOffice): Office {
		return {
			inn: source.inn,
			ogrn: source.ogrn,
			kpp: source.kpp,
			shortName: source.shortName,
			longName: source.longName,
			description: source.description
		};
	}
	
	static mapFromApiLegalEntity(source: ApiLegalEntity): Office {
		return {
			inn: source.inn,
			ogrn: source.ogrn,
			kpp: source.kpp,
			shortName: source.shortName,
			longName: source.longName,
			description: ""
		};
	}
}
