import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { formatFullName } from "@rmp/core/utils/formatting";
import { Permissions } from "@rmp/core/constants/permissions";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";
import AccessForbiddenException from "@rmp/core/exceptions/accessForbiddenException";
import PermissionsService from "@rmp/core/services/permissionsService";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { CertificateInfoMapper } from "@rmp/core/types/certificateInfo";
import { formatDate } from "@rmp/core/utils/dates";
import { dateTimeFormat } from "@rmp/core/utils/formats";
import { SnapshotKeysEnum, SnapshotState, useSnapshotStore } from "@rmp/core/stores/composables/snapshot";
import { getTrustActionerKind } from "@rmp/core/utils/getTrustActionerKind";
import { TrustStatusType } from "@rmp/core/types/counterpartyEmployee/trust/TrustStatusType";
import { TrustController } from "@rmp/core/api/counterparty/trust";
import CounterpartyEmployeeTrustMapperProfile from "@rmp/organization/stores/counterpartyEmployee/trust/mapper";
import { StorageController } from "@rmp/core/api/storage";
import ApiFile from "@rmp/core/api/types/storage/apiFile";
import { STORAGE_TRUST_NAMESPACE } from "@rmp/core/constants/storage";
import { FileMeta, FileMetaHelper } from "@rmp/core/api/types/storage/fileMeta";
import router from "@rmp/organization/router";
import { RouteNames } from "@rmp/organization/router/routes";
import TrustMapper from "@rmp/core/types/counterpartyEmployee/trust/trust";
import {
	CounterpartyEmployeeTrust,
	CounterpartyEmployeeTrustMapper
} from "@rmp/core/types/counterpartyEmployee/trust/counterpartyEmployeeTrust";
import { useCounterpartyEmployeeStore } from "@rmp/organization/stores/counterpartyEmployee";
import {
	useCounterpartyEmployeeTrustsBreadcrumb
} from "@rmp/organization/stores/counterpartyEmployee/trusts/composables/useCounterpartyEmployeeTrustsBreadcrumb";
import {
	useCounterpartyEmployeeTrustBreadcrumb
} from "@rmp/organization/stores/counterpartyEmployee/trust/composables/useCounterpartyEmployeeTrustBreadcrumb";
import { i18n } from "@rmp/core/plugins";
import {
	useCounterpartyEmployeeGeneralBreadcrumb
} from "@rmp/organization/stores/counterpartyEmployee/general/composables/useCounterpartyEmployeeGeneralBreadcrumb";

const abortService = new AbortService();
const trustController = new TrustController(abortService);
const storageController = new StorageController(abortService);
const permissionsService = new PermissionsService();

const page = usePageStore(abortService);
const snapshotStore = useSnapshotStore([
	{
		key: SnapshotKeysEnum.LAST_SAVED, fields: ["trust"]
	}
]);

const mapper = new CounterpartyEmployeeTrustMapperProfile();

export interface CounterpartyEmployeeTrustState extends PageState, SnapshotState {
	id: string;
	trust: CounterpartyEmployeeTrust;
	counterpartyId: string;
	employeeId: string;
	trustFileMeta: FileMeta;
	isDeclining: boolean;
	isAccepting: boolean;
	isCancelling: boolean;
	currentActiveTrustId: string;
	declineReason: string;
	isTrustFormLoading: boolean;
	isTrustFormDisabled: boolean;
}

const getDefaultState = (): CounterpartyEmployeeTrustState => {
	return {
		...page.getDefaultPageState(),
		...snapshotStore.getDefaultState(),
		id: "",
		trust: {} as CounterpartyEmployeeTrust,
		counterpartyId: "",
		employeeId: "",
		trustFileMeta: {
			id: "",
			name: "",
			mimeType: "",
			namespace: "",
			signature: "",
			isLoading: false,
			isSigned: false
		} as FileMeta,
		isDeclining: false,
		isAccepting: false,
		isCancelling: false,
		currentActiveTrustId: "",
		declineReason: "",
		isTrustFormLoading: false,
		isTrustFormDisabled: false
	};
};

export const useCounterpartyEmployeeTrustStore = defineStore({
	id: "counterparty-employee-trust",
	state: (): CounterpartyEmployeeTrustState => getDefaultState(),
	getters: {
		...page.getters,
		...snapshotStore.getters,
		breadcrumbs(state: CounterpartyEmployeeTrustState) {
			let {  breadcrumbs, employee, id } = useCounterpartyEmployeeStore();
			const routeName = state.id ? RouteNames.COUNTERPARTY_EMPLOYEE_TRUST : RouteNames.COUNTERPARTY_EMPLOYEE_TRUST_CREATE;
			const text = state.id ? String(i18n.t("titles.trust")) : String(i18n.t("titles.createTrust"));
			
			return [
				...breadcrumbs,
				useCounterpartyEmployeeGeneralBreadcrumb(RouteNames.COUNTERPARTY_EMPLOYEE_GENERAL, formatFullName(employee), { id: state.id }),
				useCounterpartyEmployeeTrustsBreadcrumb(),
				useCounterpartyEmployeeTrustBreadcrumb(routeName, text, { id: state.id })
			];
		},
		details(state: CounterpartyEmployeeTrustState) {
			return {
				...state.trust,
				acceptedDate: formatDate(state.trust.acceptedAt, dateTimeFormat),
				declinedDate: formatDate(state.trust.declinedAt, dateTimeFormat),
				canceledDate: formatDate(state.trust.canceledAt, dateTimeFormat),
				expireDate: formatDate(state.trust.expireAt, dateTimeFormat)
			};
		},
		isSnilsEmpty() {
			const { employee: { snils } } = useCounterpartyEmployeeStore();

			return !snils;
		}
	},
	actions: {
		...page.actions,
		...snapshotStore.actions,
		async beforeInitialized(payload: { id: string, employeeId: string, counterpartyId: string }) {
			this.counterpartyId = payload.counterpartyId;
			this.employeeId = payload.employeeId;
			this.id = payload.id;
			
			await this.fetch();
			
			this.setStateSnapshot();
		},
		async fetch() {
			this.isTrustFormLoading = true;
			
			try {
				if(this.id) {
					const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_READ])
						? AuthorizationScopeType.GLOBAL
						: AuthorizationScopeType.OWN;
					
					let trustPersisted = await trustController.getCounterpartyEmployeeTrust(this.id,
						this.employeeId,
						this.counterpartyId,
						scope);
					
					this.trust = CounterpartyEmployeeTrustMapper.map(trustPersisted);
					
					const trustActionerKind = getTrustActionerKind(trustPersisted.trust);
					
					if(trustActionerKind) {
						this.trust.trustActionerKind = trustActionerKind;
						this.trust.actionerFullName = await trustController.getCounterpartyTrustActioner(
							trustPersisted.id,
							this.employeeId,
							this.counterpartyId,
							trustActionerKind,
							scope
						);
					}
					
					if(trustPersisted.trust.isSigned)
						await this.fetchCertificateInfo();
				} else {
					const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_READ_LIST])
						? AuthorizationScopeType.GLOBAL
						: AuthorizationScopeType.OWN;
					
					let apiTrusts = await trustController.getCounterpartyEmployeeTrusts(this.counterpartyId, this.employeeId, scope);
					let trusts = apiTrusts.map(x => TrustMapper.map(x));
					let currentActiveTrust = trusts.find(x => x.expireAt > new Date().getTime() && x.status === TrustStatusType.ACCEPTED);
					
					if(currentActiveTrust)
						this.currentActiveTrustId = currentActiveTrust.id;
				}
			} catch (error) {
				const { setPageModeNotFound, setPageModeAccessForbidden } = useAppStore();
				
				switch (error.constructor) {
					case HttpNotFoundException:
						setPageModeNotFound();
						break;
					case AccessForbiddenException:
						setPageModeAccessForbidden();
						break;
					default:
						AlertHelper.handleGeneralRequestErrors(error);
						break;
				}
			} finally {
				this.isTrustFormLoading = false;
			}
		},
		async fetchCertificateInfo() {
			const { signatures } = await storageController.getFileMeta(this.trust.fileId);
			
			try {
				const [signature] = signatures;
				const [certificate] = signature.certificates;
				
				this.trust.certificateInfo = CertificateInfoMapper.map(certificate);
				
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		},
		async uploadSignedTrustFile({ signature, file }: { signature: string, file: any }) {
			this.trustFileMeta.isLoading = true;
			
			try {
				const { name, type } = file;
				
				let meta = await storageController.createTemperFile(new ApiFile(file, name, STORAGE_TRUST_NAMESPACE, type, signature));
				
				this.trustFileMeta = FileMetaHelper.map(meta);
				
				this.trust.isSigned = true;
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.trustFileMeta.isLoading = false;
			}
		},
		async saveTrust() {
			this.isTrustFormLoading = true;
			
			try {
				if(this.id) {
					// TODO update
					
					alertService.addSuccess(AlertKeys.SUCCESS_UPDATED_INFO);
					this.setStateSnapshot();
				} else {
					const counterpartyEmployeeStore = useCounterpartyEmployeeStore();
					
					const createRequest = mapper.mapToApiCounterpartyEmployeeTrustCreateRequest(counterpartyEmployeeStore.$state,
						this.$state);
					
					const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_CREATE])
						? AuthorizationScopeType.GLOBAL
						: AuthorizationScopeType.OWN;
					
					this.id =
						await trustController.createCounterpartyEmployeeTrust(this.counterpartyId, this.employeeId, createRequest, scope);
					
					alertService.addSuccess(AlertKeys.SUCCESS_CREATED_INFO);
					
					this.setStateSnapshot();
					await router.push({ name: RouteNames.COUNTERPARTY_EMPLOYEE_TRUSTS, params: { counterpartyId: this.counterpartyId, id: this.employeeId } });
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.isTrustFormLoading = false;
			}
		},
		async acceptTrust() {
			this.isAccepting = true;
			
			try {
				await trustController.acceptCounterpartyEmployeeTrust(this.counterpartyId, this.employeeId, this.id);
				
				await this.fetch();
				
				alertService.addSuccess(AlertKeys.TRUST_ACCEPTED);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.isAccepting = false;
			}
		},
		async declineTrust() {
			this.isDeclining = true;
			
			try {
				await trustController.declineCounterpartyEmployeeTrust(this.counterpartyId, this.employeeId, this.id, this.declineReason);
				
				await this.fetch();
				
				alertService.addSuccess(AlertKeys.TRUST_DECLINED);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.isDeclining = false;
			}
		},
		async cancelTrustAccept() {
			this.isCancelling = true;
			
			try {
				await trustController.cancelCounterpartyEmployeeTrust(this.counterpartyId, this.employeeId, this.id);
				
				await this.fetch();
				
				alertService.addSuccess(AlertKeys.TRUST_ACCEPT_CANCELED);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.isCancelling = false;
			}
		},
		removeTrustFile() {
			this.trustFileMeta = {} as FileMeta;
		}
	}
});
