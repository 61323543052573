import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { useAppStore } from "@rmp/core/stores/appStore";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import ApiAccountProfilePersistedBase from "@rmp/core/api/types/account/apiAccountProfilePersistedBase";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";
import AccessForbiddenException from "@rmp/core/exceptions/accessForbiddenException";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { OfficeController } from "@rmp/organization/api/office";
import { OfficeEmployee, OfficeEmployeeMapper } from "@rmp/core/types/officeEmployee/officeEmployee";
import { ApiOffice, ApiOfficeMapper } from "@rmp/core/api/types/office/apiOffice";
import { useOfficesBreadcrumb } from "@rmp/organization/stores/offices/composables/useOfficesBreadcrumb";
import { formatFullName } from "@rmp/core/utils/formatting";
import { RouteNames } from "@rmp/organization/router/routes";
import { i18n } from "@rmp/core/plugins";
import { useOfficeBreadcrumb } from "@rmp/organization/stores/office/composables/useOfficeBreadcrumb";
import { useOfficeEmployeesBreadcrumb } from "@rmp/organization/stores/office/employees/composables/useOfficeEmployeesBreadcrumb";

const abortService = new AbortService();
const officeController = new OfficeController(abortService);

const page = usePageStore(abortService);

export interface OfficeEmployeeState extends PageState {
	id: string;
	employee: OfficeEmployee;
	statusSaving: boolean;
	office: ApiOffice;
	officeId: string;
	profile: ApiAccountProfilePersistedBase | null;
}

const getDefaultState = (): OfficeEmployeeState => {
	return {
		...page.getDefaultPageState(),
		id: "",
		employee: OfficeEmployeeMapper.getEmpty(),
		statusSaving: false,
		office: ApiOfficeMapper.getEmpty(),
		officeId: "",
		profile: null
	};
};

export const useOfficeEmployeeStore = defineStore({
	id: "office-employee",
	state: (): OfficeEmployeeState => getDefaultState(),
	getters: {
		...page.getters,
		officeEmployeeTitle(state: OfficeEmployeeState) {
			return state.id ? `${String(i18n.t("titles.employee"))} ${state.office.shortName} - ${formatFullName(state.employee)}` : String(i18n.t("titles.createEmployee"));
		},
		breadcrumbs(state: OfficeEmployeeState) {
			return [
				useOfficesBreadcrumb(),
				useOfficeBreadcrumb(RouteNames.OFFICE_GENERAL, state.office.shortName, { id: state.id }),
				useOfficeEmployeesBreadcrumb()
			];
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized({ id, officeId }: { id: string | null, officeId: string }) {
			this.officeId = officeId;
			
			if(id)
				this.id = id;
			
			await this.fetch();
		},
		async fetch() {
			try {
				if(this.id) {
					let { employee } = await officeController.getOfficeEmployee(this.id, this.officeId);
					
					this.employee = OfficeEmployeeMapper.map(employee);
					
					this.profile = await officeController.getOfficeEmployeeProfile(this.officeId, this.id);
				}
				
				let { office } = await officeController.getOffice(this.officeId);
				this.office = office;
			} catch (error) {
				const { setPageModeNotFound, setPageModeAccessForbidden } = useAppStore();
				
				switch (error.constructor) {
					case HttpNotFoundException:
						setPageModeNotFound();
						break;
					case AccessForbiddenException:
						setPageModeAccessForbidden();
						break;
					default:
						AlertHelper.handleGeneralRequestErrors(error);
						break;
				}
			}
		},
		async activateEmployee() {
			this.statusSaving = true;
			
			try {
				await officeController.activateOfficeEmployee(this.officeId, this.id);
				
				this.employee.isActive = true;
				alertService.addSuccess(AlertKeys.PROFILE_ACTIVATED);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.statusSaving = false;
			}
		},
		async deactivateEmployee() {
			this.statusSaving = true;
			
			try {
				await officeController.deactivateOfficeEmployee(this.officeId, this.id);
				
				this.employee.isActive = false;
				alertService.addSuccess(AlertKeys.PROFILE_DEACTIVATED);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.statusSaving = false;
			}
		},
		assignEmployee(employee: Partial<OfficeEmployee>) {
			Object.assign(this.employee, employee);
		}
	}
});
