import { ApiAccount } from "@rmp/core/api/types/account/apiAccount";

export interface Account {
	login: string;
	email: string;
	isActive: boolean;
	isEmailConfirmed: boolean;
}

export class AccountMapper {
	static map(source: ApiAccount): Account {
		return {
			...source
		};
	}
	
	static getEmpty(): Account {
		return {
			login: "",
			email: "",
			isActive: false,
			isEmailConfirmed: false
		};
	}
}
