import { defineStore } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import { ApiApp } from "@rmp/core/api/types/app/apiApp";
import router from "@rmp/organization/router";
import { RouteNames } from "@rmp/organization/router/routes";
import { AppsController } from "@rmp/organization/api/apps";
import { first } from "lodash";

const abortService = new AbortService();
const appsController = new AppsController(abortService);

const page = usePageStore(abortService);

export interface AppsState extends PageState {
	items: ApiApp[];
	loading: boolean;
}

const getDefaultState = (): AppsState => {
	return {
		...page.getDefaultPageState(),
		items: [],
		loading: false
	};
};

export const useAppsStore = defineStore({
	id: "apps",
	state: (): AppsState => getDefaultState(),
	getters: {
		...page.getters
	},
	actions: {
		...page.actions,
		async beforeInitialized() {
			await this.fetch();
		},
		async fetch() {
			try {
				const apps: ApiApp[] = await appsController.getApps();
				
				if(!apps.length) {
					await router.push({ name: RouteNames.ROOT }).catch(() => {});
				} else if(apps.length > 1) {
					this.items = apps;
				} else {
					window.location.href = first(apps)!.url;
				}
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		}
	}
});
