import AbortService from "@rmp/core/services/abortService";
import { TrustController } from "@rmp/core/api/counterparty/trust";
import PermissionsService from "@rmp/core/services/permissionsService";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { RouteNames } from "@rmp/organization/router/routes";
import router from "@rmp/organization/router";
import { defineStore } from "pinia";
import CounterpartyEmployeeTrustsRouteQuery
	from "@rmp/organization/stores/counterpartyEmployee/trusts/types/counterpartyEmployeeTrustsRouteQuery";
import { SortingOrderTypeEnum } from "@rmp/core/types/SortingOrderTypeEnum";
import CounterpartyEmployeeTrustsRouteQueryService
	from "@rmp/organization/stores/counterpartyEmployee/trusts/services/counterpartyEmployeeTrustsRouteQueryService";
import { formatDate } from "@rmp/core/utils/dates";
import { dateTimeFormat } from "@rmp/core/utils/formats";
import { Permissions } from "@rmp/core/constants/permissions";
import { AuthorizationScopeType } from "@rmp/core/types/authorization/authorizationScopeType";
import TrustMapper, { Trust } from "@rmp/core/types/counterpartyEmployee/trust/trust";
import { useCounterpartyEmployeeStore } from "@rmp/organization/stores/counterpartyEmployee";
import { formatFullName } from "@rmp/core/utils/formatting";
import {
	useCounterpartyEmployeeTrustsBreadcrumb
} from "@rmp/organization/stores/counterpartyEmployee/trusts/composables/useCounterpartyEmployeeTrustsBreadcrumb";
import {
	useCounterpartyEmployeeGeneralBreadcrumb
} from "@rmp/organization/stores/counterpartyEmployee/general/composables/useCounterpartyEmployeeGeneralBreadcrumb";

const abortService = new AbortService();
const trustController = new TrustController(abortService);
const permissionsService = new PermissionsService();

const defaultRouteQuery = new CounterpartyEmployeeTrustsRouteQuery(
	1,
	"createdAt",
	SortingOrderTypeEnum.DESCENDING,
	""
);
const routeQueryService = new CounterpartyEmployeeTrustsRouteQueryService(defaultRouteQuery);

const page = usePageStore(abortService);

export interface CounterpartyEmployeeTrustsState extends PageState, RegisterState<Trust> {
	id: string;
}


const register = useRegisterStore<Trust>({
	routeName: RouteNames.COUNTERPARTY_EMPLOYEE_TRUSTS,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router
});

const getDefaultState = (): CounterpartyEmployeeTrustsState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		id: ""
	};
};

export const useCounterpartyEmployeeTrustsStore = defineStore({
	id: "counterparty-employee-trusts",
	state: (): CounterpartyEmployeeTrustsState => getDefaultState(),
	getters: {
		...register.getters,
		...page.getters,
		breadcrumbs(state: CounterpartyEmployeeTrustsState) {
			let { breadcrumbs, employee, id } = useCounterpartyEmployeeStore();
			
			return [
				...breadcrumbs,
				useCounterpartyEmployeeGeneralBreadcrumb(RouteNames.COUNTERPARTY_EMPLOYEE_GENERAL, formatFullName(employee), { id: state.id }),
				useCounterpartyEmployeeTrustsBreadcrumb()
			];
		},
		formattedItems(state: CounterpartyEmployeeTrustsState): object[] {
			return state.listing.items.map(x => {
				return {
					...x,
					acceptDate: x.acceptedAt || x.declinedAt,
					isSigned: x.isSigned
				};
			});
		},
		filteredItems(): object[] {
			return this.formattedItems;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized() {
			await this.initializeRegister();
		},
		async fetchRegisterItems() {
			let { counterpartyId, employeeId } = router.currentRoute.params;
			
			const scope = await permissionsService.check([Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_READ_LIST])
				? AuthorizationScopeType.GLOBAL
				: AuthorizationScopeType.OWN;
			
			let trusts = await trustController.getCounterpartyEmployeeTrusts(counterpartyId, employeeId, scope);
			
			return { items: trusts.map(x => TrustMapper.map(x)) };
		}
	}
});
