import { TrustStatusType } from "@rmp/core/types/counterpartyEmployee/trust/TrustStatusType";
import ApiCounterpartyEmployeeTrustPersisted from "@rmp/core/api/types/counterparty/trust/apiCounterpartyEmployeeTrustPersisted";
import { convertIsoToNumber } from "@rmp/core/utils/dates";

export interface Trust {
	id: string,
	status: TrustStatusType,
	expireAt: number,
	createdAt: number,
	isSigned: boolean,
	acceptedAt: number,
	declinedAt: number
}

export default class TrustMapper {
	constructor() {
	}
	
	static map(source: ApiCounterpartyEmployeeTrustPersisted): Trust {
		return {
			...source,
			status: source.trust.status,
			expireAt: convertIsoToNumber(source.trust.expireAt),
			createdAt: convertIsoToNumber(source.createdAt),
			isSigned: source.trust.isSigned,
			acceptedAt: convertIsoToNumber(source.trust.acceptedAt),
			declinedAt: convertIsoToNumber(source.trust.declinedAt),
		};
	}
}
