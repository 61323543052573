import { OfficeEmployee } from "@rmp/core/types/officeEmployee/officeEmployee";
import { ApiPersistedBase } from "@rmp/core/api/types/base/apiPersistedBase";
import { ApiOfficeEmployeeRole } from "@rmp/core/api/types/roles/apiOfficeEmployeeRole";

export interface ApiOfficeEmployee {
	position: string;
	snils: string;
	firstName: string;
	middleName: string;
	lastName: string;
	description: string;
	email: string;
	phone: string;
	isActive: boolean;
	roles: ApiOfficeEmployeeRole[];
}

export class ApiOfficeEmployeeMapper {
	static map(source: OfficeEmployee): ApiOfficeEmployee {
		return {
			...source
		};
	}
}

export interface ApiOfficeEmployeePersisted extends ApiPersistedBase{
	number: number;
	employee: ApiOfficeEmployee;
}
