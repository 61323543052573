<template>
	<rmp-nested-content-layout>
		<template #title>
			<rmp-custom-title-loader/>
		</template>
		<template #content>
			<rmp-tabs-loader></rmp-tabs-loader>

			<template v-if="isGeneralPage">
				<rmp-office-general-loader/>
			</template>

			<template v-else-if="isEmployeesPage">
				<rmp-office-employees-loader/>
			</template>

		</template>
	</rmp-nested-content-layout>
</template>

<script>
import RmpTabsLoader from "@rmp/core/components/loaders/common/RmpTabsLoader.vue";
import RmpNestedContentLayout from "@rmp/core/components/layouts/RmpNestedContentLayout.vue";
import RmpCustomTitleLoader from "@rmp/core/components/loaders/common/RmpCustomTitleLoader.vue";
import RmpFormCard from "@rmp/core/components/markup/RmpFormCard.vue";
import RmpFormCardBlocks from "@rmp/core/components/markup/RmpFormCardBlocks.vue";
import RmpFormCardBlock from "@rmp/core/components/markup/RmpFormCardBlock.vue";
import RmpFormCardBlockRow from "@rmp/core/components/markup/RmpFormCardBlockRow.vue";
import RmpFormCardBlockCol from "@rmp/core/components/markup/RmpFormCardBlockCol.vue";
import RmpFormItem from "@rmp/core/components/markup/RmpFormItem.vue";
import RmpSwitchLoader from "@rmp/core/components/loaders/form/RmpSwitchLoader.vue";
import RmpTextareaLoader from "@rmp/core/components/loaders/form/RmpTextareaLoader.vue";
import { RouteNames } from "@rmp/organization/router/routes";
import RmpOfficeEmployeesLoader from "@rmp/organization/views/office/sections/RmpOfficeEmployeesLoader.vue";
import RmpOfficeGeneralLoader from "@rmp/organization/views/office/sections/RmpOfficeGeneralLoader.vue";

export default {
	data() {
		return {
			RouteNames
		};
	},
	computed: {
		isGeneralPage() {
			return this.$route.name === this.RouteNames.OFFICE_GENERAL;
		},
		isEmployeesPage() {
			return this.$route.name === this.RouteNames.OFFICE_EMPLOYEES;
		}
	},
	components: {
		RmpOfficeEmployeesLoader,
		RmpOfficeGeneralLoader,
		RmpCustomTitleLoader,
		RmpTabsLoader,
		RmpNestedContentLayout,
		RmpFormCardBlocks,
		RmpFormCard,
		RmpFormCardBlock,
		RmpFormCardBlockCol,
		RmpFormCardBlockRow,
		RmpFormItem,
		RmpSwitchLoader,
		RmpTextareaLoader
	}
};
</script>
