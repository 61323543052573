import { RouteNames } from "@rmp/organization/router/routes";
import { Permissions } from "@rmp/core/constants/permissions";

const routeToPermissionsMap = new Map<string, Permissions[]>([
	[RouteNames.ROOT, []],
	[RouteNames.APPS, []],
	[RouteNames.CALLBACK, []],
	[RouteNames.LOGOUT, []],
	[RouteNames.SILENT_RENEW, []],
	[RouteNames.ERROR_PAGE_NOT_FOUND, []],
	[RouteNames.ERROR_ACCESS_DENIED, []],
	[RouteNames.ERROR_INTERNAL_SERVER_ERROR, []],
	
	[RouteNames.CONFIRM_EMAIL, []],
	[RouteNames.CONFIRM_PHONE, []],
	[RouteNames.RESET_PASSWORD, []],

	[RouteNames.COUNTERPARTIES, [Permissions.GLOBAL_COUNTERPARTY_READ_LIST]],

	[RouteNames.APPLICATIONS, []],
	[RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESSES, [Permissions.GLOBAL_MASTER_ACCOUNT_APPLICATION_READ_LIST]],
	[RouteNames.APPLICATIONS_MASTER_ACCOUNT_ACCESS_DETAILS, [Permissions.GLOBAL_MASTER_ACCOUNT_APPLICATION_READ]],

	[RouteNames.COUNTERPARTY, [Permissions.GLOBAL_COUNTERPARTY_READ, Permissions.OWN_COUNTERPARTY_READ]],
	[RouteNames.COUNTERPARTY_CREATE, [Permissions.GLOBAL_COUNTERPARTY_CREATE]],
	[RouteNames.COUNTERPARTY_GENERAL, [Permissions.GLOBAL_COUNTERPARTY_READ, Permissions.OWN_COUNTERPARTY_READ]],
	[RouteNames.COUNTERPARTY_EMPLOYEES, [Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_READ_LIST, Permissions.OWN_COUNTERPARTY_EMPLOYEE_READ_LIST]],
	[RouteNames.COUNTERPARTY_MASTER_ACCOUNT, [Permissions.GLOBAL_COUNTERPARTY_MASTER_ACCOUNT_READ]],
	[RouteNames.COUNTERPARTY_DOCUMENTS, [Permissions.GLOBAL_COUNTERPARTY_READ]],
	
	
	[RouteNames.COUNTERPARTY_BANK_ACCOUNTS, []],
	[RouteNames.COUNTERPARTY_BANK_ACCOUNT, []],
	[RouteNames.COUNTERPARTY_BANK_ACCOUNT_CREATE, []],

	[RouteNames.ACCOUNTS, [Permissions.GLOBAL_ACCOUNT_READ_LIST]],
	[RouteNames.ACCOUNT, [Permissions.GLOBAL_ACCOUNT_READ]],
	[RouteNames.ACCOUNT_GENERAL, [Permissions.GLOBAL_ACCOUNT_READ]],
	[RouteNames.ACCOUNT_PROFILES, [Permissions.GLOBAL_ACCOUNT_PROFILE_READ_LIST]],

	[RouteNames.COUNTERPARTY_EMPLOYEE, [Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_READ, Permissions.OWN_COUNTERPARTY_EMPLOYEE_READ]],
	[RouteNames.COUNTERPARTY_EMPLOYEE_CREATE,
		[Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_CREATE, Permissions.OWN_COUNTERPARTY_EMPLOYEE_CREATE]],
	[RouteNames.COUNTERPARTY_EMPLOYEE_GENERAL, [Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_READ, Permissions.OWN_COUNTERPARTY_EMPLOYEE_READ]],
	[RouteNames.COUNTERPARTY_EMPLOYEE_TRUSTS,
		[Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_READ_LIST, Permissions.OWN_COUNTERPARTY_EMPLOYEE_TRUST_READ_LIST]],
	[RouteNames.COUNTERPARTY_EMPLOYEE_PROFILE,
		[Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_PROFILE_READ, Permissions.OWN_COUNTERPARTY_EMPLOYEE_PROFILE_READ]],
	[RouteNames.COUNTERPARTY_EMPLOYEE_TRUST,
		[Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_READ, Permissions.OWN_COUNTERPARTY_EMPLOYEE_TRUST_READ]],
	[RouteNames.COUNTERPARTY_EMPLOYEE_TRUST_CREATE,
		[Permissions.GLOBAL_COUNTERPARTY_EMPLOYEE_TRUST_CREATE, Permissions.OWN_COUNTERPARTY_EMPLOYEE_TRUST_CREATE]],

	[RouteNames.OFFICES, [Permissions.GLOBAL_OFFICE_READ_LIST]],
	[RouteNames.OFFICE, [Permissions.GLOBAL_OFFICE_READ]],
	[RouteNames.OFFICE_CREATE, [Permissions.GLOBAL_OFFICE_CREATE]],
	[RouteNames.OFFICE_GENERAL, [Permissions.GLOBAL_OFFICE_READ]],
	[RouteNames.OFFICE_EMPLOYEES, [Permissions.GLOBAL_OFFICE_EMPLOYEE_READ_LIST]],
	[RouteNames.OFFICE_EMPLOYEE, [Permissions.GLOBAL_OFFICE_EMPLOYEE_READ]],
	[RouteNames.OFFICE_EMPLOYEE_CREATE, [Permissions.GLOBAL_OFFICE_EMPLOYEE_CREATE]],
	[RouteNames.OFFICE_EMPLOYEE_GENERAL, [Permissions.GLOBAL_OFFICE_EMPLOYEE_READ]],
	[RouteNames.OFFICE_EMPLOYEE_PROFILE, [Permissions.GLOBAL_OFFICE_EMPLOYEE_PROFILE_READ]]
]);

export default routeToPermissionsMap;
