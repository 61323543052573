import { CounterpartiesState } from "@rmp/organization/stores/counterparties";
import ApiGetCounterpartiesParameters from "@rmp/core/api/types/counterparty/apiGetCounterpartiesParameters";

export default class CounterpartiesMapperProfile {
	constructor() {
	}
	
	mapToApiGetCounterpartiesParameters(source: CounterpartiesState): ApiGetCounterpartiesParameters {
		return {
			take: source.paging.pageSize,
			skip: source.paging.pageSize * (source.paging.page - 1),
			query: source.search.query,
			sortField: source.sorting.type,
			sortingDirection: source.sorting.order,
			counterpartyTypes: source.filter.counterpartyTypes
		};
	}
}
