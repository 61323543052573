import { ApiCounterpartyEmployeeBase } from "@rmp/core/api/types/counterparty/apiCounterpartyEmployee";
import { ApiRole } from "@rmp/core/api/types/roles/apiRole";

export interface CounterpartyEmployee {
	inn: string;
	firstName: string;
	middleName: string;
	lastName: string;
	snils: string;
	isActive: boolean;
	statusSaving: boolean;
	roles: ApiRole[];
}

export class CounterpartyEmployeeMapper {
	static map(source: ApiCounterpartyEmployeeBase): CounterpartyEmployee {
		return {
			...source,
			statusSaving: false
		};
	}
	
	static getEmpty(): CounterpartyEmployee {
		return {
			firstName: "",
			inn: "",
			middleName: "",
			lastName: "",
			snils: "",
			isActive: true,
			statusSaving: false,
			roles: []
		}
	}
}
