import { render, staticRenderFns } from "./RmpTilesLinkGroupBtn.vue?vue&type=template&id=b2e48416&scoped=true&"
import script from "./RmpTilesLinkGroupBtn.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RmpTilesLinkGroupBtn.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./RmpTilesLinkGroupBtn.vue?vue&type=style&index=0&id=b2e48416&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2e48416",
  null
  
)

export default component.exports