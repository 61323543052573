import { RouteNames } from "@rmp/organization/router/routes";
import { useBreadcrumb } from "@rmp/core/stores/composables/breadcrumb/useBreadcrumb";
import routeToPermissionsMap from "@rmp/organization/router/routeToPermissionsMap";
import { i18n } from "@rmp/organization//plugins";

export function useOfficeEmployeeProfileBreadcrumb() {
	return useBreadcrumb({
		route: {
			name: RouteNames.OFFICE_EMPLOYEE_PROFILE
		},
		text: i18n.t(`navigation.breadcrumbs.${RouteNames.OFFICE_EMPLOYEE_PROFILE}`).toString(),
		routeToPermissionsMap
	});
}
