import { MasterAccountAccessApplicationsState } from "@rmp/organization/stores/masterAccountAccessApplications/index";
import ApiGetMasterAccountAccessApplicationsParameters
	from "@rmp/core/api/types/masterAccountAccessApplication/apiGetMasterAccountAccessApplicationsParameters";
import { MasterAccountAccessStatusFilterEnum } from "@rmp/core/types/masterAccountAccess/MasterAccountAccessStatusFilter";

export default class MasterAccountAccessApplicationsMapperProfile {
	constructor() {
	}
	
	mapToApiGetMasterAccountAccessApplicationsParameters(source: MasterAccountAccessApplicationsState): ApiGetMasterAccountAccessApplicationsParameters {
		return {
			take: source.paging.pageSize,
			skip: source.paging.pageSize * (source.paging.page - 1),
			query: source.search.query,
			sortField: source.sorting.type,
			sortingDirection: source.sorting.order,
			statuses: source.filter.statuses.filter(y => y !== MasterAccountAccessStatusFilterEnum.EMPTY)
		};
	}
}
