import { ApiLegalPersonFnsUpdate } from "@rmp/core/api/types/fns/apiLegalPersonFnsUpdate";
import { convertIsoToNumber } from "@rmp/core/utils/dates";

export interface LegalPersonFnsUpdate {
	lastChecked: number;
	lastUpdated: number;
	isEnabled: boolean;
	externalId: string;
	inn: string;
	ogrn: string;
	type: string;
}

export class LegalPersonFnsUpdateMapper {
	static map(source: ApiLegalPersonFnsUpdate): LegalPersonFnsUpdate {
		return {
			...source,
			lastChecked: convertIsoToNumber(source.lastChecked),
			lastUpdated: convertIsoToNumber(source.lastUpdated)
		};
	}
}
