import { Store } from "pinia";
import { EmbeddedFormEditModeTypeEnum } from "@rmp/core/stores/composables/embeddedForm/types/EmbeddedFormEditModeTypeEnum";
import { EmbeddedFormModeTypeEnum } from "@rmp/core/stores/composables/embeddedForm/types/EmbeddedFormModeTypeEnum";
import { SnapshotActions, SnapshotGetters, SnapshotState } from "@rmp/core/stores/composables/snapshot";

export interface EmbeddedFormState {
	formStateLoading: boolean;
	formReadonly: boolean;
	formSaving: boolean;
	formValid: boolean;
	formDisabled: boolean;
	formInitialized: boolean;
	formEditMode: EmbeddedFormEditModeTypeEnum;
	formMode: EmbeddedFormModeTypeEnum;
}

export type EmbeddedFormGetters = {}

export type EmbeddedFormActions = {};

export type EmbeddedFormStore = Store<string, (EmbeddedFormState & SnapshotState) | EmbeddedFormState, (EmbeddedFormGetters & SnapshotGetters) | EmbeddedFormGetters, (EmbeddedFormActions & SnapshotActions) | EmbeddedFormActions>;

export const useEmbeddedFormStore = () => {
	const getDefaultState = (): EmbeddedFormState => {
		return {
			formStateLoading: false,
			formReadonly: false,
			formSaving: false,
			formValid: false,
			formDisabled: false,
			formInitialized: false,
			formEditMode: EmbeddedFormEditModeTypeEnum.UNKNOWN,
			formMode: EmbeddedFormModeTypeEnum.UNKNOWN
		};
	};
	
	const getters: EmbeddedFormGetters = {};
	
	const actions: EmbeddedFormActions = {};
	
	return {
		actions,
		getters,
		getDefaultState
	};
};
