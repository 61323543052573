import ApiMasterAccountAccessApplication from "@rmp/core/api/types/masterAccountAccessApplication/apiMasterAccountAccessApplication";
import { convertIsoToNumber } from "@rmp/core/utils/dates";
import { convertNumberToIsoString } from "@rmp/core/utils/formatting";
import MasterAccountAccessApplication from "@rmp/organization/stores/masterAccountAccessApplication/types/masterAccountAccessApplication";

export default class MasterAccountAccessApplicationMapperProfile {
	constructor() {
	}
	
	mapFromApi(source: ApiMasterAccountAccessApplication): MasterAccountAccessApplication {
		return {
			...source,
			createdAt: convertIsoToNumber(source.createdAt),
			updatedAt: convertIsoToNumber(source.updatedAt),
		}
	}
	
	mapToApi(source: MasterAccountAccessApplication): ApiMasterAccountAccessApplication {
		return {
			...source,
			createdAt: convertNumberToIsoString(source.createdAt),
			updatedAt: convertNumberToIsoString(source.updatedAt),
		};
	}
}
