export enum AVAILABLE_SORTS {
	Title = "title",
	Inn = "inn",
	Orgn = "ogrn",
	CounterpartType = "counterpartyType",
	CreatedAt = "createdAt"
};

export enum AVAILABLE_FILTER_TYPE_ENUM {
	LegalEntity = "legalEntity", 
	Individual = "individual", 
	Entepreneur = "entrepreneur",
	ForeignOrganization = "foreignOrganization"
};

