import { TrustStatusType } from "@rmp/core/types/counterpartyEmployee/trust/TrustStatusType";
import ApiCounterpartyEmployeeTrust from "@rmp/core/api/types/counterparty/trust/apiCounterpartyEmployeeTrust";
import { TrustActionerKind } from "@rmp/core/types/counterpartyEmployee/trust/TrustActionerKind";

export const getTrustActionerKind = (trust: ApiCounterpartyEmployeeTrust) => {
	if(trust.status === TrustStatusType.CREATED && (trust.cancellerOfficeEmployeeId || trust.cancellerCounterpartyEmployeeId))
		return TrustActionerKind.CANCELLER;

	if(trust.status === TrustStatusType.ACCEPTED)
		return TrustActionerKind.ACCEPTOR;

	if(trust.status === TrustStatusType.DECLINED)
		return TrustActionerKind.DECLINER;
};
