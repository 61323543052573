import { defineStore, Store, SubscriptionCallback } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { RouteNames } from "@rmp/organization/router/routes";
import router from "@rmp/organization/router";
import { CounterpartyController } from "@rmp/core/api/counterparty";
import CounterpartiesMapperProfile from "@rmp/organization/stores/counterparties/mapper";
import ApiCounterpartyPersistedBase from "@rmp/core/api/types/counterparty/apiCounterpartyPersistedBase";
import {CounterpartiesFilter} from "@rmp/organization/stores/counterparties/types/counterpartiesFilter";
import { get } from "local-storage";
import { LAST_VISITED_COUNTERPARTIES } from "@rmp/core/constants/localStorage";
import SearchSuggestion from "@rmp/core/services/searchSuggestion/searchSuggestion";
import { convertIsoToNumber, formatDate } from "@rmp/core/utils/dates";
import { dateTimeFormat } from "@rmp/core/utils/formats";
import { i18n } from "@rmp/core/plugins";
import ApiLegalEntityCounterparty from "@rmp/core/api/types/counterparty/apiLegalEntityCounterparty";
import ApiLegalPersonCounterparty from "@rmp/core/api/types/counterparty/apiLegalPersonCounterparty";
import ApiForeignOrganizationCounterparty from "@rmp/core/api/types/counterparty/apiForeignOrganizationCounterparty";
import ApiPhysicalPersonCounterparty from "@rmp/core/api/types/counterparty/apiPhysicalPersonCounterparty";
import { formatFullName } from "@rmp/core/utils/formatting";
import { CounterpartiesItem } from "@rmp/organization/stores/counterparties/types/counterpartiesItem";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { z } from "zod";
import { useRouteQueryService } from "@rmp/core/composables/useRouteQueryService";
import { AVAILABLE_FILTER_TYPE_ENUM, AVAILABLE_SORTS } from "@rmp/organization/stores/counterparties/constants";
import { parseArrayParameter } from "@rmp/core/utils/query";

const abortService = new AbortService();
const counterpartyController = new CounterpartyController(abortService);

const mapper = new CounterpartiesMapperProfile();

export interface CounterpartiesState extends PageState, RegisterState<ApiCounterpartyPersistedBase, CounterpartiesFilter>
{
	filter: CounterpartiesFilter;
	searchSuggestions: SearchSuggestion[];
}


const schema = z.object({
	sort: z.nativeEnum(AVAILABLE_SORTS).default(AVAILABLE_SORTS.CreatedAt),
	counterpartyTypes: z.array(z.nativeEnum(AVAILABLE_FILTER_TYPE_ENUM)).default([])
});

const { defaultRouteQuery, ...routeQueryService } = useRouteQueryService(schema.shape, {
	parseFilterParameters: (state) => {
		return {
			...state,
			counterpartyTypes: parseArrayParameter(state.counterpartyTypes)
		}
	}
});


const page = usePageStore(abortService);

const register = useRegisterStore<ApiCounterpartyPersistedBase, CounterpartiesFilter>({
	routeName: RouteNames.COUNTERPARTIES,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router,
	itemsKey: "counterparties"
});

const getDefaultState = (): CounterpartiesState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState(),
		filter: {
			counterpartyTypes: []
		} as CounterpartiesFilter,
		searchSuggestions: []
	};
};

export const useCounterpartiesStore = defineStore({
	id: "counterparties",
	state: (): CounterpartiesState => getDefaultState(),
	getters: {
		...register.getters,
		...page.getters,
		formattedItems(state: CounterpartiesState): CounterpartiesItem[] {
			return state.listing.items.map(x => {
				let item = {
					id: x.id,
					createdAt: formatDate(convertIsoToNumber(x.createdAt), dateTimeFormat),
					inn: "",
					ogrn: "",
					title: "",
					counterpartyType: i18n.t(`aliases.counterpartyType.${x.counterparty.type}`)
				};
				
				if(x.counterparty instanceof ApiLegalEntityCounterparty) {
					item.inn = x.counterparty.inn;
					item.ogrn = x.counterparty.ogrn;
					item.title = x.counterparty.shortName;
				}
				if(x.counterparty instanceof ApiLegalPersonCounterparty) {
					item.inn = x.counterparty.inn;
					item.ogrn = x.counterparty.ogrnIp;
					item.title = x.counterparty.fullName;
				}
				if(x.counterparty instanceof ApiForeignOrganizationCounterparty) {
					item.title = x.counterparty.longName;
				}
				
				if(x.counterparty instanceof ApiPhysicalPersonCounterparty) {
					item.inn = x.counterparty.inn;
					item.title = formatFullName({ ...x.counterparty });
				}
				
				return item as CounterpartiesItem;
			});
		},
		filteredItems(): CounterpartiesItem[] {
			return this.formattedItems;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized() {
			await this.initializeRegister();
			
			this.searchSuggestions = get(LAST_VISITED_COUNTERPARTIES) || [];
		},
		patchStateWithRouteQuery(query: typeof defaultRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
			this.$patch((state) => {
				state.filter.counterpartyTypes = query.counterpartyTypes;
			});
		},
		async fetchRegisterItems() {
			return await counterpartyController.getCounterparties(mapper.mapToApiGetCounterpartiesParameters(this));
		}
	}
});
