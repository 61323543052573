import AbortService from "@rmp/core/services/abortService";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import { defineStore } from "pinia";
import { MasterAccountAccessApplicationController } from "@rmp/core/api/masterAccountAccessApplication";
import HttpNotFoundException from "@rmp/core/exceptions/httpNotFoundException";
import AlertHelper from "@rmp/core/stores/alerts/helpers/alertHelper";
import MasterAccountAccessApplicationMapperProfile from "@rmp/organization/stores/masterAccountAccessApplication/mapper";
import { useAppStore } from "@rmp/core/stores/appStore";
import MasterAccountAccessApplicationStatus from "@rmp/core/api/types/masterAccountAccessApplication/masterAccountAccessApplicationStatus";
import alertService, { AlertKeys } from "@rmp/core/stores/alerts/services/alertService";
import { CounterpartyTypeEnum } from "@rmp/core/types/CounterpartyTypeEnum";
import MasterAccountAccessApplication from "@rmp/organization/stores/masterAccountAccessApplication/types/masterAccountAccessApplication";
import { useMasterAccountAccessApplicationsBreadcrumb } from "@rmp/organization/stores/masterAccountAccessApplications/composables/useMasterAccountAccessApplicationsBreadcrumb";
import { useMasterAccountAccessApplicationBreadcrumb } from "@rmp/organization/stores/masterAccountAccessApplication/composables/useMasterAccountAccessApplicationBreadcrumb";

const abortService = new AbortService();
const masterAccountAccessApplicationController = new MasterAccountAccessApplicationController(abortService);

const mapper = new MasterAccountAccessApplicationMapperProfile();

export interface MasterAccountAccessApplicationState extends PageState {
	loading: boolean;
	approving: boolean;
	declining: boolean;
	applicationRejectReason: string;
	id: string | null;
	application: MasterAccountAccessApplication;
}

const page = usePageStore(abortService);

const getDefaultState = (): MasterAccountAccessApplicationState => ({
	...page.getDefaultPageState(),
	loading: false,
	approving: false,
	declining: false,
	applicationRejectReason: "",
	id: null,
	application: new MasterAccountAccessApplication()
});

export const useMasterAccountAccessApplicationStore = defineStore({
	id: "master-account-access-application",
	state: () => getDefaultState(),
	getters: {
		...page.getters,
		breadcrumbs(state) {
			return [
				useMasterAccountAccessApplicationsBreadcrumb(),
				useMasterAccountAccessApplicationBreadcrumb(state.application.legalEntity.shortName ||
					state.application.entrepreneur.fullName)
			];
		},
		details(state) {
			let details = {
				...state.application,
				title: ""
			};
			
			if(state.application.type === CounterpartyTypeEnum.LEGAL_ENTITY) {
				Object.assign(details, state.application.legalEntity);
				details.title = state.application.legalEntity.longName;
			}
			if(state.application.type === CounterpartyTypeEnum.LEGAL_PERSON) {
				Object.assign(details, state.application.entrepreneur);
				details.title = state.application.entrepreneur.fullName;
			}
			
			return details;
		}
	},
	actions: {
		...page.actions,
		async beforeInitialized({ id }: { id: string }) {
			this.id = id;
			
			await this.fetch();
		},
		async fetch() {
			this.loading = true;
			
			try {
				const data = await masterAccountAccessApplicationController.getMasterAccountAccessApplication(this.id!);
				
				this.application = mapper.mapFromApi(data);
			} catch (error) {
				console.error(error);
				
				const { setPageModeNotFound } = useAppStore();
				
				switch (error.constructor) {
					case HttpNotFoundException:
						setPageModeNotFound();
						break;
					default:
						AlertHelper.handleGeneralRequestErrors(error);
						break;
				}
			} finally {
				this.loading = false;
			}
		},
		async approveApplication() {
			this.approving = true;
			
			try {
				await masterAccountAccessApplicationController.approveMasterAccountAccessApplication(this.id!,
					MasterAccountAccessApplicationStatus.Approved);
				
				await this.fetch();
				
				alertService.addSuccess(AlertKeys.MASTER_ACCOUNT_ACCESS_APPLICATION_APPROVED);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.approving = false;
			}
		},
		async declineApplication() {
			this.declining = true;
			
			try {
				await masterAccountAccessApplicationController.declineMasterAccountAccessApplication(this.id!,
					MasterAccountAccessApplicationStatus.Rejected,
					this.applicationRejectReason);
				
				await this.fetch();
				
				alertService.addSuccess(AlertKeys.MASTER_ACCOUNT_ACCESS_APPLICATION_REJECTED);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.declining = false;
			}
		},
		resetApplicationRejectReason() {
			this.applicationRejectReason = "";
		}
	}
});
