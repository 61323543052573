import { defineStore, Store, SubscriptionCallback } from "pinia";
import { PageState, usePageStore } from "@rmp/core/stores/composables/page/usePageStore";
import AbortService from "@rmp/core/services/abortService";
import { RouteNames } from "@rmp/organization/router/routes";
import router from "@rmp/organization/router";
import AccountsMapperProfile from "@rmp/organization/stores/accounts/mapper";
import { convertIsoToNumber, formatDate } from "@rmp/core/utils/dates";
import { dateTimeFormat } from "@rmp/core/utils/formats";
import { Accountstem } from "@rmp/organization/stores/accounts/types/accountstem";
import { RegisterState, useRegisterStore } from "@rmp/core/stores/composables/register/useRegisterStore";
import { AccountController } from "@rmp/organization/api/account";
import { ApiAccountPersisted } from "@rmp/core/api/types/account/apiAccount";
import { z } from "zod";
import { AVAILABLE_SORTS } from "@rmp/organization/stores/accounts/constants";
import { useRouteQueryService } from "@rmp/core/composables/useRouteQueryService";

const abortService = new AbortService();
const accountController = new AccountController(abortService);

const mapper = new AccountsMapperProfile();

export interface AccountsState extends PageState, RegisterState<ApiAccountPersisted>
{
}

const schema = z.object({
	sort: z.nativeEnum(AVAILABLE_SORTS).default(AVAILABLE_SORTS.CreatedAt)
});

const { defaultRouteQuery, ...routeQueryService } = useRouteQueryService(schema.shape);

const page = usePageStore(abortService);

const register = useRegisterStore<ApiAccountPersisted>({
	routeName: RouteNames.ACCOUNTS,
	routeQueryService,
	routeQuery: defaultRouteQuery,
	getRouter: () => router,
	itemsKey: "accounts"
});

const getDefaultState = (): AccountsState => {
	return {
		...page.getDefaultPageState(),
		...register.getDefaultRegisterState()
	};
};

export const useAccountsStore = defineStore({
	id: "accounts",
	state: (): AccountsState => getDefaultState(),
	getters: {
		...register.getters,
		...page.getters,
		formattedItems(state: AccountsState): Accountstem[] {
			return state.listing.items.map(x => {
				return {
					...x,
					createdAt: formatDate(convertIsoToNumber(x.createdAt), dateTimeFormat),
					login: x.account.login,
					email: x.account.email,
					isActive: x.account.isActive
				} as Accountstem;
			});
		},
		filteredItems(): Accountstem[] {
			return this.formattedItems;
		}
	},
	actions: {
		...page.actions,
		...register.actions,
		async beforeInitialized() {
			await this.initializeRegister();
		},
		patchStateWithRouteQuery(query: typeof defaultRouteQuery) {
			this.patchRegisterStateWithRouteQuery(query);
		},
		async fetchRegisterItems() {
			return await accountController.getAccounts(mapper.mapToApiGetAccountsParameters(this));
		}
	}
});
