import { CounterpartyEmployeeGeneral } from "@rmp/core/types/counterpartyEmployee/counterpartyEmployeeGeneral";
import { ApiRole } from "@rmp/core/api/types/roles/apiRole";

export interface ApiCounterpartyEmployeeBase {
	counterpartyId: string;
	inn: string;
	email: string;
	phone: string;
	position: string;
	snils: string;
	firstName: string;
	middleName: string;
	lastName: string;
	isActive: boolean;
	roles: ApiRole[];
}

export class ApiCounterpartyEmployeeBaseMapper {
	static map(source: CounterpartyEmployeeGeneral): ApiCounterpartyEmployeeBase {
		return {
			...source,
			counterpartyId: "",
			roles: []
		};
	}
}
